import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { APPOINTMENT } from "../graphql/queries/appointment";
import { ON_APPOINTMENT_UPDATED } from "../graphql/subscriptions/appointment";
import { Appointment, AppointmentStatus } from "../types/api";

import { useAppStateMachine } from "./useAppStateMachine";
import { pexRTC } from "./usePexip";
import { useToken } from "./useToken";

export function useAppointment() {
  const token = useToken();
  const { transition } = useAppStateMachine();
  const { pathname } = useLocation();

  const { data, subscribeToMore } = useQuery<Appointment>(APPOINTMENT, {
    onError() {
      transition({ type: "ERROR" });
    },
    onCompleted({ appointment }) {
      if (appointment.status === AppointmentStatus.ENDED) {
        pexRTC.disconnect();
      }
    },
    skip: !token,
  });

  const appointment = useMemo(() => data?.appointment, [data]);

  useEffect(() => {
    if (appointment !== undefined && subscribeToMore !== undefined) {
      const unsubscribe = subscribeToMore({
        document: ON_APPOINTMENT_UPDATED,
        variables: { appointmentId: appointment.id },
        updateQuery: (prev: any, chunk: any) => {
          const newAppointmentData = chunk.subscriptionData.data.onAppointmentUpdated;

          return {
            appointment: {
              ...prev.appointment,
              ...newAppointmentData,
            },
          };
        },
      });

      return () => {
        if (unsubscribe !== undefined) {
          unsubscribe();
        }
      };
    }
  }, [appointment, subscribeToMore, transition]);

  useEffect(() => {
    if (appointment?.status != null) {
      // TODO: can we merge (or make decision) these two transitions into Xstate?
      if (pathname.endsWith("setup")) {
        transition({
          type: "SETUP",
        });
      } else {
        transition({
          type: appointment.status,
          appointmentStatus: appointment.status,
        });
      }
    }
  }, [appointment?.status, pathname, transition]);

  return appointment;
}
