import React, { ChangeEvent, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";

import { Button } from "../../components/Button/Button";
import { Questions_questions, QuestionType } from "../../types/api";

import styles from "./Survey.module.scss";

interface Props {
  question: Questions_questions;
  onAnswer: (questionId: string, answer: string) => void;
}

function Ratings(props: Props) {
  const { onAnswer, question } = props;

  return (
    <div className={styles.Ratings}>
      {Array.from(Array(10).keys()).map((value) => (
        <button
          className={styles.rating}
          key={value}
          onClick={() => onAnswer(question.id, `${value + 1}`)}
        >
          <p>{value + 1}</p>
        </button>
      ))}
    </div>
  );
}

function Select(props: Props) {
  const { onAnswer, question } = props;

  return (
    <div className={styles.Select}>
      {question?.options?.map((option) => (
        <button
          className={styles.rating}
          key={option.value}
          onClick={() => onAnswer(question.id, option.value)}
        >
          <p>{option.label}</p>
        </button>
      ))}
    </div>
  );
}

function Text(props: Props) {
  const { onAnswer, question } = props;
  const { t } = useTranslation();
  const [input, setInput] = useState("");

  function handleChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setInput(event.target.value);
  }

  return (
    <div className={styles.Text}>
      <TextareaAutosize
        minRows={4}
        onChange={handleChange}
        placeholder={t("input_placeholder") ?? undefined}
        value={input}
      />
      <Button
        onClick={() => onAnswer(question.id, input)}
        variant={!input ? Button.variants.DISABLED : Button.variants.DEFAULT}
      >
        {t("submit")}
      </Button>
    </div>
  );
}

const QUESTION_MAP: Record<QuestionType, (props: Props) => ReactElement> = {
  [QuestionType.RATING]: (props: Props) => <Ratings {...props} />,
  [QuestionType.SELECT]: (props: Props) => <Select {...props} />,
  [QuestionType.TEXT]: (props: Props) => <Text {...props} />,
};

export function Question(props: Props) {
  const { question } = props;

  return (
    <div>
      <h4 className={styles.title} dangerouslySetInnerHTML={{ __html: question.label }} />
      {QUESTION_MAP[question.type](props)}
    </div>
  );
}
