import { IconName } from "@fortawesome/fontawesome-svg-core";

export enum NavItemKey {
  CHAT = "chat",
  FILES = "files",
  RECORDINGS = "recordings",
  SETTINGS = "settings",
  PARTICIPANTS = "participants",
}

export enum NavPlacement {
  TOP = "TOP",
  BOTTOM = "BOTTOM",
}

export type NavItem = {
  icon: IconName;
  notificationCount?: number | null;
  placement: NavPlacement.TOP | NavPlacement.BOTTOM;
};

export type NavItems = {
  [x in NavItemKey]?: NavItem;
};
