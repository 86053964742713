import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import { nanoid } from "nanoid";
import slugify from "slugify";

import BlobStorageClient from "../azure/blobStorageClient";
import { ToastBody } from "../components/ToastContainer/components/ToastBody/ToastBody";
import { FILES, GET_SAS_STRING } from "../containers/Files/filesGql";
import { CREATE_FILES, DELETE_FILES, UPDATE_FILE } from "../graphql/mutations/files";
import { FILE_STATES, FileObject, UploadedFile as File } from "../types/File";
import { upsertObject } from "../utils/arrayHelpers";
import { fullName } from "../utils/user";

import { useDecodedToken } from "./useToken";

export function useFiles() {
  const token = useDecodedToken();
  const { t } = useTranslation("files");

  const [createFiles] = useMutation(CREATE_FILES);
  const [updateFile] = useMutation(UPDATE_FILE);
  const [deleteFiles] = useMutation(DELETE_FILES);

  const { data: SASString } = useQuery<{ getSASString: string }>(GET_SAS_STRING, {
    pollInterval: 27e4, // 4 min 30 sec
  });

  const getOptimisticResponse = useCallback(
    (file: FileObject, state: FILE_STATES) => {
      return {
        ...file,
        createdAt: new Date(),
        expiresAt: new Date(new Date().getTime() + 100000),
        participant: {
          __typename: "PARTICIPANT",
          id: token?.participantId,
          fullName: fullName(token),
        },
        state,
        thumbnail: null,
        url: "",
        __typename: "File",
      };
    },
    [token],
  );

  const handleUploadFiles = useCallback(
    async (files: any[]) => {
      if (token?.appointmentId === undefined) {
        return;
      }

      const blobStorageClient = new BlobStorageClient(
        SASString?.getSASString!,
        token.appointmentId,
      );

      const filesWithId: FileObject[] = await Promise.all(
        files.map(async (file) => {
          const name = await blobStorageClient.getEnumeratedBlockBlobName(slugify(file.name));

          return {
            id: nanoid(),
            file,
            expiresAt: new Date(),
            mimeType: file.type,
            name,
          };
        }),
      );

      try {
        await createFiles({
          variables: {
            input: filesWithId.map((file) => ({ ...file, file: undefined })),
          },
          optimisticResponse: {
            __typename: "Mutation",
            createFiles: filesWithId.map((file) =>
              getOptimisticResponse(file, FILE_STATES.UPLOADING),
            ),
          },
          update: (proxy, { data: { createFiles } }) => {
            const query = { query: FILES };
            const data: any = proxy.readQuery(query);

            proxy.writeQuery({
              ...query,
              data: {
                files: [...createFiles, ...(data?.files ?? [])],
              },
            });
          },
        });

        blobStorageClient.uploadFiles(filesWithId, token.appointmentId).map(async (upload, idx) => {
          try {
            const azureFile = await upload;

            updateFile({
              variables: {
                input: { id: azureFile.id, state: FILE_STATES.UPLOADED },
              },
              optimisticResponse: {
                __typename: "Mutation",
                updateFile: getOptimisticResponse(filesWithId[idx], FILE_STATES.UPLOADED),
              },
              update: (proxy, { data: { updateFile } }) => {
                const query = { query: FILES };
                const data: any = proxy.readQuery(query);

                proxy.writeQuery({
                  ...query,
                  data: {
                    files: upsertObject(data?.files, updateFile),
                  },
                });
              },
            });
          } catch (error) {
            Sentry.captureException(error);
            toast.error(
              <ToastBody
                icon="arrow-up"
                message={filesWithId[idx].name}
                title={t("status.failed")}
                variant="error"
              />,
            );
            updateFile({
              variables: {
                input: { id: filesWithId[idx].id, state: FILE_STATES.FAILED },
              },
              optimisticResponse: {
                __typename: "Mutation",
                updateFile: getOptimisticResponse(filesWithId[idx], FILE_STATES.FAILED),
              },
            });
          }
        });
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    [SASString, createFiles, getOptimisticResponse, t, token, updateFile],
  );

  const handleDeleteFiles = useCallback(
    (ids: any) => {
      return deleteFiles({
        variables: {
          ids,
        },
        optimisticResponse: {
          deleteFiles: {
            success: true,
            __typename: "MutationResult",
          },
        },
        update: (proxy) => {
          const query = { query: FILES };
          const data: any = proxy.readQuery(query);

          proxy.writeQuery({
            ...query,
            data: {
              files: data?.files.filter((f: File) => !ids.includes(f.id)) ?? [],
            },
          });
        },
      });
    },
    [deleteFiles],
  );

  return {
    uploadFiles: handleUploadFiles,
    deleteFiles: handleDeleteFiles,
  };
}
