import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const languages = import.meta.glob<{ default: Record<string, any> }>("./*.json", { eager: true });
const resources = Object.keys(languages).reduce((translations, key) => {
  const language = key.match(/(?!\/i18n\/)(\w+)(?=\.json)/g)?.[0];

  if (language == null) {
    return translations;
  }

  return {
    ...translations,
    [language]: languages[key].default,
  };
}, {});

//TODO fix language parsing

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: resources,
    // TODO migrate to new json compat syntax, beware of plural change!
    compatibilityJSON: "v3",
    preload: ["en"],
    fallbackLng: {
      default: ["en"],
      nn: ["nb", "en"],
    },
    supportedLngs: ["da", "de", "el", "en", "es", "fr", "nl", "nb"],
    nonExplicitSupportedLngs: true,
    interpolation: {
      escapeValue: false,
    },
    defaultNS: "common",
    ns: "common",
  });

export default i18n;
