export function openZendeskDetail(labels: string[], onClose: () => void) {
  // @ts-ignore
  window.zE(function () {
    // @ts-ignore
    window.zE?.apply(null, ["webWidget", "open"]);
    // @ts-ignore
    window.zE?.apply(null, [
      "webWidget",
      "helpCenter:setSuggestions",
      {
        labels,
      },
    ]);
    // @ts-ignore
    window.zE("webWidget:on", "close", onClose);
  });
}
