import React, { ReactElement } from "react";
import { useDecodedToken } from "@app/hooks/useToken";
import clsx from "clsx";

import { Button, Variants } from "../../../../components/Button/Button";
import { Icon } from "../../../../components/Icon/Icon";
import { FILE_STATES, UploadedFile as File } from "../../../../types/File";

import { COLOR_MAP } from "./File";

import styles from "./File.module.scss";

interface Props {
  file: File;
  onDelete: () => void;
  state: FILE_STATES;
}

export const ICON_MAP: Record<FILE_STATES, ReactElement | undefined> = {
  [FILE_STATES.FAILED]: <Icon icon="times-circle" />,
  [FILE_STATES.UPLOADING]: <Icon icon="spinner" spin />,
  [FILE_STATES.UPLOADED]: <Icon icon="check-circle" />,
  [FILE_STATES.REMOVED]: undefined,
  [FILE_STATES.SUCCESS]: undefined,
};

export function FileActions(props: Props) {
  const { file, onDelete, state } = props;
  const currentUser = useDecodedToken();
  const isCreator = currentUser?.participantId === file.participant?.id;

  function handleDelete(e: any) {
    e.preventDefault();

    return onDelete();
  }

  if (state === FILE_STATES.UPLOADED) {
    return (
      <div className={styles.Actions}>
        {isCreator && (
          <Button onClick={handleDelete} variant={Variants.FLAT}>
            <Icon className={styles.red} icon="trash" />
          </Button>
        )}
      </div>
    );
  }

  return <div className={clsx(styles.Icon, COLOR_MAP[state])}>{ICON_MAP[state]}</div>;
}
