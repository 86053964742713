import React, { useEffect, useRef } from "react";
import { IconName } from "@fortawesome/fontawesome-svg-core";

import { EmptyState, EmptyStateProps } from "../EmptyState/EmptyState";

import { IItem, Item } from "./Item";

import styles from "./Feed.module.scss";

interface Props {
  items: IItem[];
  iconLeft?: IconName;
  emptyState: EmptyStateProps;
  onSave?: (item: IItem) => void;
}

export function Feed(props: Props) {
  const feed = useRef<HTMLDivElement>(null);
  const { emptyState, iconLeft, items, onSave } = props;

  useEffect(() => {
    if (feed.current !== null) {
      feed.current.scrollTop = feed.current.scrollHeight;
    }
  }, [feed, items.length]);

  return (
    <div className={styles.Feed} ref={feed}>
      {items.length > 0 ? (
        <>
          {items.map((item) => (
            <Item iconLeft={iconLeft} item={item} key={item.id} onSave={onSave} />
          ))}
          <div className={styles.filler} />
        </>
      ) : (
        <EmptyState {...emptyState} />
      )}
    </div>
  );
}
