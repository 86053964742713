// src/types/Colors.ts
var Colors = /* @__PURE__ */ ((Colors2) => {
  Colors2["BRAND"] = "BRAND";
  Colors2["GRAY"] = "GRAY";
  Colors2["RED"] = "RED";
  Colors2["ORANGE"] = "ORANGE";
  Colors2["YELLOW"] = "YELLOW";
  Colors2["LIME"] = "LIME";
  Colors2["GREEN"] = "GREEN";
  Colors2["TEAL"] = "TEAL";
  Colors2["CYAN"] = "CYAN";
  Colors2["BLUE"] = "BLUE";
  Colors2["INDIGO"] = "INDIGO";
  Colors2["PURPLE"] = "PURPLE";
  Colors2["FUCHSIA"] = "FUCHSIA";
  Colors2["PINK"] = "PINK";
  return Colors2;
})(Colors || {});
var text500 = {
  ["BRAND" /* BRAND */]: "text-brand-500",
  ["GRAY" /* GRAY */]: "text-gray-500",
  ["RED" /* RED */]: "text-red-500",
  ["ORANGE" /* ORANGE */]: "text-orange-500",
  ["YELLOW" /* YELLOW */]: "text-yellow-500",
  ["LIME" /* LIME */]: "text-lime-500",
  ["GREEN" /* GREEN */]: "text-green-500",
  ["TEAL" /* TEAL */]: "text-teal-500",
  ["CYAN" /* CYAN */]: "text-cyan-500",
  ["BLUE" /* BLUE */]: "text-blue-500",
  ["INDIGO" /* INDIGO */]: "text-indigo-500",
  ["PURPLE" /* PURPLE */]: "text-purple-500",
  ["FUCHSIA" /* FUCHSIA */]: "text-fuchsia-500",
  ["PINK" /* PINK */]: "text-pink-500"
};
var text600 = {
  ["BRAND" /* BRAND */]: "text-brand-600",
  ["GRAY" /* GRAY */]: "text-gray-600",
  ["RED" /* RED */]: "text-red-600",
  ["ORANGE" /* ORANGE */]: "text-orange-600",
  ["YELLOW" /* YELLOW */]: "text-yellow-600",
  ["LIME" /* LIME */]: "text-lime-600",
  ["GREEN" /* GREEN */]: "text-green-600",
  ["TEAL" /* TEAL */]: "text-teal-600",
  ["CYAN" /* CYAN */]: "text-cyan-600",
  ["BLUE" /* BLUE */]: "text-blue-600",
  ["INDIGO" /* INDIGO */]: "text-indigo-600",
  ["PURPLE" /* PURPLE */]: "text-purple-600",
  ["FUCHSIA" /* FUCHSIA */]: "text-fuchsia-600",
  ["PINK" /* PINK */]: "text-pink-600"
};
var text700 = {
  ["BRAND" /* BRAND */]: "text-brand-700",
  ["GRAY" /* GRAY */]: "text-gray-700",
  ["RED" /* RED */]: "text-red-700",
  ["ORANGE" /* ORANGE */]: "text-orange-700",
  ["YELLOW" /* YELLOW */]: "text-yellow-700",
  ["LIME" /* LIME */]: "text-lime-700",
  ["GREEN" /* GREEN */]: "text-green-700",
  ["TEAL" /* TEAL */]: "text-teal-700",
  ["CYAN" /* CYAN */]: "text-cyan-700",
  ["BLUE" /* BLUE */]: "text-blue-700",
  ["INDIGO" /* INDIGO */]: "text-indigo-700",
  ["PURPLE" /* PURPLE */]: "text-purple-700",
  ["FUCHSIA" /* FUCHSIA */]: "text-fuchsia-700",
  ["PINK" /* PINK */]: "text-pink-700"
};
var text700Hover = {
  ["BRAND" /* BRAND */]: "hover:text-brand-700",
  ["GRAY" /* GRAY */]: "hover:text-gray-700",
  ["RED" /* RED */]: "hover:text-red-700",
  ["ORANGE" /* ORANGE */]: "hover:text-orange-700",
  ["YELLOW" /* YELLOW */]: "hover:text-yellow-700",
  ["LIME" /* LIME */]: "hover:text-lime-700",
  ["GREEN" /* GREEN */]: "hover:text-green-700",
  ["TEAL" /* TEAL */]: "hover:text-teal-700",
  ["CYAN" /* CYAN */]: "hover:text-cyan-700",
  ["BLUE" /* BLUE */]: "hover:text-blue-700",
  ["INDIGO" /* INDIGO */]: "hover:text-indigo-700",
  ["PURPLE" /* PURPLE */]: "hover:text-purple-700",
  ["FUCHSIA" /* FUCHSIA */]: "hover:text-fuchsia-700",
  ["PINK" /* PINK */]: "hover:text-pink-700"
};
var text900Hover = {
  ["BRAND" /* BRAND */]: "hover:text-brand-900",
  ["GRAY" /* GRAY */]: "hover:text-gray-900",
  ["RED" /* RED */]: "hover:text-red-900",
  ["ORANGE" /* ORANGE */]: "hover:text-orange-900",
  ["YELLOW" /* YELLOW */]: "hover:text-yellow-900",
  ["LIME" /* LIME */]: "hover:text-lime-900",
  ["GREEN" /* GREEN */]: "hover:text-green-900",
  ["TEAL" /* TEAL */]: "hover:text-teal-900",
  ["CYAN" /* CYAN */]: "hover:text-cyan-900",
  ["BLUE" /* BLUE */]: "hover:text-blue-900",
  ["INDIGO" /* INDIGO */]: "hover:text-indigo-900",
  ["PURPLE" /* PURPLE */]: "hover:text-purple-900",
  ["FUCHSIA" /* FUCHSIA */]: "hover:text-fuchsia-900",
  ["PINK" /* PINK */]: "hover:text-pink-900"
};
var bg100Hover = {
  ["BRAND" /* BRAND */]: "hover:bg-brand-100",
  ["GRAY" /* GRAY */]: "hover:bg-gray-100",
  ["RED" /* RED */]: "hover:bg-red-100",
  ["ORANGE" /* ORANGE */]: "hover:bg-orange-100",
  ["YELLOW" /* YELLOW */]: "hover:bg-yellow-100",
  ["LIME" /* LIME */]: "hover:bg-lime-100",
  ["GREEN" /* GREEN */]: "hover:bg-green-100",
  ["TEAL" /* TEAL */]: "hover:bg-teal-100",
  ["CYAN" /* CYAN */]: "hover:bg-cyan-100",
  ["BLUE" /* BLUE */]: "hover:bg-blue-100",
  ["INDIGO" /* INDIGO */]: "hover:bg-indigo-100",
  ["PURPLE" /* PURPLE */]: "hover:bg-purple-100",
  ["FUCHSIA" /* FUCHSIA */]: "hover:bg-fuchsia-100",
  ["PINK" /* PINK */]: "hover:bg-pink-100"
};
var bg200 = {
  ["BRAND" /* BRAND */]: "bg-brand-200",
  ["GRAY" /* GRAY */]: "bg-gray-200",
  ["RED" /* RED */]: "bg-red-200",
  ["ORANGE" /* ORANGE */]: "bg-orange-200",
  ["YELLOW" /* YELLOW */]: "bg-yellow-200",
  ["LIME" /* LIME */]: "bg-lime-200",
  ["GREEN" /* GREEN */]: "bg-green-200",
  ["TEAL" /* TEAL */]: "bg-teal-200",
  ["CYAN" /* CYAN */]: "bg-cyan-200",
  ["BLUE" /* BLUE */]: "bg-blue-200",
  ["INDIGO" /* INDIGO */]: "bg-indigo-200",
  ["PURPLE" /* PURPLE */]: "bg-purple-200",
  ["FUCHSIA" /* FUCHSIA */]: "bg-fuchsia-200",
  ["PINK" /* PINK */]: "bg-pink-200"
};
var bg200Hover = {
  ["BRAND" /* BRAND */]: "hover:bg-brand-200",
  ["GRAY" /* GRAY */]: "hover:bg-gray-200",
  ["RED" /* RED */]: "hover:bg-red-200",
  ["ORANGE" /* ORANGE */]: "hover:bg-orange-200",
  ["YELLOW" /* YELLOW */]: "hover:bg-yellow-200",
  ["LIME" /* LIME */]: "hover:bg-lime-200",
  ["GREEN" /* GREEN */]: "hover:bg-green-200",
  ["TEAL" /* TEAL */]: "hover:bg-teal-200",
  ["CYAN" /* CYAN */]: "hover:bg-cyan-200",
  ["BLUE" /* BLUE */]: "hover:bg-blue-200",
  ["INDIGO" /* INDIGO */]: "hover:bg-indigo-200",
  ["PURPLE" /* PURPLE */]: "hover:bg-purple-200",
  ["FUCHSIA" /* FUCHSIA */]: "hover:bg-fuchsia-200",
  ["PINK" /* PINK */]: "hover:bg-pink-200"
};
var bg300Hover = {
  ["BRAND" /* BRAND */]: "hover:bg-brand-300",
  ["GRAY" /* GRAY */]: "hover:bg-gray-300",
  ["RED" /* RED */]: "hover:bg-red-300",
  ["ORANGE" /* ORANGE */]: "hover:bg-orange-300",
  ["YELLOW" /* YELLOW */]: "hover:bg-yellow-300",
  ["LIME" /* LIME */]: "hover:bg-lime-300",
  ["GREEN" /* GREEN */]: "hover:bg-green-300",
  ["TEAL" /* TEAL */]: "hover:bg-teal-300",
  ["CYAN" /* CYAN */]: "hover:bg-cyan-300",
  ["BLUE" /* BLUE */]: "hover:bg-blue-300",
  ["INDIGO" /* INDIGO */]: "hover:bg-indigo-300",
  ["PURPLE" /* PURPLE */]: "hover:bg-purple-300",
  ["FUCHSIA" /* FUCHSIA */]: "hover:bg-fuchsia-300",
  ["PINK" /* PINK */]: "hover:bg-pink-300"
};
var bg400Hover = {
  ["BRAND" /* BRAND */]: "hover:bg-brand-400",
  ["GRAY" /* GRAY */]: "hover:bg-gray-400",
  ["RED" /* RED */]: "hover:bg-red-400",
  ["ORANGE" /* ORANGE */]: "hover:bg-orange-400",
  ["YELLOW" /* YELLOW */]: "hover:bg-yellow-400",
  ["LIME" /* LIME */]: "hover:bg-lime-400",
  ["GREEN" /* GREEN */]: "hover:bg-green-400",
  ["TEAL" /* TEAL */]: "hover:bg-teal-400",
  ["CYAN" /* CYAN */]: "hover:bg-cyan-400",
  ["BLUE" /* BLUE */]: "hover:bg-blue-400",
  ["INDIGO" /* INDIGO */]: "hover:bg-indigo-400",
  ["PURPLE" /* PURPLE */]: "hover:bg-purple-400",
  ["FUCHSIA" /* FUCHSIA */]: "hover:bg-fuchsia-400",
  ["PINK" /* PINK */]: "hover:bg-pink-400"
};
var bg500 = {
  ["BRAND" /* BRAND */]: "bg-brand-500",
  ["GRAY" /* GRAY */]: "bg-gray-500",
  ["RED" /* RED */]: "bg-red-500",
  ["ORANGE" /* ORANGE */]: "bg-orange-500",
  ["YELLOW" /* YELLOW */]: "bg-yellow-500",
  ["LIME" /* LIME */]: "bg-lime-500",
  ["GREEN" /* GREEN */]: "bg-green-500",
  ["TEAL" /* TEAL */]: "bg-teal-500",
  ["CYAN" /* CYAN */]: "bg-cyan-500",
  ["BLUE" /* BLUE */]: "bg-blue-500",
  ["INDIGO" /* INDIGO */]: "bg-indigo-500",
  ["PURPLE" /* PURPLE */]: "bg-purple-500",
  ["FUCHSIA" /* FUCHSIA */]: "bg-fuchsia-500",
  ["PINK" /* PINK */]: "bg-pink-500"
};
var bg600Hover = {
  ["BRAND" /* BRAND */]: "hover:bg-brand-600",
  ["GRAY" /* GRAY */]: "hover:bg-gray-600",
  ["RED" /* RED */]: "hover:bg-red-600",
  ["ORANGE" /* ORANGE */]: "hover:bg-orange-600",
  ["YELLOW" /* YELLOW */]: "hover:bg-yellow-600",
  ["LIME" /* LIME */]: "hover:bg-lime-600",
  ["GREEN" /* GREEN */]: "hover:bg-green-600",
  ["TEAL" /* TEAL */]: "hover:bg-teal-600",
  ["CYAN" /* CYAN */]: "hover:bg-cyan-600",
  ["BLUE" /* BLUE */]: "hover:bg-blue-600",
  ["INDIGO" /* INDIGO */]: "hover:bg-indigo-600",
  ["PURPLE" /* PURPLE */]: "hover:bg-purple-600",
  ["FUCHSIA" /* FUCHSIA */]: "hover:bg-fuchsia-600",
  ["PINK" /* PINK */]: "hover:bg-pink-600"
};
var bg700Hover = {
  ["BRAND" /* BRAND */]: "hover:bg-brand-700",
  ["GRAY" /* GRAY */]: "hover:bg-gray-700",
  ["RED" /* RED */]: "hover:bg-red-700",
  ["ORANGE" /* ORANGE */]: "hover:bg-orange-700",
  ["YELLOW" /* YELLOW */]: "hover:bg-yellow-700",
  ["LIME" /* LIME */]: "hover:bg-lime-700",
  ["GREEN" /* GREEN */]: "hover:bg-green-700",
  ["TEAL" /* TEAL */]: "hover:bg-teal-700",
  ["CYAN" /* CYAN */]: "hover:bg-cyan-700",
  ["BLUE" /* BLUE */]: "hover:bg-blue-700",
  ["INDIGO" /* INDIGO */]: "hover:bg-indigo-700",
  ["PURPLE" /* PURPLE */]: "hover:bg-purple-700",
  ["FUCHSIA" /* FUCHSIA */]: "hover:bg-fuchsia-700",
  ["PINK" /* PINK */]: "hover:bg-pink-700"
};
var ring500Focus = {
  ["BRAND" /* BRAND */]: "focus:ring-brand-500/30",
  ["GRAY" /* GRAY */]: "focus:ring-gray-500/30",
  ["RED" /* RED */]: "focus:ring-red-500/30",
  ["ORANGE" /* ORANGE */]: "focus:ring-orange-500/30",
  ["YELLOW" /* YELLOW */]: "focus:ring-yellow-500/30",
  ["LIME" /* LIME */]: "focus:ring-lime-500/30",
  ["GREEN" /* GREEN */]: "focus:ring-green-500/30",
  ["TEAL" /* TEAL */]: "focus:ring-teal-500/30",
  ["CYAN" /* CYAN */]: "focus:ring-cyan-500/30",
  ["BLUE" /* BLUE */]: "focus:ring-blue-500/30",
  ["INDIGO" /* INDIGO */]: "focus:ring-indigo-500/30",
  ["PURPLE" /* PURPLE */]: "focus:ring-purple-500/30",
  ["FUCHSIA" /* FUCHSIA */]: "focus:ring-fuchsia-500/30",
  ["PINK" /* PINK */]: "focus:ring-pink-500/30"
};
var ring700Active = {
  ["BRAND" /* BRAND */]: "focus:ring-brand-700",
  ["GRAY" /* GRAY */]: "focus:ring-gray-700",
  ["RED" /* RED */]: "focus:ring-red-700",
  ["ORANGE" /* ORANGE */]: "focus:ring-orange-700",
  ["YELLOW" /* YELLOW */]: "focus:ring-yellow-700",
  ["LIME" /* LIME */]: "focus:ring-lime-700",
  ["GREEN" /* GREEN */]: "focus:ring-green-700",
  ["TEAL" /* TEAL */]: "focus:ring-teal-700",
  ["CYAN" /* CYAN */]: "focus:ring-cyan-700",
  ["BLUE" /* BLUE */]: "focus:ring-blue-700",
  ["INDIGO" /* INDIGO */]: "focus:ring-indigo-700",
  ["PURPLE" /* PURPLE */]: "focus:ring-purple-700",
  ["FUCHSIA" /* FUCHSIA */]: "focus:ring-fuchsia-700",
  ["PINK" /* PINK */]: "focus:ring-pink-700"
};
var ring700Focus = {
  ["BRAND" /* BRAND */]: "focus:ring-brand-700/50",
  ["GRAY" /* GRAY */]: "focus:ring-gray-700/50",
  ["RED" /* RED */]: "focus:ring-red-700/50",
  ["ORANGE" /* ORANGE */]: "focus:ring-orange-700/50",
  ["YELLOW" /* YELLOW */]: "focus:ring-yellow-700/50",
  ["LIME" /* LIME */]: "focus:ring-lime-700/50",
  ["GREEN" /* GREEN */]: "focus:ring-green-700/50",
  ["TEAL" /* TEAL */]: "focus:ring-teal-700/50",
  ["CYAN" /* CYAN */]: "focus:ring-cyan-700/50",
  ["BLUE" /* BLUE */]: "focus:ring-blue-700/50",
  ["INDIGO" /* INDIGO */]: "focus:ring-indigo-700/50",
  ["PURPLE" /* PURPLE */]: "focus:ring-purple-700/50",
  ["FUCHSIA" /* FUCHSIA */]: "focus:ring-fuchsia-700/50",
  ["PINK" /* PINK */]: "focus:ring-pink-700/50"
};
var border500 = {
  ["BRAND" /* BRAND */]: "border-brand-500",
  ["GRAY" /* GRAY */]: "border-gray-500",
  ["RED" /* RED */]: "border-red-500",
  ["ORANGE" /* ORANGE */]: "border-orange-500",
  ["YELLOW" /* YELLOW */]: "border-yellow-500",
  ["LIME" /* LIME */]: "border-lime-500",
  ["GREEN" /* GREEN */]: "border-green-500",
  ["TEAL" /* TEAL */]: "border-teal-500",
  ["CYAN" /* CYAN */]: "border-cyan-500",
  ["BLUE" /* BLUE */]: "border-blue-500",
  ["INDIGO" /* INDIGO */]: "border-indigo-500",
  ["PURPLE" /* PURPLE */]: "border-purple-500",
  ["FUCHSIA" /* FUCHSIA */]: "border-fuchsia-500",
  ["PINK" /* PINK */]: "border-pink-500"
};

// src/types/Themes.ts
var Themes = /* @__PURE__ */ ((Themes2) => {
  Themes2["BRAND"] = "BRAND";
  Themes2["NEUTRAL"] = "NEUTRAL";
  Themes2["ERROR"] = "ERROR";
  Themes2["WARNING"] = "WARNING";
  Themes2["SUCCESS"] = "SUCCESS";
  Themes2["INFO"] = "INFO";
  return Themes2;
})(Themes || {});
var ThemesToColors = {
  ["BRAND" /* BRAND */]: "BRAND" /* BRAND */,
  ["NEUTRAL" /* NEUTRAL */]: "GRAY" /* GRAY */,
  ["ERROR" /* ERROR */]: "RED" /* RED */,
  ["WARNING" /* WARNING */]: "ORANGE" /* ORANGE */,
  ["SUCCESS" /* SUCCESS */]: "GREEN" /* GREEN */,
  ["INFO" /* INFO */]: "BLUE" /* BLUE */
};
var FunctionalStates = /* @__PURE__ */ ((FunctionalStates2) => {
  FunctionalStates2["ERROR"] = "ERROR" /* ERROR */;
  FunctionalStates2["WARNING"] = "WARNING" /* WARNING */;
  FunctionalStates2["SUCCESS"] = "SUCCESS" /* SUCCESS */;
  FunctionalStates2["INFO"] = "INFO" /* INFO */;
  return FunctionalStates2;
})(FunctionalStates || {});
var ThemesToColorsMap = ((ThemesToColorsMap2) => {
  ThemesToColorsMap2[ThemesToColorsMap2["BRAND"] = "BRAND" /* BRAND */] = "BRAND";
  ThemesToColorsMap2[ThemesToColorsMap2["NEUTRAL"] = "GRAY" /* GRAY */] = "NEUTRAL";
  ThemesToColorsMap2[ThemesToColorsMap2["ERROR"] = "RED" /* RED */] = "ERROR";
  ThemesToColorsMap2[ThemesToColorsMap2["WARNING"] = "ORANGE" /* ORANGE */] = "WARNING";
  ThemesToColorsMap2[ThemesToColorsMap2["SUCCESS"] = "GREEN" /* GREEN */] = "SUCCESS";
  ThemesToColorsMap2[ThemesToColorsMap2["INFO"] = "BLUE" /* BLUE */] = "INFO";
  return ThemesToColorsMap2;
})(ThemesToColorsMap || {});
var BgShades = /* @__PURE__ */ ((BgShades2) => {
  BgShades2["WHITE"] = "WHITE";
  BgShades2["SHADE_50"] = "SHADE_50";
  BgShades2["SHADE_100"] = "SHADE_100";
  BgShades2["SHADE_500"] = "SHADE_500";
  BgShades2["SHADE_900"] = "SHADE_900";
  return BgShades2;
})(BgShades || {});

export {
  Colors,
  text500,
  text600,
  text700,
  text700Hover,
  text900Hover,
  bg100Hover,
  bg200,
  bg200Hover,
  bg300Hover,
  bg400Hover,
  bg500,
  bg600Hover,
  bg700Hover,
  ring500Focus,
  ring700Active,
  ring700Focus,
  border500,
  Themes,
  ThemesToColors,
  FunctionalStates,
  BgShades
};
