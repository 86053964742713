import { useCallback, useEffect, useRef } from "react";
import { createRoot, Root } from "react-dom/client";

import { ConfirmDialog } from "../components/ConfirmDialog/ConfirmDialog";

interface Options {
  cancelLabel?: string | null;
  confirmLabel?: string | null;
  isCancelDisabled?: boolean;
}

export function useConfirm() {
  const wrapper = useRef<HTMLDivElement>();
  const rootEl = useRef<Root>();

  const unmount = useCallback(() => {
    rootEl.current?.unmount();

    setTimeout(() => {
      if (wrapper.current?.parentNode !== null) {
        wrapper.current?.parentNode.removeChild(wrapper.current);
      }
    });
  }, []);

  const confirm = useCallback(
    (message: string, action: () => void, options?: Options) => {
      const wrapper = document.createElement("div");

      function onConfirm() {
        unmount();

        return action();
      }

      const root = createRoot(wrapper);
      rootEl.current = root;

      root.render(
        <ConfirmDialog
          cancelLabel={options?.cancelLabel}
          confirmLabel={options?.confirmLabel}
          isCancelDisabled={options?.isCancelDisabled}
          message={message}
          onCancel={unmount}
          onConfirm={onConfirm}
        />,
      );
    },
    [unmount],
  );

  useEffect(() => unmount, [unmount]);

  return confirm;
}
