import React from "react";

import loader from "../../assets/loader.gif";

import styles from "./Loader.module.css";

export function Loader() {
  return (
    <div className={styles.Loader}>
      <div className={styles.title}>Loading appointment</div>
      <img alt="" height={50} src={loader} />
    </div>
  );
}
