import {
  Appointment_appointment as Appointment,
  Appointment_appointment_participants as Participant,
  Messages_messages as Message,
} from "./api";

export enum FILE_STATES {
  FAILED = "FAILED",
  REMOVED = "REMOVED",
  SUCCESS = "SUCCESS",
  UPLOADED = "UPLOADED",
  UPLOADING = "UPLOADING",
}

export enum FileEvent {
  FILE_DELETED = "FILE_DELETED",
  FILE_UPDATED = "FILE_UPDATED",
  FILE_UPLOADED = "FILE_UPLOADED",
}

export interface FileObject {
  id: string;
  file: File;
  name: string;
}

export interface UploadedFile {
  id: string;
  appointment: Appointment;
  createdAt: Date;
  deletedAt: Date;
  expiresAt: Date;
  message?: Message | null;
  mimeType: string;
  name: string;
  participant?: Participant | null;
  state: FILE_STATES;
  thumbnail?: string | null;
  updatedAt: Date;
  url: string;
}

export interface UploadedFileResponse {
  id: string;
  mimeType: string;
  name: string;
}
