// src/components/Icon/Icon.tsx
import * as Icons from "@heroicons/react/24/outline";
import { jsx } from "react/jsx-runtime";
function Icon({ icon, ...props }) {
  const IconComponent = typeof icon === "string" ? Icons[icon] : icon;
  return /* @__PURE__ */ jsx(IconComponent, { ...props });
}

export {
  Icon
};
