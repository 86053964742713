import React, { ReactNode } from "react";

import styles from "./Modal.module.scss";

interface Props {
  children: ReactNode;
}

export function ModalBody(props: Props) {
  const { children } = props;

  return <div className={styles.ModalBody}>{children}</div>;
}
