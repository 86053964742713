import React from "react";
import { ToastContainer as Toastify } from "react-toastify";

import "react-toastify/dist/ReactToastify.min.css";
import "./ToastContainer.scss";

export function ToastContainer() {
  return (
    <Toastify
      autoClose={5 * 1000}
      icon={false}
      closeOnClick
      draggable
      hideProgressBar
      pauseOnHover
    />
  );
}
