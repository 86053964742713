import React from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "../../components/Icon/Icon";

import styles from "./BrowserFallback.module.scss";

interface Browser {
  name: string;
  url: string;
  version?: string;
  icon: JSX.Element;
}

const SUPPORTED_BROWSERS: Browser[] = [
  {
    icon: <Icon icon={["fab", "chrome"]} size="5x" />,
    name: "Google Chrome",
    version: "(not iOS)",
    url: "https://www.google.com/chrome",
  },
  {
    icon: <Icon icon={["fab", "firefox"]} size="5x" />,
    name: "Mozilla Firefox",
    url: "https://www.mozilla.org/firefox",
  },
  {
    icon: <Icon icon={["fab", "edge"]} size="5x" />,
    name: "Microsoft Edge",
    version: "(version 79 or later)",
    url: "https://www.microsoft.com/edge",
  },
  {
    icon: <Icon icon={["fab", "safari"]} size="5x" />,
    name: "Apple Safari",
    url: "https://www.apple.com/safari",
  },
];

export function BrowserFallback() {
  const { t } = useTranslation("browser_fallback");

  return (
    <div className={styles.BrowserFallback}>
      <h1 dangerouslySetInnerHTML={{ __html: t("title") }} />
      <p>{t("message")}</p>
      <h2>{t("action")}</h2>
      <div className={styles.list}>
        {SUPPORTED_BROWSERS.map((browser) => (
          <a href={browser.url} key={browser.name} rel="noopener noreferrer" target="_blank">
            {browser.icon}
            <span>
              {browser.name} <br />
              {browser.version}
            </span>
          </a>
        ))}
      </div>
    </div>
  );
}
