import { library } from "@fortawesome/fontawesome-svg-core";
import { faChrome, faEdge, faFirefox, faSafari } from "@fortawesome/free-brands-svg-icons";
import {
  faArrowDown,
  faArrowUp,
  faBars,
  faBell,
  faCaretSquareLeft,
  faCaretSquareRight,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faClone,
  faCog,
  faComments,
  faDesktop,
  faEllipsisV,
  faEnvelope,
  faExclamationCircle,
  faFile,
  faFileDownload,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFileVideo,
  faFileWord,
  faFolderOpen,
  faHeartBroken,
  faInfoCircle,
  faMicrophone,
  faMicrophoneSlash,
  faMinusSquare,
  faPaperclip,
  faPaperPlane,
  faPause,
  faPhoneAlt,
  faPlay,
  faSignal,
  faSpinner,
  faThumbtack,
  faTimes,
  faTimesCircle,
  faTrash,
  faUsers,
  faVideo,
  faVideoSlash,
  faVolumeMute,
  faVolumeUp,
  faWindowMaximize,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(
  faArrowDown,
  faArrowUp,
  faBars,
  faBell,
  faCaretSquareLeft,
  faCaretSquareRight,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChrome,
  faClone,
  faCog,
  faComments,
  faDesktop,
  faEdge,
  faEllipsisV,
  faEnvelope,
  faExclamationCircle,
  faFile,
  faFileDownload,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFileVideo,
  faFileWord,
  faFirefox,
  faFolderOpen,
  faHeartBroken,
  faInfoCircle,
  faMicrophone,
  faMicrophoneSlash,
  faMinusSquare,
  faPaperclip,
  faPaperPlane,
  faPause,
  faPhoneAlt,
  faPlay,
  faSafari,
  faSignal,
  faSpinner,
  faThumbtack,
  faTimes,
  faTimesCircle,
  faTrash,
  faUsers,
  faVideo,
  faVideoSlash,
  faVolumeMute,
  faVolumeUp,
  faWindowMaximize,
);

export const Icon = FontAwesomeIcon;
