import { ReactNode, useContext, useEffect } from "react";
import { useReactiveVar } from "@apollo/client";
import clsx from "clsx";

import { Button, Variants } from "../../components/Button/Button";
import { Icon } from "../../components/Icon/Icon";
import { ActionTypes } from "../../containers/SideNavigation/navReducer";
import { SideNavigation } from "../../containers/SideNavigation/SideNavigation";
import { NavContext } from "../../containers/SideNavigation/useNavContext";
import { isSideNavMobileShownVar } from "../../graphql/cache";
import { Appointment_appointment as Appointment } from "../../types/api";
import { NavItemKey } from "../../types/SideNavigation";
import { useControls } from "../Controls/useControls";

import { SidePanelContent } from "./SidePanelContent";

import styles from "./Panel.module.scss";

interface Props {
  appointment: Appointment;
  children: ReactNode;
  isOpenByDefault?: boolean;
}

export function PanelContainer({ appointment, children, isOpenByDefault = false }: Props) {
  const controls = useControls(true);
  const isSideNavMobileShown = useReactiveVar(isSideNavMobileShownVar);
  const {
    dispatch,
    state: { active_tab },
  } = useContext(NavContext);
  const isOpen = active_tab ? true : false;

  function handleClose() {
    controls.navigation?.onToggle(false);

    dispatch({
      type: ActionTypes.CLOSE_SIDEBAR,
    });
  }

  useEffect(() => {
    if (isOpenByDefault || isSideNavMobileShown) {
      dispatch({
        type: ActionTypes.SET_ACTIVE_TAB,
        tab: NavItemKey.CHAT,
      });
    }

    if (!isSideNavMobileShown) {
      dispatch({
        type: ActionTypes.CLOSE_SIDEBAR,
      });
    }
  }, [isSideNavMobileShown, dispatch, isOpenByDefault]);

  return (
    <div className={styles.Container}>
      <SideNavigation isSideNavMobileShown={isOpen} />
      <div
        className={clsx(styles.Content, {
          [styles.open]: isOpen,
        })}
      >
        <div className={styles.Panel}>
          <div className={styles.PanelContentWrapper}>
            <div className={styles.CloseButton}>
              <Button onClick={handleClose} variant={Variants.FLAT}>
                <Icon icon="times" />
              </Button>
            </div>
            <SidePanelContent appointment={appointment} />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
}
