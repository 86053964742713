import React from "react";

import { Controls } from "../../components/Controls/Controls";

import { useControls } from "./useControls";

export function UserControls() {
  const controls = useControls(false);

  return <Controls controls={controls} size="small" />;
}
