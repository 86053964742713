import React, { useContext } from "react";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import clsx from "clsx";

import { NavContext } from "../../containers/SideNavigation/useNavContext";
import { NavItemKey, NavPlacement } from "../../types/SideNavigation";
import { useControls } from "../Controls/useControls";

import { ActionTypes } from "./navReducer";
import { SideNavigationItem } from "./SideNavigationItem";

import styles from "./SideNavigation.module.scss";

interface Props {
  isSideNavMobileShown?: boolean;
}

export function SideNavigation(props: Props) {
  const {
    dispatch,
    state: { active_tab, navItems },
  } = useContext(NavContext);
  const { navigation } = useControls(true);
  const { isSideNavMobileShown } = props;

  function toggle(tab: NavItemKey) {
    dispatch({
      type: ActionTypes.RESET_NOTIFICATION_COUNTER,
      tab,
    });

    if (active_tab === tab) {
      navigation?.onToggle(false);

      return dispatch({
        type: ActionTypes.CLOSE_SIDEBAR,
      });
    }

    return dispatch({
      type: ActionTypes.SET_ACTIVE_TAB,
      tab,
    });
  }

  return (
    <div
      className={clsx(styles.SideNavigation, {
        [styles.open]: isSideNavMobileShown,
      })}
    >
      {Object.values(NavPlacement).map((block) => (
        <div className={styles.SideNavigationBlock} key={block}>
          {Object.entries(navItems)
            .filter(([_, navItem]) => navItem?.placement === block)
            .map(([itemType, navItem]) => (
              <SideNavigationItem
                {...navItem}
                icon={navItem?.icon as IconName}
                isActive={active_tab === itemType}
                key={itemType}
                notificationCount={navItem?.notificationCount}
                onChange={() => toggle(itemType as NavItemKey)}
                type={itemType as NavItemKey}
              />
            ))}
        </div>
      ))}
    </div>
  );
}
