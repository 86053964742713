import { useCallback, useEffect, useRef, useState } from "react";
import { useLocalMedia } from "@app/hooks/media/media";
import { useAnalyzer, useAudioMeter } from "@pexip/media-components";
import clsx from "clsx";

import { times } from "../../utils/times";

import styles from "./TestBar.module.scss";

export function TestBar() {
  const [strength, setStrength] = useState(0);
  const [width, setWidth] = useState(0);
  const requestRef = useRef<number>(0);
  const divRef = useRef<HTMLDivElement>(null);
  const localMedia = useLocalMedia();

  const dotsMax = Math.floor(width / 12);
  const avgVolHeximalScale = Math.floor((strength / 100) * dotsMax);
  const avgVolHasDecimalPoint = strength % 1 !== 0;

  const analyser = useAnalyzer(localMedia);
  const max = useAudioMeter(analyser);

  const animate = useCallback(() => {
    requestRef.current = requestAnimationFrame(animate);

    return setStrength(max);
  }, [max]);

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(requestRef.current);
  }, [animate]);

  useEffect(() => {
    divRef.current && setWidth(divRef.current.clientWidth);
  }, []);

  return (
    <div className={styles.SoundBar} ref={divRef}>
      {times(dotsMax).map((idx) => {
        const isActive = idx < avgVolHeximalScale;
        const isFaded = idx === avgVolHeximalScale && avgVolHasDecimalPoint;

        return (
          <div
            className={clsx(styles.Dot, isActive && styles.Active, isFaded && styles.Fade)}
            key={idx}
          />
        );
      })}
    </div>
  );
}
