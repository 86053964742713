import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { enterprise } from "@app/utils/enterprise";

import { ToastBody } from "../components/ToastContainer/components/ToastBody/ToastBody";
import { IS_RECORDING, IS_RECORDING_UPDATED } from "../containers/Recordings/graphql";
import { START_RECORDINGS, STOP_RECORDINGS } from "../graphql/mutations/recording";
import { RECORDINGS } from "../graphql/queries/recording";
import { ON_RECORDING_EVENT } from "../graphql/subscriptions/recording";
import { ModuleName, Recordings } from "../types/api";
import { upsertObject } from "../utils/arrayHelpers";

import { useModules } from "./useModules";
import { useDecodedToken } from "./useToken";

export function useRecordings() {
  const { appointmentId } = useDecodedToken() ?? {};
  const { isModuleEnabled } = useModules(ModuleName.RECORDINGS);

  const { data, subscribeToMore } = useQuery<Recordings>(RECORDINGS, {
    skip: !isModuleEnabled,
  });
  const recordings = data?.recordings ?? [];

  const [startRecordings, { loading: startLoading }] = useMutation(START_RECORDINGS, {
    onError(error) {
      console.error(error);
    },
  });

  const [stopRecordings, { loading: stopLoading }] = useMutation(STOP_RECORDINGS, {
    onError(error) {
      console.error(error);
    },
  });

  useEffect(() => {
    if (isModuleEnabled) {
      const unsubscribe = subscribeToMore({
        document: ON_RECORDING_EVENT,
        variables: {
          enterprise,
          appointmentId,
        },
        updateQuery: (prev: any, chunk: any) => {
          const recording = chunk.subscriptionData.data.onRecordingEvent;

          return {
            recordings: upsertObject(prev.recordings, recording),
          };
        },
      });

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [appointmentId, isModuleEnabled, subscribeToMore]);

  return {
    isLoading: startLoading || stopLoading,
    recordings,
    startRecordings,
    stopRecordings,
  };
}

export function useIsRecording() {
  const { t } = useTranslation("recordings");
  const { isModuleEnabled } = useModules(ModuleName.RECORDINGS);
  const { appointmentId } = useDecodedToken() ?? {};

  const { data: isRecordingData, subscribeToMore } = useQuery<{
    isRecording: boolean;
  }>(IS_RECORDING, {
    skip: !isModuleEnabled,
  });

  useEffect(() => {
    if (isModuleEnabled) {
      const unsubscribe = subscribeToMore({
        document: IS_RECORDING_UPDATED,
        variables: {
          enterprise,
          appointmentId,
        },
        updateQuery: (prev: any, chunk: any) => {
          const { isRecordingSubscription: isRecording } = chunk.subscriptionData.data ?? {};

          if (prev.isRecording !== isRecording) {
            toast.info(
              <ToastBody
                icon="video"
                message={isRecording ? t("start_toast.message") : t("stop_toast.message")}
                title={isRecording ? t("start_toast.title") : t("stop_toast.title")}
              />,
            );
          }

          return { isRecording };
        },
      });

      return () => {
        if (unsubscribe !== undefined) {
          unsubscribe();
        }
      };
    }
  }, [appointmentId, isModuleEnabled, subscribeToMore, t]);

  return isRecordingData?.isRecording ?? false;
}
