import { useEffect, useRef, useState } from "react";
import { enterprise } from "@app/utils/enterprise";
import WaveSurfer from "wavesurfer.js";

import { Icon } from "../Icon/Icon";

import styles from "./TestSound.module.scss";
interface Props {
  onPlayed?: () => void;
}

const SOUND_URL = `https://skedifyvideostorage.blob.core.windows.net/assets/${enterprise}/public/test_sound.mp3`;

export function TestSound(props: Props) {
  const { onPlayed } = props;
  const waveformRef = useRef<HTMLDivElement>(null);
  const wavesurfer = useRef<WaveSurfer>();
  const [isPlaying, setIsPlaying] = useState(false);

  function handlePlayPause() {
    if (onPlayed !== undefined) {
      onPlayed();
    }

    setIsPlaying(!isPlaying);
    wavesurfer.current?.playPause();
  }

  useEffect(() => {
    if (waveformRef.current != null) {
      wavesurfer.current = WaveSurfer.create({
        waveColor: getComputedStyle(document.documentElement).getPropertyValue("--color-gray-3"),
        progressColor: getComputedStyle(document.documentElement).getPropertyValue(
          "--color-primary",
        ),
        cursorColor: "transparent",
        barWidth: 3,
        container: waveformRef.current,
      });
      wavesurfer.current.load(SOUND_URL);
      wavesurfer.current.on("pause", () => setIsPlaying(false));
      wavesurfer.current.on("play", () => setIsPlaying(true));

      return () => wavesurfer.current?.destroy();
    }
  }, [waveformRef]);

  return (
    <div className={styles.TestSound}>
      <div className={styles.PlayPauseIcon} onClick={handlePlayPause}>
        {isPlaying ? <Icon color="white" icon="pause" /> : <Icon color="white" icon="play" />}
      </div>
      <div className={styles.Wave}>
        <div ref={waveformRef} />
      </div>
    </div>
  );
}
