import React from "react";
import {
  DropzoneOptions,
  DropzoneState,
  FileRejection,
  useDropzone as useDefaultDropzone,
} from "react-dropzone";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/browser";
import path from "path";

import { ToastBody } from "../components/ToastContainer/components/ToastBody/ToastBody";

export function useDropzone(options?: DropzoneOptions): DropzoneState {
  const { t } = useTranslation("files");
  const onDropRejected = (err: FileRejection[]) => {
    err.forEach((rejection) =>
      rejection.errors.forEach(({ code }) =>
        toast.error(
          <ToastBody
            icon="arrow-up"
            message={t(`validation.${code}`)}
            title={t(`validation.title`)}
            variant="error"
          />,
        ),
      ),
    );

    const wrongFileRejections = err.filter((rejection) =>
      rejection.errors.some(({ code }) => code === "file-invalid-type"),
    );
    Sentry.captureMessage(
      `A user was blocked uploading file(s) of type(s): ${wrongFileRejections
        .map(({ file: { name } }) => path.extname(name))
        .join(", ")}`,
    );
  };

  const maxSize = 1024 * 1024 * 100; // 100MB;
  const accept = {
    // See: https://www.iana.org/assignments/media-types/media-types.xhtml
    "audio/*": [], // These aren't actually official mimetypes.
    "video/*": [], // We can't use the wildcard to do things like 'application/vnd.openxmlformats-*'
    "image/*": [],
    "text/*": [],
    "application/zip": [".zip"],
    "application/gzip": [".gz"],
    "application/json": [".json", ".map"],
    "application/xml": [".xml", ".xsl", ".xsd", ".rng"],
    "application/pdf": [".pdf"],
    "application/mp4": [".mp4s", ".m4p"],
    // MS and Open/Libre Office
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.template": [".dotx"],
    "application/vnd.ms-word.document.macroEnabled.12": [".docm"],
    "application/vnd.ms-word.template.macroEnabled.12": [".dotm"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.template": [".xltx"],
    "application/vnd.ms-excel.sheet.macroEnabled.12": [".xlsm"],
    "application/vnd.ms-excel.template.macroEnabled.12": [".xltm"],
    "application/vnd.ms-excel.addin.macroEnabled.12": [".xlam"],
    "application/vnd.ms-excel.sheet.binary.macroEnabled.12": [".xlsb"],
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
    "application/vnd.openxmlformats-officedocument.presentationml.template": [".potx"],
    "application/vnd.openxmlformats-officedocument.presentationml.slideshow": [".ppsx"],
    "application/vnd.ms-powerpoint.addin.macroEnabled.12": [".ppam"],
    "application/vnd.ms-powerpoint.presentation.macroEnabled.12": [".pptm"],
    "application/vnd.ms-powerpoint.template.macroEnabled.12": [".potm"],
    "application/vnd.ms-powerpoint.slideshow.macroEnabled.12": [".ppsm"],
    "application/msword": [".doc", ".dot"],
    "application/vnd.ms-excel": [".xls", ".xlm", ".xla", ".xlc", ".xlt", ".xlw"],
    "application/vnd.ms-powerpoint": [".ppt", ".pps", ".pot"],
    // Apple iWork
    "application/vnd.apple.keynote": [".key"],
    "application/vnd.apple.mpegurl": [".m3u8"],
    "application/vnd.apple.numbers": [".numbers"],
    "application/vnd.apple.pages": [".pages"],
  };

  return useDefaultDropzone({
    ...options,
    accept,
    maxSize,
    multiple: true,
    noClick: true,
    onDropRejected: onDropRejected,
  });
}
