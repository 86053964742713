import React from "react";
import { useTranslation } from "react-i18next";
import { useReactiveVar } from "@apollo/client";
import { Icon, IconTypes } from "@pexip/components";

import { isMicrophoneMutedVar, isVideoMutedVar } from "../../graphql/cache";

import styles from "./Video.module.scss";

interface Props {
  error?: string | null;
}

export function VideoOverlay({ error }: Props) {
  const { t } = useTranslation();
  const isMicrophoneMuted = useReactiveVar(isMicrophoneMutedVar);
  const isVideoMuted = useReactiveVar(isVideoMutedVar);

  if (error != null) {
    return (
      <div className={styles.overlay}>
        <span>
          <Icon colorScheme="light" source={IconTypes.IconWarningRound} />

          {t(`device-status:${error}.title`)}
        </span>
      </div>
    );
  }

  if (!isMicrophoneMuted && !isVideoMuted) {
    return null;
  }

  return (
    <div className={styles.overlay}>
      {!isMicrophoneMuted && isVideoMuted && <span>{t("video:overlay.join_video_muted")}</span>}
      {isMicrophoneMuted && !isVideoMuted && (
        <span>{t("video:overlay.join_microphone_muted")}</span>
      )}
      {isMicrophoneMuted && isVideoMuted && (
        <span>{t("video:overlay.join_microphone_video_muted")}</span>
      )}
    </div>
  );
}
