import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";

import "./i18n";

import { ToastContainer } from "./components/ToastContainer/ToastContainer";
import { getApolloClient } from "./graphql/client";
import { useSentry } from "./hooks/useSentry";
import { AuthProvider, useToken } from "./hooks/useToken";
import { BrowserFallback } from "./pages/BrowserFallback/BrowserFallback";
import { NotFound } from "./pages/NotFound/NotFound";
import { isBrowserCompatible } from "./utils/isBrowserCompatible";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";

import "@fontsource/fira-sans/400-italic.css";
import "@fontsource/fira-sans/400.css";
import "@fontsource/fira-sans/500.css";
import "@fontsource/fira-sans/600.css";
import "@fontsource/fira-sans/700.css";
import "./index.css";
import "./tailwind.css";

function Root() {
  useSentry();

  if (!isBrowserCompatible()) {
    return <BrowserFallback />;
  }

  return (
    <>
      <Router>
        <AuthProvider>
          <Routes>
            <Route element={<ApolloApp />} path="/:token/*" />
            <Route element={<NotFound />} path="/" />
            <Route element={<Navigate to="/" replace />} path="*" />
          </Routes>
        </AuthProvider>
      </Router>
      <ToastContainer />
    </>
  );
}

function ApolloApp() {
  const token = useToken();
  const client = getApolloClient(token);

  return (
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  );
}

const rootEl = document.getElementById("root")!;
const root = createRoot(rootEl);
root.render(<Root />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
