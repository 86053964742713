import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Dropdown } from "../../../../components/Dropdown/Dropdown";
import { Appointment_appointment_participants as Participant } from "../../../../types/api";

import styles from "./AppointmentOptions.module.scss";

interface Props {
  currentUser: Participant;
}

export function AppointmentOptions(props: Props) {
  const { t } = useTranslation("waiting_room");
  const { currentUser } = props;

  const options = useMemo(() => {
    return [
      {
        label: t("options.cancel"),
        value: currentUser.pluginEditUrl.replace("act=edit", "act=cancel"),
      },
      {
        label: t("options.reschedule"),
        value: currentUser.pluginEditUrl.replace("act=edit", "act=reschedule"),
      },
    ];
  }, [currentUser.pluginEditUrl, t]);

  return (
    <div className={styles.AppointmentOptions}>
      {t("options.body")}{" "}
      <Dropdown label={t("options.label")} onChange={window.open} options={options} />
    </div>
  );
}
