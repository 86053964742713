import { useEffect, useState } from "react";
import { hotjar } from "react-hotjar";
import { useEnterpriseSettings } from "@app/graphql/hooks/useEnterpriseSettings.hooks";

export function useHotjar() {
  const [isInitialized, setIsInitialized] = useState(false);
  const { hotjarId, hotjarSv } = useEnterpriseSettings() ?? {};

  useEffect(() => {
    if (!isInitialized && hotjarId && hotjarSv) {
      hotjar.initialize(parseInt(hotjarId, 10), parseInt(hotjarSv, 10), false);
      setIsInitialized(true);
    }
  }, [hotjarId, hotjarSv, isInitialized]);
}
