import { gql } from "@apollo/client";

export const FILES = gql`
  query Files {
    files {
      id
      createdAt
      mimeType
      name
      state
      thumbnail
      url
      participant {
        fullName
        id
      }
    }
  }
`;

export const FILE_EVENT = gql`
  subscription OnFileEvent($appointmentId: String!) {
    onFileEvent(appointmentId: $appointmentId) {
      id
      createdAt
      event
      mimeType
      name
      state
      thumbnail
      url
      participant {
        fullName
        id
      }
    }
  }
`;

export const GET_SAS_STRING = gql`
  query SASString {
    getSASString
  }
`;
