import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import clsx from "clsx";

import { Icon } from "../Icon/Icon";

import styles from "./Tooltip.module.scss";

export enum Placement {
  TOP = "top",
  LEFT = "left",
  RIGHT = "right",
  BOTTOM = "bottom",
}

export enum PointerPlacement {
  START = "start",
  CENTER = "center",
  END = "end",
}

export enum Type {
  ERROR = "error",
  NEUTRAL = "neutral",
  SUCCESS = "success",
  WARNING = "warning",
}

interface Props {
  children: React.ReactNode;
  icon?: string;
  onClick?: () => void;
  pointer?: PointerPlacement;
  position?: Placement;
  title: string;
  type?: Type;
  withClose?: boolean;
}

export function Tooltip(props: Props) {
  const [showSettingsError, setShowSettingsError] = useState(true);

  const {
    children,
    icon,
    pointer,
    position = Placement.BOTTOM,
    title,
    type = Type.NEUTRAL,
    withClose = false,
  } = props;

  return (
    <CSSTransition classNames={{ ...styles }} in={showSettingsError} timeout={200} unmountOnExit>
      <div
        className={clsx(
          styles.Tooltip,
          styles[position],
          pointer && styles.Pointer,
          pointer && styles[pointer],
        )}
      >
        {icon && (
          <div className={clsx(styles.Icon, styles[type])}>
            <Icon icon={icon as IconName} size="xs" />
          </div>
        )}
        <div className={styles.Content}>
          <div className={styles.Header}>
            <p>
              <strong>{title}</strong>
            </p>
            {withClose && (
              <button className={styles.CloseButton} onClick={() => setShowSettingsError(false)}>
                <Icon icon="times" />
              </button>
            )}
          </div>
          <p>{children}</p>
        </div>
      </div>
    </CSSTransition>
  );
}
