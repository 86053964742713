import React from "react";

import { Appointment_appointment_participants } from "../../types/api";
import { Avatar } from "../Avatar/Avatar";

import styles from "./Participant.module.scss";

interface Props {
  participant: Pick<
    Appointment_appointment_participants,
    "avatar" | "email" | "firstName" | "lastName" | "fullName" | "title"
  >;
  size?: "small" | "normal" | "large";
  withContactInfo?: boolean;
}

export function Participant(props: Props) {
  const { participant, size, withContactInfo = false } = props;

  return (
    <div className={styles.Participant}>
      <Avatar participant={participant} size={size} />
      <div className={styles.right}>
        {participant.fullName}
        {participant.title && <small>{participant.title}</small>}
        {withContactInfo && participant.email && <small>{participant.email}</small>}
      </div>
    </div>
  );
}
