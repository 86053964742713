import { gql, useQuery } from "@apollo/client";
import { EnterpriseSettings } from "@app/types/api";
import { enterprise } from "@app/utils/enterprise";

export function useEnterpriseSettings() {
  const { data } = useQuery<EnterpriseSettings>(
    gql`
      query EnterpriseSettings($enterprise: String!) {
        enterpriseSettings(enterprise: $enterprise) {
          color
          hotjarId
          hotjarSv
          name
        }
      }
    `,
    {
      variables: {
        enterprise,
      },
    },
  );

  return data?.enterpriseSettings;
}
