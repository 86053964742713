import React, { useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useDecodedToken } from "@app/hooks/useToken";
import { formatISO } from "date-fns";

import { Appointment_appointment as Appointment, Messages } from "../../types/api";

import { ChatFeed } from "./components/ChatFeed/ChatFeed";
import { ChatInput } from "./components/ChatInput/ChatInput";
import { MESSAGES, NEW_MESSAGE, SEND_MESSAGE } from "./graphql";

import styles from "./Chat.module.css";
interface Props {
  appointment: Appointment;
  onNewNotification: () => void;
}

export function Chat(props: Props) {
  const { appointment, onNewNotification } = props;
  const { data, error, loading, subscribeToMore } = useQuery<Messages>(MESSAGES);
  const messages = data?.messages ?? [];
  const token = useDecodedToken();

  const [sendMessage] = useMutation(SEND_MESSAGE);
  const onSubmit = (body: string) => {
    sendMessage({
      variables: {
        input: { body },
      },
      optimisticResponse: {
        __typename: "Mutation",
        sendMessage: {
          __typename: "Message",
          id: new Date().getTime().toString(),
          body,
          createdAt: formatISO(new Date()),
          sender: appointment.participants.find(
            (participant) => participant.id === token?.participantId,
          ),
        },
      },
      update: (proxy, { data: { sendMessage } }) => {
        const query = { query: MESSAGES };
        const data: any = proxy.readQuery(query);

        proxy.writeQuery({
          ...query,
          data: {
            messages: [...data.messages, sendMessage],
          },
        });
      },
    }).catch(console.error);
  };

  useEffect(() => {
    if (token != null && subscribeToMore !== undefined) {
      const unsubscribe = subscribeToMore({
        document: NEW_MESSAGE,
        variables: {
          appointmentId: token?.appointmentId,
          userId: token?.participantId,
        },
        updateQuery: (prev: any, chunk: any) => {
          const message = chunk.subscriptionData.data.newMessage;

          if (!message) {
            return prev;
          }

          onNewNotification();

          return {
            messages: [...prev.messages, message],
          };
        },
      });

      return () => unsubscribe();
    }
  }, [onNewNotification, subscribeToMore, token]);

  if (loading || error) {
    return null;
  }

  return (
    <div className={styles.Chat}>
      <ChatFeed messages={messages} />
      <ChatInput onSubmit={onSubmit} />
    </div>
  );
}
