import { IconName } from "@fortawesome/fontawesome-svg-core";
import { produce } from "immer";

import { ModuleName } from "../../types/api";
import { NavItemKey, NavItems, NavPlacement } from "../../types/SideNavigation";

export function getInitialState(modules: ModuleName[]) {
  return {
    active_tab: null,
    navItems: {
      [NavItemKey.CHAT]: {
        icon: "comments" as IconName,
        notificationCount: 0,
        placement: NavPlacement.TOP,
      },
      [NavItemKey.FILES]: modules.includes(ModuleName.FILES)
        ? {
            icon: "folder-open" as IconName,
            notificationCount: 0,
            placement: NavPlacement.TOP,
          }
        : undefined,
      [NavItemKey.RECORDINGS]: modules.includes(ModuleName.RECORDINGS)
        ? {
            icon: "video" as IconName,
            notificationCount: 0,
            placement: NavPlacement.TOP,
          }
        : undefined,
      [NavItemKey.PARTICIPANTS]: {
        icon: "users" as IconName,
        notificationCount: 0,
        placement: NavPlacement.TOP,
      },
      [NavItemKey.SETTINGS]: {
        icon: "cog" as IconName,
        notificationCount: 0,
        placement: NavPlacement.BOTTOM,
      },
    },
  };
}

export type StateType = {
  active_tab: NavItemKey | null;
  navItems: NavItems;
};

export enum ActionTypes {
  CLOSE_SIDEBAR,
  INCREMENT_NOTIFICATION_COUNTER,
  RESET_NOTIFICATION_COUNTER,
  SET_ACTIVE_TAB,
}

export type ActionPayload =
  | { type: ActionTypes.CLOSE_SIDEBAR }
  | { type: ActionTypes.INCREMENT_NOTIFICATION_COUNTER; tab: NavItemKey }
  | { type: ActionTypes.RESET_NOTIFICATION_COUNTER; tab: NavItemKey }
  | { type: ActionTypes.SET_ACTIVE_TAB; tab: NavItemKey };

export const reducer = produce((draft: StateType, action: ActionPayload) => {
  switch (action.type) {
    case ActionTypes.INCREMENT_NOTIFICATION_COUNTER:
      // TODO: The UI component should decide whether to increment or not, and not the reducer.
      // However, that does not seem to work for files in onNewNotification in SidePanelContent.
      // So let's settle for this for now.
      if (action.tab !== draft.active_tab) {
        draft.navItems[action.tab]!.notificationCount = +1;
      }
      break;

    case ActionTypes.RESET_NOTIFICATION_COUNTER:
      draft.navItems[action.tab]!.notificationCount = 0;
      break;

    case ActionTypes.SET_ACTIVE_TAB:
      draft.active_tab = action.tab;
      break;

    case ActionTypes.CLOSE_SIDEBAR:
      draft.active_tab = null;
      break;

    default:
      break;
  }
});
