import React from "react";

import { Button, Variants } from "../Button/Button";
import { Icon } from "../Icon/Icon";

interface Props {
  children: string;
  onClick?: (evt: React.MouseEvent<HTMLElement>) => void;
}

export function DropdownButton(props: Props) {
  const { children, onClick } = props;

  return (
    <Button onClick={onClick} variant={Variants.FLAT}>
      {children}
      &nbsp;
      <Icon icon="chevron-down" />
    </Button>
  );
}
