import { gql } from "@apollo/client";

export const APPOINTMENT = gql`
  query Appointment {
    appointment {
      id
      availableAt
      externalId
      dialInNumber
      endTime
      pexipNode
      pexipVMR
      startTime
      state
      status
      participants {
        id
        avatar
        email
        firstName
        fullName
        language
        lastName
        pluginEditUrl
        title
        role
      }
      subject {
        branding: subjectBranding {
          backgroundUrl
          backgroundReplacementImage
          color
          logo
          watermarkLogo
        }
        description
        instructions
        title
      }
    }
  }
`;
