import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { CSSTransition } from "react-transition-group";
import { useMutation } from "@apollo/client";
import { useDecodedToken } from "@app/hooks/useToken";
import { Menu } from "@headlessui/react";
import { IconButton } from "@skedify/unify";

import { Icon } from "../../../../components/Icon/Icon";
import { ToastBody } from "../../../../components/ToastContainer/components/ToastBody/ToastBody";
import { Appointment_appointment_participants, Role } from "../../../../types/api";
import { TRIGGER_DIAL_IN } from "../../graphql";

import dropdownTransition from "./DropdownTransition.module.scss";
import styles from "./ParticipantActions.module.scss";

interface Props {
  participant: Appointment_appointment_participants;
  role?: Role;
}

export function ParticipantActions(props: Props) {
  const { participant, role } = props;
  const token = useDecodedToken();
  const { t } = useTranslation("participants");

  const [triggerDialIn] = useMutation(TRIGGER_DIAL_IN, {
    onCompleted: ({ triggerDialIn }) => {
      if (triggerDialIn.success && participant.id !== token?.participantId) {
        toast.success(
          <ToastBody
            icon="phone-alt"
            message={t("notifications.triggerDialIn.success.message")}
            title={t("notifications.triggerDialIn.success.title")}
          />,
        );
      }
    },
    onError: () => {
      toast.error(
        <ToastBody
          icon="phone-alt"
          message={t("notifications.triggerDialIn.error.message")}
          title={t("notifications.triggerDialIn.error.title")}
        />,
      );
    },
  });

  function handleTriggerDialIn() {
    triggerDialIn({
      variables: {
        participantId: participant.id,
      },
    }).catch(console.error);
  }

  if (role === Role.CUSTOMER) return null;

  return (
    <Menu>
      {({ open }) => (
        <div className={styles.ParticipantActionsDropdown}>
          <Menu.Button as={Fragment}>
            <IconButton aria-label="" icon="EllipsisVerticalIcon" />
          </Menu.Button>

          <CSSTransition
            classNames={dropdownTransition}
            in={open}
            timeout={200}
            appear
            unmountOnExit
          >
            <Menu.Items className={styles.Dropdown} static>
              <Menu.Item>
                {() => (
                  <div className={styles.MenuItem} onClick={handleTriggerDialIn}>
                    <div className={styles.Icon}>
                      <Icon icon="phone-alt" />
                    </div>
                    {t("menu.triggerDialIn")}
                  </div>
                )}
              </Menu.Item>
            </Menu.Items>
          </CSSTransition>
        </div>
      )}
    </Menu>
  );
}
