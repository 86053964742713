import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@app/components/Button/Button";
import { APP_HOST } from "@app/utils/enterprise";

interface Props {
  appointmentId: string;
}

export function HostActions(props: Props) {
  const { appointmentId } = props;
  const { t } = useTranslation("end_screen");

  return (
    <Button
      onClick={() => window.open(`https://${APP_HOST}/appointments/${appointmentId}`, "_blank")}
    >
      {t("view_details")}
    </Button>
  );
}
