import React from "react";
import { useTranslation } from "react-i18next";

import { Button, Variants } from "../Button/Button";
import { Modal } from "../Modal/Modal";
import { ModalBody } from "../Modal/ModalBody";
import { ModalFooter } from "../Modal/ModalFooter";

interface Props {
  cancelLabel?: string | null;
  confirmLabel?: string | null;
  isCancelDisabled?: boolean;
  message: string;
  onCancel: (evt: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onConfirm: (evt: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export function ConfirmDialog(props: Props) {
  const { t } = useTranslation("common");
  const { cancelLabel, confirmLabel, isCancelDisabled, message, onCancel, onConfirm } = props;

  return (
    <Modal
      onRequestClose={!isCancelDisabled ? onCancel : undefined}
      shouldCloseOnEsc={!isCancelDisabled}
      shouldCloseOnOverlayClick={!isCancelDisabled}
      hideCloseButton
      isOpen
    >
      <ModalBody>{message}</ModalBody>
      <ModalFooter>
        <>
          {!isCancelDisabled && (
            <Button onClick={onCancel} variant={Variants.FLAT}>
              {cancelLabel ?? t("cancel")}
            </Button>
          )}
        </>
        <Button onClick={onConfirm} variant={Variants.DANGER}>
          {confirmLabel ?? t("confirm")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
