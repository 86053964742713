import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";
import { isToday } from "date-fns";

import { Button } from "../../components/Button/Button";
import { DateTime } from "../../components/DateTime/DateTime";
import { Loader } from "../../components/Loader/Loader";
import { Participant } from "../../components/Participant/Participant";
import { PhotoContainer } from "../../components/PhotoContainer/PhotoContainer";
import { Spacer } from "../../components/Spacer/Spacer";
import { AppStates, Match, useAppStateMachine } from "../../hooks/useAppStateMachine";
import { useDecodedToken } from "../../hooks/useToken";
import { Appointment_appointment as Appointment, AppointmentState } from "../../types/api";
import { AcceptedAppointment } from "../../types/Appointment";

import { StepNames } from "./components/Steps/Steps";
import { StepWrapper } from "./components/Steps/Steps";

import styles from "./Setup.module.scss";

const SCROLL_OPTIONS: ScrollIntoViewOptions = {
  behavior: "smooth",
  block: "start",
};

const STEPS_ORDER: StepNames[] = [
  StepNames.PERMISSION,
  StepNames.VIDEO,
  StepNames.SOUND,
  StepNames.MIC,
];

interface Props {
  appointment: Appointment;
}

export function Setup({ appointment }: Props) {
  const { t } = useTranslation();
  const { transition } = useAppStateMachine();

  const { token } = useParams<"token">();
  const navigate = useNavigate();
  const location = useLocation();

  const { participantId } = useDecodedToken() ?? {};

  const [activeStep, setActiveStep] = useState(0);
  const [doneUntil, setDoneUntil] = useState(0);

  const isAppointmentToday = appointment.startTime && isToday(new Date(appointment.startTime));

  const collocutor = appointment.participants.find(
    (participant) => participant.id !== participantId,
  );
  const currentUser = appointment.participants.find(
    (participant) => participant.id === participantId,
  );

  const paramStep = new URLSearchParams(location.search).get("step") as StepNames;

  function goToNextStep() {
    const anchor = document.getElementById(STEPS_ORDER[activeStep]);

    anchor?.scrollIntoView(SCROLL_OPTIONS);
    setActiveStep(activeStep + 1);

    if (activeStep === doneUntil) {
      setDoneUntil(doneUntil + 1);
    }
  }

  function goToStep(stepName: StepNames) {
    const anchor = document.getElementById(stepName);

    anchor?.scrollIntoView(SCROLL_OPTIONS);
    setActiveStep(STEPS_ORDER.indexOf(stepName) + 1);
  }

  // TODO: can setup be launched from MEETING_ROOM state?
  function finishSetup() {
    navigate(`/${token}`, { replace: true });

    return transition({
      type: "SETUP_DONE",
    });
  }

  // TODO: does this still work with Xstate?
  useEffect(() => {
    // some browsers need a refresh to apply new Settings
    if (Object.values(StepNames).includes(paramStep)) {
      const index = STEPS_ORDER.indexOf(paramStep);

      setActiveStep(index + 1);

      navigate(`/${token}/setup`, { replace: true });
    }
  }, [navigate, paramStep, token]);

  return (
    <>
      <Match state={AppStates.LOADING}>
        <Loader />
      </Match>
      <Match state={AppStates.ERROR}>
        <Navigate to="/" replace />
      </Match>
      {appointment != null && (
        <PhotoContainer
          branding={appointment.subject.branding}
          titleLeft={`${t("hello")}, <br/><strong>${currentUser?.fullName}</strong>!`}
        >
          <div className={clsx(styles.Setup, styles.FullHeightWidth)}>
            {activeStep === 0 && (
              <Spacer>
                <div className={styles.SetupStart}>
                  <h2>
                    {t("setup:intro.title")}
                    {isAcceptedAppointment(appointment) && (
                      <>
                        {" "}
                        {t("for")}
                        <br />
                        <DateTime appointment={appointment} />
                      </>
                    )}{" "}
                    {t("with")}
                  </h2>
                  {collocutor !== undefined && (
                    <Spacer size="small">
                      <Participant participant={collocutor} />
                    </Spacer>
                  )}
                  <Spacer size="small">
                    <Button onClick={goToNextStep}>{t("setup:intro.button")}</Button>
                  </Spacer>
                </div>
              </Spacer>
            )}
            <div className={styles.Steps}>
              {STEPS_ORDER.map((stepName, idx) => (
                <StepWrapper
                  appointment={appointment as AcceptedAppointment}
                  goToStep={goToStep}
                  isActive={idx + 1 === activeStep}
                  isDone={idx + 1 < doneUntil}
                  isNext={idx + 1 === doneUntil}
                  key={stepName}
                  onClickNext={goToNextStep}
                  stepName={stepName as StepNames}
                />
              ))}
            </div>
            {activeStep === 5 && (
              <Spacer>
                <div className={styles.SetupEnding}>
                  <h2>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t("setup:outro.title"),
                      }}
                    />
                    {isAcceptedAppointment(appointment) && (
                      <>
                        <br />
                        <DateTime appointment={appointment} />
                      </>
                    )}{" "}
                    {t("with")}
                  </h2>
                  {collocutor !== undefined && (
                    <Spacer size="small">
                      <Participant participant={collocutor} />
                    </Spacer>
                  )}
                  {isAppointmentToday ? (
                    <Spacer size="small">
                      <Button onClick={finishSetup}>{t("setup:outro.goto_meeting.button")} </Button>
                    </Spacer>
                  ) : (
                    <p>{t("setup:outro.goto_meeting.text")}</p>
                  )}
                </div>
              </Spacer>
            )}
          </div>
        </PhotoContainer>
      )}
    </>
  );
}

function isAcceptedAppointment(appointment: Appointment): appointment is AcceptedAppointment {
  return appointment.state === AppointmentState.ACCEPTED && appointment.startTime !== null;
}
