import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";

import { Spacer } from "../../components/Spacer/Spacer";
import { Questions, QuestionType } from "../../types/api";

import { ANSWER_QUESTION, COMPLETE_SURVEY, QUESTIONS } from "./graphql";
import { Question } from "./Question";

import styles from "./Survey.module.scss";

function parseComparableDataTypes(dataType: "string" | "number", a: any, b: any): [any, any] {
  switch (dataType) {
    case "number":
      return [parseInt(a, 10), parseInt(b, 10)];
    case "string":
    default:
      return [`"${a}"`, `"${b}"`];
  }
}

export function Survey() {
  const { t } = useTranslation("end_screen");

  const [step, setStep] = useState(0);
  const [answers, setAnswers] = useState<Record<string, string>>({});

  const { data } = useQuery<Questions>(QUESTIONS);
  const [completeSurvey] = useMutation(COMPLETE_SURVEY);
  const [answerQuestion] = useMutation(ANSWER_QUESTION, {
    onError: () => null,
  });

  const questions = data?.questions ?? [];
  const activeQuestion = questions[step];

  const calculateNextStep = useCallback(
    (step: number, answers: Record<string, string>): number => {
      const { compareToQuestionId, compareValue, operator } = data?.questions[step + 1] ?? {};

      if (compareToQuestionId != null && compareValue != null && operator != null) {
        const answer = answers[compareToQuestionId];

        try {
          const [a, b] = parseComparableDataTypes(
            data?.questions[step].type === QuestionType.RATING ? "number" : "string",
            answer,
            compareValue,
          );

          if (
            // eslint-disable-next-line no-new-func
            !Function(`"use strict";return ${a} ${operator} ${b}`)()
          ) {
            return calculateNextStep(step + 1, answers);
          }
        } catch (e) {
          console.error(e);

          return calculateNextStep(step + 1, answers);
        }
      }

      return step + 1;
    },
    [data?.questions],
  );

  function handleAnswer(questionId: string, value: string) {
    answerQuestion({
      variables: { input: { questionId, value } },
    });

    const nextAnswers = { ...answers, [questionId]: value };
    setAnswers(nextAnswers);

    const nextStep = calculateNextStep(step, nextAnswers);
    setStep(nextStep);

    if (nextStep === questions.length) {
      completeSurvey();
    }
  }

  if (questions.length === 0) {
    return null;
  }

  if (step !== 0 && activeQuestion === undefined) {
    return (
      <div className={styles.NPSForm}>
        <h3 className={styles.title}>{t("survey_complete")}</h3>
      </div>
    );
  }

  return (
    <div className={styles.NPSForm}>
      <p className="small">{t("survey_text")}</p>
      <Spacer>
        <p>
          {t("survey_progress", {
            percentage: Math.round((step / questions.length) * 10) * 10, // Round to nearest decimal
          })}
        </p>
        {activeQuestion !== undefined && (
          <Question onAnswer={handleAnswer} question={activeQuestion} />
        )}
      </Spacer>
    </div>
  );
}
