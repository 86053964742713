/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Messages
// ====================================================

export interface Messages_messages_sender {
  id: string;
  avatar: string | null;
  email: string | null;
  lastName: string | null;
  firstName: string | null;
  fullName: string;
  role: Role;
  title: string | null;
}

export interface Messages_messages {
  id: string;
  body: string;
  createdAt: any;
  sender: Messages_messages_sender;
}

export interface Messages {
  messages: Messages_messages[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendMessage
// ====================================================

export interface SendMessage_sendMessage_sender {
  id: string;
  avatar: string | null;
  email: string | null;
  lastName: string | null;
  firstName: string | null;
  fullName: string;
  role: Role;
  title: string | null;
}

export interface SendMessage_sendMessage {
  id: string;
  body: string;
  createdAt: any;
  sender: SendMessage_sendMessage_sender;
}

export interface SendMessage {
  sendMessage: SendMessage_sendMessage;
}

export interface SendMessageVariables {
  input: SendMessageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: NewMessage
// ====================================================

export interface NewMessage_newMessage_sender {
  id: string;
  avatar: string | null;
  email: string | null;
  lastName: string | null;
  firstName: string | null;
  fullName: string;
  role: Role;
  title: string | null;
}

export interface NewMessage_newMessage {
  id: string;
  body: string;
  createdAt: any;
  sender: NewMessage_newMessage_sender;
}

export interface NewMessage {
  newMessage: NewMessage_newMessage;
}

export interface NewMessageVariables {
  appointmentId: string;
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Files
// ====================================================

export interface Files_files_participant {
  fullName: string;
  id: string;
}

export interface Files_files {
  id: string;
  createdAt: any;
  mimeType: string;
  name: string;
  state: FileState;
  thumbnail: string | null;
  url: string;
  participant: Files_files_participant | null;
}

export interface Files {
  files: Files_files[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: OnFileEvent
// ====================================================

export interface OnFileEvent_onFileEvent_participant {
  fullName: string;
  id: string;
}

export interface OnFileEvent_onFileEvent {
  id: string;
  createdAt: any;
  event: string;
  mimeType: string;
  name: string;
  state: FileState;
  thumbnail: string | null;
  url: string;
  participant: OnFileEvent_onFileEvent_participant | null;
}

export interface OnFileEvent {
  onFileEvent: OnFileEvent_onFileEvent;
}

export interface OnFileEventVariables {
  appointmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SASString
// ====================================================

export interface SASString {
  getSASString: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TriggerDialIn
// ====================================================

export interface TriggerDialIn_triggerDialIn {
  success: boolean;
}

export interface TriggerDialIn {
  triggerDialIn: TriggerDialIn_triggerDialIn;
}

export interface TriggerDialInVariables {
  participantId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: OnParticipantEvent
// ====================================================

export interface OnParticipantEvent_onParticipantEvent {
  body: string | null;
  timestamp: any;
  triggeredBy: string;
  type: ParticipantEventType;
}

export interface OnParticipantEvent {
  onParticipantEvent: OnParticipantEvent_onParticipantEvent;
}

export interface OnParticipantEventVariables {
  appointmentId: string;
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TimelineEvents
// ====================================================

export interface TimelineEvents_timelineEvents {
  id: string;
  body: string;
  createdAt: any;
  type: TimelineEventType;
  isSaving: boolean | null;
}

export interface TimelineEvents {
  timelineEvents: TimelineEvents_timelineEvents[];
}

export interface TimelineEventsVariables {
  recordingId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTimelineEvent
// ====================================================

export interface CreateTimelineEvent_createTimelineEvent {
  id: string;
  body: string;
  type: TimelineEventType;
  createdAt: any;
  isSaving: boolean | null;
}

export interface CreateTimelineEvent {
  createTimelineEvent: CreateTimelineEvent_createTimelineEvent;
}

export interface CreateTimelineEventVariables {
  input: CreateTimelineEventInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTimelineEvent
// ====================================================

export interface UpdateTimelineEvent_updateTimelineEvent {
  id: string;
  body: string;
  type: TimelineEventType;
  createdAt: any;
  isSaving: boolean | null;
}

export interface UpdateTimelineEvent {
  updateTimelineEvent: UpdateTimelineEvent_updateTimelineEvent;
}

export interface UpdateTimelineEventVariables {
  input: UpdateTimelineEventInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IsRecording
// ====================================================

export interface IsRecording {
  isRecording: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: IsRecordingSubscription
// ====================================================

export interface IsRecordingSubscription {
  isRecordingSubscription: boolean;
}

export interface IsRecordingSubscriptionVariables {
  enterprise: string;
  appointmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Questions
// ====================================================

export interface Questions_questions_options {
  label: string;
  value: string;
}

export interface Questions_questions {
  id: string;
  compareToQuestionId: string | null;
  compareValue: string | null;
  operator: string | null;
  order: number;
  options: Questions_questions_options[] | null;
  label: string;
  type: QuestionType;
}

export interface Questions {
  questions: Questions_questions[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AnswerQuestion
// ====================================================

export interface AnswerQuestion_answerQuestion {
  id: string;
}

export interface AnswerQuestion {
  answerQuestion: AnswerQuestion_answerQuestion;
}

export interface AnswerQuestionVariables {
  input: AnswerInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CompleteSurvey
// ====================================================

export interface CompleteSurvey {
  completeSurvey: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EnterpriseSettings
// ====================================================

export interface EnterpriseSettings_enterpriseSettings {
  color: string;
  hotjarId: string | null;
  hotjarSv: string | null;
  name: string;
}

export interface EnterpriseSettings {
  enterpriseSettings: EnterpriseSettings_enterpriseSettings;
}

export interface EnterpriseSettingsVariables {
  enterprise: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: endAppointment
// ====================================================

export interface endAppointment_endAppointment {
  success: boolean;
}

export interface endAppointment {
  endAppointment: endAppointment_endAppointment;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createFiles
// ====================================================

export interface createFiles_createFiles_participant {
  id: string;
  fullName: string;
}

export interface createFiles_createFiles {
  id: string;
  createdAt: any;
  mimeType: string;
  name: string;
  state: FileState;
  thumbnail: string | null;
  url: string;
  participant: createFiles_createFiles_participant | null;
}

export interface createFiles {
  createFiles: createFiles_createFiles[];
}

export interface createFilesVariables {
  input: FileInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteFiles
// ====================================================

export interface deleteFiles_deleteFiles {
  success: boolean;
}

export interface deleteFiles {
  deleteFiles: deleteFiles_deleteFiles;
}

export interface deleteFilesVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateFile
// ====================================================

export interface updateFile_updateFile_participant {
  id: string;
  fullName: string;
}

export interface updateFile_updateFile {
  id: string;
  createdAt: any;
  mimeType: string;
  name: string;
  state: FileState;
  thumbnail: string | null;
  url: string;
  participant: updateFile_updateFile_participant | null;
}

export interface updateFile {
  updateFile: updateFile_updateFile;
}

export interface updateFileVariables {
  input: UpdateFileInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Heartbeat
// ====================================================

export interface Heartbeat {
  heartbeat: boolean;
}

export interface HeartbeatVariables {
  inCall?: boolean | null;
  metrics?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: connectParticipant
// ====================================================

export interface connectParticipant_connectParticipant {
  success: boolean;
}

export interface connectParticipant {
  connectParticipant: connectParticipant_connectParticipant;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: disconnectParticipant
// ====================================================

export interface disconnectParticipant_disconnectParticipant {
  success: boolean;
}

export interface disconnectParticipant {
  disconnectParticipant: disconnectParticipant_disconnectParticipant;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: StartRecordings
// ====================================================

export interface StartRecordings_startRecordings {
  id: string;
  state: RecordingState;
}

export interface StartRecordings {
  startRecordings: StartRecordings_startRecordings[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: StopRecordings
// ====================================================

export interface StopRecordings_stopRecordings {
  id: string;
  state: RecordingState;
}

export interface StopRecordings {
  stopRecordings: StopRecordings_stopRecordings[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Appointment
// ====================================================

export interface Appointment_appointment_participants {
  id: string;
  avatar: string | null;
  email: string | null;
  firstName: string | null;
  fullName: string;
  language: string;
  lastName: string | null;
  pluginEditUrl: string;
  title: string | null;
  role: Role;
}

export interface Appointment_appointment_subject_branding {
  backgroundUrl: string;
  backgroundReplacementImage: string | null;
  color: string;
  logo: string;
  watermarkLogo: string | null;
}

export interface Appointment_appointment_subject {
  branding: Appointment_appointment_subject_branding;
  description: string | null;
  instructions: string;
  title: string;
}

export interface Appointment_appointment {
  id: string;
  availableAt: any | null;
  externalId: string;
  dialInNumber: string | null;
  endTime: any | null;
  pexipNode: string;
  pexipVMR: string | null;
  startTime: any | null;
  state: AppointmentState;
  status: AppointmentStatus | null;
  participants: Appointment_appointment_participants[];
  subject: Appointment_appointment_subject;
}

export interface Appointment {
  appointment: Appointment_appointment;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getModuleSettings
// ====================================================

export interface getModuleSettings_moduleSettings {
  key: string;
  type: ModuleSettingType;
  userControllable: boolean;
  value: string | null;
}

export interface getModuleSettings {
  moduleSettings: getModuleSettings_moduleSettings[];
}

export interface getModuleSettingsVariables {
  moduleName: ModuleName;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Recordings
// ====================================================

export interface Recordings_recordings {
  id: string;
  duration: number | null;
  name: string;
  size: number | null;
  state: RecordingState;
  type: RecordingType;
  url: string | null;
  createdAt: any;
  updatedAt: any;
}

export interface Recordings {
  recordings: Recordings_recordings[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: OnAppointmentUpdated
// ====================================================

export interface OnAppointmentUpdated_onAppointmentUpdated {
  availableAt: any | null;
  endTime: any | null;
  pexipVMR: string | null;
  startTime: any | null;
  state: AppointmentState;
  status: AppointmentStatus | null;
}

export interface OnAppointmentUpdated {
  onAppointmentUpdated: OnAppointmentUpdated_onAppointmentUpdated;
}

export interface OnAppointmentUpdatedVariables {
  appointmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: OnRecordingEvent
// ====================================================

export interface OnRecordingEvent_onRecordingEvent {
  id: string;
  state: RecordingState;
  name: string;
  duration: number | null;
  size: number | null;
  url: string | null;
  type: RecordingType;
  createdAt: any;
  updatedAt: any;
}

export interface OnRecordingEvent {
  onRecordingEvent: OnRecordingEvent_onRecordingEvent;
}

export interface OnRecordingEventVariables {
  enterprise: string;
  appointmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * All possible appointment states
 */
export enum AppointmentState {
  ACCEPTED = "ACCEPTED",
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  INCOMING = "INCOMING",
  OCCURRED = "OCCURRED",
  OUTGOING = "OUTGOING",
}

/**
 * All possible appointment statuses
 */
export enum AppointmentStatus {
  CANCELLED = "CANCELLED",
  CUSTOMER_READY = "CUSTOMER_READY",
  ENDED = "ENDED",
  HOST_READY = "HOST_READY",
  PENDING = "PENDING",
  SCHEDULED = "SCHEDULED",
  STARTED = "STARTED",
}

export enum FileState {
  FAILED = "FAILED",
  UPLOADED = "UPLOADED",
  UPLOADING = "UPLOADING",
}

/**
 * All registered module names
 */
export enum ModuleName {
  FILES = "FILES",
  HEARTBEAT = "HEARTBEAT",
  PEXIP = "PEXIP",
  RECORDINGS = "RECORDINGS",
}

/**
 * Possible data types for a Module Setting value
 */
export enum ModuleSettingType {
  Boolean = "Boolean",
  Email = "Email",
  Number = "Number",
  String = "String",
  URL = "URL",
}

export enum ParticipantEventType {
  DIAL_IN_REQUEST = "DIAL_IN_REQUEST",
}

export enum QuestionType {
  RATING = "RATING",
  SELECT = "SELECT",
  TEXT = "TEXT",
}

export enum RecordingState {
  FAILED = "FAILED",
  INITIALIZING = "INITIALIZING",
  READY = "READY",
  RECORDING = "RECORDING",
  STOPPED = "STOPPED",
}

export enum RecordingType {
  COMBINED = "COMBINED",
  PRESENTATION = "PRESENTATION",
  USER = "USER",
}

/**
 * Possible User roles
 */
export enum Role {
  CONTACT = "CONTACT",
  CUSTOMER = "CUSTOMER",
  SUPER_USER = "SUPER_USER",
}

export enum TimelineEventType {
  ANNOTATION = "ANNOTATION",
}

export interface AnswerInput {
  questionId: string;
  value: string;
}

export interface CreateTimelineEventInput {
  body: string;
  recordingId?: string | null;
  type: TimelineEventType;
}

export interface FileInput {
  id?: string | null;
  expiresAt: any;
  mimeType: string;
  name: string;
}

export interface SendMessageInput {
  body: string;
}

export interface UpdateFileInput {
  id: string;
  state?: FileState | null;
}

export interface UpdateTimelineEventInput {
  id: string;
  body: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
