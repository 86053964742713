import { enterprise, Environment, environment } from "@app/utils/enterprise";
import * as Sentry from "@sentry/browser";

const SENTRY_DSN = "https://74c5d9eba0d74e1f972a38d1bec30837@o323299.ingest.sentry.io/5208458";

export function useSentry() {
  if (environment !== Environment.DEVELOPMENT) {
    Sentry.init({
      dsn: SENTRY_DSN,
      enabled: true,
      environment: `${enterprise}-${environment}`,
      ignoreErrors: [/^ResizeObserver loop limit exceeded$/],
      release: __APP_VERSION__,
    });
  }
}
