import React from "react";
import { useTranslation } from "react-i18next";

import { PhotoContainer } from "../../components/PhotoContainer/PhotoContainer";
import { UnacceptedAppointment } from "../../types/Appointment";

interface Props {
  appointment: UnacceptedAppointment;
}

export function Declined(props: Props) {
  const { appointment } = props;
  const { t } = useTranslation("declined");
  const state = appointment.state.toLowerCase();

  return (
    <PhotoContainer branding={appointment.subject.branding}>
      <h2>{t(`title.${state}`)}</h2>
      <p>{t(`message.${state}`)}</p>
    </PhotoContainer>
  );
}
