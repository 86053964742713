// src/components/Loader/Loader.tsx
import clsx from "clsx";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
var Sizes = /* @__PURE__ */ ((Sizes2) => {
  Sizes2["SMALL"] = "SMALL";
  Sizes2["MEDIUM"] = "MEDIUM";
  Sizes2["LARGE"] = "LARGE";
  return Sizes2;
})(Sizes || {});
var SIZE_MAP = {
  ["SMALL" /* SMALL */]: "w-5 h-5 border-2",
  ["MEDIUM" /* MEDIUM */]: "w-8 h-8 border-4",
  ["LARGE" /* LARGE */]: "w-16 h-16 border-8"
};
function Loader({ size, text }) {
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    text && /* @__PURE__ */ jsx("div", { className: "mb-4 text-sm font-semibold uppercase tracking-wide text-gray-500", children: text }),
    /* @__PURE__ */ jsx(
      "div",
      {
        className: clsx("border-brand-200 animate-spin rounded-full", SIZE_MAP[size]),
        style: { borderTopColor: "transparent" }
      }
    )
  ] });
}
Loader.size = Sizes;
Loader.defaultProps = {
  size: Loader.size.MEDIUM
};

export {
  Loader
};
