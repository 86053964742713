import React from "react";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";

import { useDecodedToken } from "../../hooks/useToken";
import { AcceptedAppointment } from "../../types/Appointment";
import { getDateLocale } from "../../utils/dateFns";

interface Props {
  appointment: AcceptedAppointment;
}

export function DateTime({ appointment }: Props) {
  const { t } = useTranslation();
  const { language } = useDecodedToken() ?? {};
  const locale = getDateLocale(language);

  const parsedData = parseISO(appointment.startTime);
  const date = format(parsedData, "PP", {
    locale,
  });
  const time = format(parsedData, "p", {
    locale,
  });

  return (
    <span>
      <strong>{date}</strong> {t("at")} <strong>{time}</strong>{" "}
    </span>
  );
}
