import { da, de, enGB, fr, nb, nl } from "date-fns/locale";

const languages: { [key: string]: () => Locale } = {
  nl: () => nl,
  da: () => da,
  fr: () => fr,
  de: () => de,
  nb: () => nb,
  default: () => enGB,
};

export function getDateLocale(locale?: string): Locale {
  if (locale === undefined) {
    return languages.default();
  }

  const [trimmedLocale] = locale.split("-");

  if (trimmedLocale === undefined) {
    return languages.default();
  }

  return (languages[trimmedLocale] ?? languages.default)();
}
