import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";

import { Icon } from "../../../../components/Icon/Icon";
import { FILE_STATES, UploadedFile as File } from "../../../../types/File";

import { FileActions } from "./FileActions";
import { FileIcon } from "./FileIcon";
import { FileStatus } from "./FileStatus";

import styles from "./File.module.scss";

interface Props {
  file: File;
  onDelete: (ids: string[]) => void;
}

export const COLOR_MAP: Record<FILE_STATES, string> = {
  [FILE_STATES.FAILED]: styles.red,
  [FILE_STATES.UPLOADING]: styles.blue,
  [FILE_STATES.UPLOADED]: styles.green,
  [FILE_STATES.REMOVED]: "",
  [FILE_STATES.SUCCESS]: styles.green,
};

export function FileRow(props: Props) {
  const { file, onDelete } = props;
  const [fileUIState, setFileUIState] = useState<FILE_STATES>(file.state);

  const handleDelete = useCallback(() => {
    setFileUIState(FILE_STATES.REMOVED);
    setTimeout(() => {
      onDelete([file.id]);
    }, 500);
  }, [file.id, onDelete]);

  useEffect(() => {
    if (fileUIState === FILE_STATES.UPLOADING && file.state === FILE_STATES.UPLOADED) {
      setFileUIState(FILE_STATES.SUCCESS);
      setTimeout(() => {
        setFileUIState(FILE_STATES.UPLOADED);
      }, 1000);
    }

    if (fileUIState === FILE_STATES.UPLOADING && file.state === FILE_STATES.FAILED) {
      setFileUIState(FILE_STATES.FAILED);
      setTimeout(() => {
        handleDelete();
      }, 5000);
    }
  }, [file.state, fileUIState, handleDelete]);

  return (
    <div
      className={clsx(styles.FileWrapper, {
        [styles.removed]: fileUIState === FILE_STATES.REMOVED,
      })}
      key={file.id}
    >
      <a className={styles.File} href={file.url} rel="noopener noreferrer" target="_blank">
        <div className={styles.FileType}>
          {file.thumbnail ? (
            <img alt="thumbnail file" src={file.thumbnail} />
          ) : (
            <FileIcon type={file.mimeType} />
          )}
          <Icon className={styles.download} icon="arrow-down" size="lg" />
        </div>
        <div className={styles.Details}>
          <p className="strong">{file.name}</p>
          <p className="small">{file.participant?.fullName}</p>
          <FileStatus file={file} state={fileUIState} />
        </div>
      </a>
      <FileActions file={file} onDelete={handleDelete} state={fileUIState} />
    </div>
  );
}
