import React, { useRef, useState } from "react";
import Select, { OnChangeValue } from "react-select";

import { useOutsideClick } from "../../hooks/useOutsideClick";

import { DropdownButton } from "./DropdownButton";

type Option = {
  label: string;
  value: string;
};

interface Props {
  label: string;
  options: Option[];
  onChange: (value: string) => void;
}

const selectStyles = {
  control: () => ({
    fontWeight: 600,
  }),
  menu: (provided: any) => ({
    ...provided,
    marginTop: 0,
    width: "auto",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#eef2f6" : "transparent",
    color: "#000",
    cursor: "pointer",
    whiteSpace: "nowrap",
  }),
};

export function Dropdown(props: Props) {
  const ref = useRef(null);
  const { label, onChange, options } = props;
  const [isOpen, setIsOpen] = useState<boolean>();

  function toggleIsOpen() {
    setIsOpen(!isOpen);
  }

  function handleChange(option: OnChangeValue<Option, boolean>) {
    if (!option || "length" in option) {
      return;
    }

    toggleIsOpen();

    return onChange(option.value);
  }

  useOutsideClick(ref, () => {
    setIsOpen(false);
  });

  return (
    <div ref={ref}>
      <Select
        components={{
          Input: () => <DropdownButton onClick={toggleIsOpen}>{label}</DropdownButton>,
          IndicatorSeparator: null,
          DropdownIndicator: null,
        }}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        menuIsOpen={isOpen}
        onChange={handleChange}
        options={options}
        placeholder={null}
        styles={selectStyles}
        autoFocus
      />
    </div>
  );
}
