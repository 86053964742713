import {
  BACKGROUND_BLUR_AMOUNT,
  BANDWIDTHS,
  EDGE_BLUR_AMOUNT,
  FFT_SIZE,
  FLIP_HORIZONTAL,
  FOREGROUND_THRESHOLD,
  FRAME_RATE,
  PROCESSING_HEIGHT,
  PROCESSING_WIDTH,
  SILENT_DETECTION_DURATION_S,
  UPDATE_FREQUENCY_HZ,
  VAD_THROTTLE_MS,
} from "@app/hooks/media/constants";
import { denoiseWasm } from "@pexip/denoise/urls";
import {
  createAudioStreamProcess,
  createMedia,
  createMediaSignals,
  createVideoStreamProcess,
  Segmenters,
  setLogger,
  VideoRenderParams,
} from "@pexip/media";
import {
  generateMediaSignalHooks,
  qualityToMediaConstraints,
  StreamQuality,
} from "@pexip/media-components";
import { setLogger as MCSetLogger } from "@pexip/media-control";
import {
  createCanvasTransform,
  createMediapipeSegmenter,
  urls as mpUrls,
} from "@pexip/media-processor";
import isMobile from "ismobilejs";

import {
  audioInputDeviceIdVar,
  backgroundBlurAmountVar,
  bandwidthVar,
  edgeBlurAmountVar,
  foregroundThresholdVar,
  isMicrophoneMutedVar,
  isVideoMutedVar,
  videoInputDeviceIdVar,
  videoSegmentationEffectVar,
} from "../../graphql/cache";

interface State extends VideoRenderParams {
  sampleRate: number;
  noiseSuppression: boolean;
  denoise: boolean;
  vad: boolean;
  asd: boolean;
  updateFrequency: number;
  silentDetectionDuration: number;
  vadThrottleMS: number;
  fftSize: number;
  monitor: boolean;
  channelCount: number;
}

const isMobileDevice = isMobile(navigator).any;
export const videoSegmentation = isMobileDevice ? "none" : videoSegmentationEffectVar();

// Temporary read this from localStorage for debugging purposes
const foregroundThreshold = foregroundThresholdVar() ?? FOREGROUND_THRESHOLD;
const backgroundBlurAmount = backgroundBlurAmountVar() ?? BACKGROUND_BLUR_AMOUNT;
const edgeBlurAmount = edgeBlurAmountVar() ?? EDGE_BLUR_AMOUNT;

export const initialMediaState: State = {
  videoSegmentation,
  sampleRate: 48000, // Ideal sample rate for our own noise suppression implementation
  denoise: false, // Use our own implementation of noise suppression
  noiseSuppression: false, // Disable the built-in noise suppression
  vad: true, // Use Voice Activity Detection
  asd: true, // Use Audio Signal Detection to check if the microphone is malfunctioned
  updateFrequency: UPDATE_FREQUENCY_HZ,
  silentDetectionDuration: SILENT_DETECTION_DURATION_S,
  fftSize: FFT_SIZE,
  vadThrottleMS: VAD_THROTTLE_MS,
  width: PROCESSING_WIDTH,
  height: PROCESSING_HEIGHT,
  frameRate: FRAME_RATE,
  monitor: false,
  channelCount: 1,
  bgImageUrl: null,
  foregroundThreshold,
  backgroundBlurAmount,
  edgeBlurAmount,
  flipHorizontal: FLIP_HORIZONTAL,
};

const getDefaultConstraints = () => ({
  audio: {
    sampleRate: 48000,
    echoCancellation: true,
    denoise: initialMediaState.denoise,
    noiseSuppression: initialMediaState.noiseSuppression,
    vad: initialMediaState.vad,
    asd: initialMediaState.asd,
    ...(audioInputDeviceIdVar() ? { deviceId: { ideal: audioInputDeviceIdVar() } } : {}),
  },
  video: {
    ...qualityToMediaConstraints(getStreamQuality(bandwidthVar())),
    foregroundThreshold: initialMediaState.foregroundThreshold,
    backgroundBlurAmount: initialMediaState.backgroundBlurAmount,
    edgeBlurAmount: initialMediaState.edgeBlurAmount,
    flipHorizontal: initialMediaState.flipHorizontal,
    frameRate: initialMediaState.frameRate,
    videoSegmentation: initialMediaState.videoSegmentation,
    bgImageUrl: initialMediaState.bgImageUrl,
    ...(videoInputDeviceIdVar() ? { deviceId: { ideal: videoInputDeviceIdVar() } } : {}),
  },
});

setLogger({
  debug: (context, msg) => console.debug(msg, context),
  info: (context, msg) => console.info(msg, context),
  trace: (context, msg) => console.trace(msg, context),
  warn: (context, msg) => console.warn(msg, context),
  error: (context, msg) => console.error(msg, context),
  redact: (msg) => {},
  fatal: (context, msg) => console.error(msg, context),
  silent: (context, msg) => {},
});
MCSetLogger({
  debug: (context, msg) => console.debug(msg, context),
  info: (context, msg) => console.info(msg, context),
  trace: (context, msg) => console.trace(msg, context),
  warn: (context, msg) => console.warn(msg, context),
  error: (context, msg) => console.error(msg, context),
  redact: (msg) => {},
  fatal: (context, msg) => console.error(msg, context),
  silent: (context, msg) => {},
});

export const signals = createMediaSignals(["onDevicesChanged", "onStatusChanged"]);

const selfieJs = new URL("./selfie_segmentation/selfie_segmentation.js", window.origin);

const mediapipeSegmenter = createMediapipeSegmenter(`${window.origin}/selfie_segmentation`, {
  modelType: "general",
  processingWidth: PROCESSING_WIDTH,
  processingHeight: PROCESSING_HEIGHT,
  gluePath: selfieJs.href,
});

const segmenters: Segmenters = {
  mediapipeSelfie: mediapipeSegmenter,
};

const audioStreamProcessor = createAudioStreamProcess({
  shouldEnable: () => true,
  denoiseParams: {
    wasmURL: denoiseWasm.href,
    workletModule: mpUrls.denoise().href,
  },
  analyzerUpdateFrequency: initialMediaState.updateFrequency,
  audioSignalDetectionDuration: initialMediaState.silentDetectionDuration,
  throttleMs: initialMediaState.vadThrottleMS,
  fftSize: initialMediaState.fftSize,
  onAudioSignalDetected: signals.onSilentDetected.emit,
  onVoiceActivityDetected: signals.onVAD.emit,
});

const transformer = createCanvasTransform(mediapipeSegmenter, {
  effects: initialMediaState.videoSegmentation,
  width: initialMediaState.width,
  height: initialMediaState.height,
  foregroundThreshold: initialMediaState.foregroundThreshold,
  backgroundBlurAmount: initialMediaState.backgroundBlurAmount,
  edgeBlurAmount: initialMediaState.edgeBlurAmount,
  flipHorizontal: initialMediaState.flipHorizontal,
});

const videoStreamProcessor = createVideoStreamProcess({
  trackProcessorAPI: deriveApiFromBrowserUA(),
  shouldEnable: () => true,
  videoSegmentationModel: "mediapipeSelfie",
  segmenters,
  transformer,
  frameRate: initialMediaState.frameRate,
  processingWidth: initialMediaState.width,
  processingHeight: initialMediaState.height,
  backgroundBlurAmount: initialMediaState.backgroundBlurAmount,
  foregroundThreshold: initialMediaState.foregroundThreshold,
  edgeBlurAmount: initialMediaState.edgeBlurAmount,

  onError: (error) => {
    console.error(error);
  },
});

export const mediaController = createMedia({
  getMuteState: () => ({
    video: isVideoMutedVar(),
    audio: isMicrophoneMutedVar(),
  }),
  signals,
  mediaProcessors: [audioStreamProcessor, videoStreamProcessor],
  getDefaultConstraints,
});

export const { useDevices, useLocalMedia, useStreamStatus } = generateMediaSignalHooks({
  useDevices: {
    initial: () => mediaController.devices,
    subscribe: signals.onDevicesChanged.add,
  },

  useLocalMedia: {
    initial: () => mediaController.media,
    subscribe: signals.onMediaChanged.add,
  },

  useStreamStatus: {
    initial: () => mediaController.media.status,
    subscribe: signals.onStatusChanged.add,
  },
});

export const toggleFacingMode = () => {
  const currentFacingMode = mediaController.media.getSettings().video[0]?.facingMode;

  void mediaController.getUserMedia({
    audio: true,
    video: {
      facingMode: {
        ideal: currentFacingMode === "environment" ? "user" : "environment",
      },
    },
  });
};

// Temporary workaround for M108 to M110 Windows Chromium bugs
// Remove me after M111 becomes default for Windows Chromium
// (Also remove @types/user-agent.d.ts)
function deriveApiFromBrowserUA() {
  // https://developer.mozilla.org/en-US/docs/Web/API/NavigatorUAData
  // userAgentData is currently only available in Chromium as well as the
  // `MediaStreamTrackProcessor` API
  if ("userAgentData" in navigator) {
    const { platform } = navigator.userAgentData as NavigatorUAData;
    // Avoid using the API to workaround the browser bug introduced from M108
    if (platform === "Windows") {
      console.debug("Falling back to 'canvas' TrackProcessor");

      return "canvas";
    }

    console.debug("MediaStreamTrackProcessor available, using 'stream' TrackProcessor");

    return "stream";
  }

  console.debug("Falling back to 'canvas' TrackProcessor");

  // For other browsers, `MediaStreamTrackProcessor` API is not available
  return "canvas";
}

export const getStreamQuality = (bandwidth: string) => {
  const [low, medium, high, veryHigh] = BANDWIDTHS;

  switch (bandwidth) {
    case low:
      return StreamQuality.Low;
    case medium:
      return StreamQuality.Medium;
    case high:
      return StreamQuality.High;
    case veryHigh:
      return StreamQuality.VeryHigh;
    default:
      return StreamQuality.Auto;
  }
};
