import React from "react";

import styles from "./Typography.module.scss";

interface Props {
  children: string;
}

export function ErrorMessage({ children }: Props) {
  return <p className={styles.ErrorMessage}>{children}</p>;
}
