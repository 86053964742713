import React, { ReactNode } from "react";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import clsx from "clsx";

import { Icon } from "../Icon/Icon";

import styles from "./Banner.module.scss";

interface Props {
  children: ReactNode;
  type: "success" | "warning" | "error" | "neutral";
  onClose?: () => void;
}

export function Banner(props: Props) {
  const { children, type } = props;
  const icon = {
    success: "check-circle",
    warning: "bell",
    error: "exclamation-circle",
    neutral: "info-circle",
  };

  return (
    <div className={clsx(styles.Banner, styles[type])}>
      <div className={styles.Icon}>
        <Icon icon={icon[type] as IconName} />
      </div>
      <p>{children}</p>
    </div>
  );
}
