import React, { ErrorInfo } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import * as Sentry from "@sentry/browser";

import { EmptyState } from "../../components/EmptyState/EmptyState";

interface State {
  hasError: boolean;
}

// TODO: remove after type fixes
interface Props extends WithTranslation {
  children: React.ReactNode;
}

class FilesErrorComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.captureException(error);
  }

  render() {
    const { hasError } = this.state;
    const { children, t } = this.props;

    if (hasError) {
      return (
        <EmptyState
          icon="heart-broken"
          text={t("error_state_text")}
          title={t("error_state_title")}
        />
      );
    }

    return children;
  }
}

export const FilesErrorBoundary = withTranslation("files")(FilesErrorComponent);
