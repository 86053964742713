import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Banner } from "../../../../components/Banner/Banner";
import { Button, Variants } from "../../../../components/Button/Button";
import { Spacer } from "../../../../components/Spacer/Spacer";
import { useAppointment } from "../../../../hooks/useAppointment";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";
import { useDecodedToken } from "../../../../hooks/useToken";
import { Role } from "../../../../types/api";
import { openZendeskDetail } from "../../../../utils/openZendeskDetail";

import { StepProps } from "./Steps";

import styles from "./Steps.module.scss";

interface StepPropsFooter extends StepProps {
  enabled?: boolean;
}

export function StepFooter(props: StepPropsFooter) {
  const { enabled = true, onClickNext, stepName } = props;
  const { t } = useTranslation("setup");
  const { participantId, role } = useDecodedToken() ?? {};
  const appointment = useAppointment();
  const { isMobile } = useMediaQuery();
  const [troubleShootingClosed, setTroubleShootingClosed] = useState(false);

  const contactInfo = appointment?.participants.find(
    (participant) => participant.id !== participantId,
  );

  async function extraTroubleShooting() {
    openZendeskDetail(["troubleshooting-video"], () => setTroubleShootingClosed(true));
  }

  return (
    <div className={styles.Footer}>
      <Spacer size="small">
        {enabled ? (
          <Button onClick={onClickNext}>{t(`${stepName}.button_next`)}</Button>
        ) : (
          <Button variant={Variants.DISABLED}>{t(`${stepName}.button_next`)}</Button>
        )}

        <Button onClick={extraTroubleShooting} variant={Variants.INVERTED}>
          {isMobile
            ? t(`${stepName}.button_troubleshooting_mobile`)
            : t(`${stepName}.button_troubleshooting`)}
        </Button>
      </Spacer>
      {troubleShootingClosed && (
        <div className={styles.ContentFade}>
          <Banner type="warning">
            {role === Role.CONTACT ? (
              <>{t("troubleshooting.contact_admin")}</>
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html: t("troubleshooting.contact_agent", {
                    email: contactInfo?.email,
                    name: contactInfo?.fullName,
                  }),
                }}
              />
            )}
          </Banner>
        </div>
      )}
    </div>
  );
}
