import { gql } from "@apollo/client";

export const ON_RECORDING_EVENT = gql`
  subscription OnRecordingEvent($enterprise: String!, $appointmentId: String!) {
    onRecordingEvent(enterprise: $enterprise, appointmentId: $appointmentId) {
      id
      state
      name
      duration
      size
      url
      type
      createdAt
      updatedAt
    }
  }
`;
