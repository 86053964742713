import { gql } from "@apollo/client";

export const ON_APPOINTMENT_UPDATED = gql`
  subscription OnAppointmentUpdated($appointmentId: String!) {
    onAppointmentUpdated(appointmentId: $appointmentId) {
      availableAt
      endTime
      pexipVMR
      startTime
      state
      status
    }
  }
`;
