import React from "react";

import { Spacer } from "../Spacer/Spacer";

import styles from "./Separator.module.scss";

export function Separator() {
  return (
    <Spacer>
      <div className={styles.Separator} />
    </Spacer>
  );
}
