import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon, IconSource, IconTypes } from "@pexip/components";
import clsx from "clsx";
import isMobile from "ismobilejs";

import { ControlTypes, ControlVariants } from "../../types/Control";

import styles from "./Controls.module.scss";

interface Props {
  colorOff?: string;
  colorOn?: string;
  isLoading?: boolean;
  size: "large" | "small";
  type: ControlTypes;
  value?: boolean;
  variant?: ControlVariants;
  onChange: () => void;
}

const VARIANT_STYLES_MAP: Record<ControlVariants, string> = {
  [ControlVariants.DANGER]: styles.danger,
  [ControlVariants.DEFAULT]: "",
  [ControlVariants.OFF]: styles.off,
  [ControlVariants.ON]: styles.on,
};

export function Control({ isLoading, onChange, size, type, value, variant }: Props) {
  const { t } = useTranslation("controls");
  const iconSource = CONTROL_ICON_MAP[type][value ? "off" : "on"];

  return (
    <div className={clsx(styles.ControlWrapper, styles[size])}>
      <Tooltip hidden={isMobile(window.navigator).any} id="control-tooltip" variant="light" />
      <button
        className={clsx(styles.Control, VARIANT_STYLES_MAP[variant ?? ControlVariants.DEFAULT])}
        data-title={t(type)}
        data-tooltip-content={t(type)}
        data-tooltip-id="control-tooltip"
        data-tooltip-position-strategy="fixed"
        onClick={onChange}
      >
        {isLoading ? <FontAwesomeIcon icon="spinner" spin /> : <Icon source={iconSource} />}
      </button>
    </div>
  );
}

const CONTROL_ICON_MAP: Record<ControlTypes, Record<"off" | "on", IconSource>> = {
  audio: {
    off: IconTypes.IconSpeakerOff,
    on: IconTypes.IconSpeakerOn,
  },
  disconnect: {
    off: IconTypes.IconLeave,
    on: IconTypes.IconLeave,
  },
  microphone: {
    off: IconTypes.IconMicrophoneOff,
    on: IconTypes.IconMicrophoneOn,
  },
  navigation: {
    off: IconTypes.IconMoreVertical,
    on: IconTypes.IconMoreVertical,
  },
  pictureInPicture: {
    off: IconTypes.IconOpenInNew,
    on: IconTypes.IconOpenInNew,
  },
  presentation: {
    off: IconTypes.IconPresentationOn,
    on: IconTypes.IconPresentationOn,
  },
  settings: {
    off: IconTypes.IconSettings,
    on: IconTypes.IconSettings,
  },
  video: {
    off: IconTypes.IconVideoOff,
    on: IconTypes.IconVideoOn,
  },
  userFacing: {
    off: IconTypes.IconFlipCamera,
    on: IconTypes.IconFlipCamera,
  },
};
