import React from "react";

import { Icon } from "../Icon/Icon";

import { Button, Variants } from "./Button";

interface Props {
  onClose: () => void;
}

export function CloseButton(props: Props) {
  const { onClose } = props;

  return (
    <Button onClick={onClose} variant={Variants.FLAT}>
      <Icon icon="times" />
    </Button>
  );
}
