import { useCallback, useEffect } from "react";
import { audioInputDeviceIdVar, bandwidthVar, videoInputDeviceIdVar } from "@app/graphql/cache";
import { Bandwidth } from "@app/hooks/media/constants";
import { isInitial, isInitialPermissionsNotGranted, isRejected } from "@pexip/media";
import { qualityToMediaConstraints } from "@pexip/media-components";
import { MediaDeviceInfoLike } from "@pexip/media-control";

import { getStreamQuality, mediaController, signals } from "./media";

export function useSwitchDisconnectedDevice() {
  useEffect(() =>
    signals.onDevicesChanged.add(() => {
      if (
        !isInitialPermissionsNotGranted(mediaController.media.status) &&
        !isInitial(mediaController.media.status) &&
        !isRejected(mediaController.media.status)
      ) {
        mediaController.getUserMedia({ audio: true, video: true });
      }
    }, []),
  );
}

export function useUpdateVideoInputDevice() {
  return useCallback(async (device?: MediaDeviceInfoLike) => {
    if (!device || device.deviceId === videoInputDeviceIdVar()) return;

    videoInputDeviceIdVar(device.deviceId);
    localStorage.setItem("videoInputDeviceId", device.deviceId);

    mediaController.getUserMediaAsync({
      audio: true,
      video: {
        deviceId: device.deviceId,
      },
    });
  }, []);
}

export function useUpdateAudioInputDevice() {
  return useCallback(async (device?: MediaDeviceInfoLike) => {
    if (!device || device.deviceId === audioInputDeviceIdVar()) return;

    audioInputDeviceIdVar(device.deviceId);
    localStorage.setItem("audioInputDeviceId", device.deviceId);

    mediaController.getUserMediaAsync({
      video: true,
      audio: {
        deviceId: device.deviceId,
      },
    });
  }, []);
}

export function useUpdateBandwidth() {
  return useCallback(async (bandwidth: Bandwidth | "") => {
    const newBandwidth = bandwidth === "" ? null : bandwidth;

    if (newBandwidth === bandwidthVar()) return;

    bandwidthVar(newBandwidth || null);
    localStorage.setItem("bandwidth", newBandwidth || null);

    mediaController.getUserMediaAsync({
      audio: true,
      video: {
        ...qualityToMediaConstraints(getStreamQuality(newBandwidth)),
      },
    });
  }, []);
}
