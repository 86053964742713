import React from "react";
import { IconName } from "@fortawesome/fontawesome-common-types";

import { Button } from "../Button/Button";
import { Icon } from "../Icon/Icon";

import styles from "./EmptyState.module.css";

export interface EmptyStateProps {
  icon: IconName;
  title?: string | null;
  text?: string | null;
  buttonText?: string;
  onClick?: (evt: React.MouseEvent<HTMLElement>) => void;
}

export function EmptyState(props: EmptyStateProps) {
  const { buttonText, icon, onClick, text, title } = props;

  return (
    <div className={styles.Container}>
      <div className={styles.Icon}>
        <Icon icon={icon} size="3x" />
      </div>
      <div className={styles.TextContainer}>
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
      {buttonText && onClick && <Button onClick={onClick}>{buttonText}</Button>}
    </div>
  );
}
