import React, { ReactElement } from "react";
import clsx from "clsx";

import { Logo } from "../../components/Logo/Logo";

import styles from "./PhotoContainer.module.scss";

interface Props {
  branding: {
    logo: string;
    backgroundUrl: string;
  };
  children: ReactElement | Array<ReactElement | boolean | undefined>;
  titleLeft?: string;
}

export function PhotoContainer(props: Props) {
  const { branding, children, titleLeft } = props;

  return (
    <div className={styles.PhotoContainer}>
      <div className={clsx(styles.left, styles.photoOverlay)}>
        <div
          className={styles.bgPhoto}
          style={{
            backgroundImage: `url(${branding.backgroundUrl})`,
          }}
        />
        {titleLeft && (
          <div
            className={styles.sides}
            dangerouslySetInnerHTML={{ __html: `<h1>${titleLeft}</h1>` }}
          />
        )}
      </div>
      <div className={styles.right}>
        <div className={clsx(styles.sides, styles.ScrollWrapper)}>
          <Logo src={branding.logo} />
          {children}
          {/* Spacer div for centering children */}
          <div />
        </div>
      </div>
    </div>
  );
}
