import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useReactiveVar } from "@apollo/client";
import { BackgroundsSetting } from "@app/containers/Settings/Backgrounds";
import {
  mediaController,
  useDevices,
  useLocalMedia,
  useStreamStatus,
} from "@app/hooks/media/media";
import {
  useSwitchDisconnectedDevice,
  useUpdateAudioInputDevice,
  useUpdateBandwidth,
  useUpdateVideoInputDevice,
} from "@app/hooks/media/useDevices";
import { useGUM } from "@app/hooks/media/useGum";
import { ERRORS } from "@app/hooks/media/useMediaError";
import { UserMediaStatus } from "@pexip/media";
import { findAudioInputDevices, findVideoInputDevices } from "@pexip/media-control";
import isMobile from "ismobilejs";

import { Banner } from "../../../../components/Banner/Banner";
import { Button, Variants } from "../../../../components/Button/Button";
import { Spacer } from "../../../../components/Spacer/Spacer";
import { TestSound } from "../../../../components/TestSound/TestSound";
import { UserVideo } from "../../../../components/Video/UserVideo";
import {
  BandwidthSettingsGroup,
  DeviceSettingsGroup,
} from "../../../../containers/Settings/SettingsGroup";
import {
  audioInputDeviceIdVar,
  bandwidthVar,
  isMicrophoneMutedVar,
  isVideoMutedVar,
  videoInputDeviceIdVar,
} from "../../../../graphql/cache";

import { BrowserSpecificHelp } from "./BrowserSpecificHelp";
import { StepFooter } from "./StepFooter";
import { StepProps } from "./Steps";

import styles from "./Steps.module.scss";
import settingStyles from "@app/containers/Settings/Settings.module.scss";
enum PermissionState {
  PERMISSION_REQUEST = "PERMISSION_REQUEST",
  PERMISSION_GRANTED = "PERMISSION_GRANTED",
  PERMISSION_DENIED = "PERMISSION_DENIED",
}

export function PermissionStep(props: StepProps) {
  const { onClickNext, stepName } = props;
  const { t } = useTranslation("setup");
  const [permissionLoading, setPermissionLoading] = useState<boolean | undefined>();
  const [state, setState] = useState<PermissionState>(PermissionState.PERMISSION_REQUEST);

  const status = useStreamStatus();
  const getUserMedia = useGUM(true);

  function requestPermission() {
    setPermissionLoading(true);
    getUserMedia();
  }

  useEffect(() => {
    if (status.startsWith(UserMediaStatus.PermissionsGranted)) {
      setState(PermissionState.PERMISSION_GRANTED);
      setPermissionLoading(false);
    }

    // TODO: handle other errors appropriately (currently all errors fallback to no permissions)
    if (ERRORS.includes(status)) {
      setState(PermissionState.PERMISSION_DENIED);
      setPermissionLoading(false);
    }
  }, [status, permissionLoading]);

  // Reset muted settings when starting setup
  useEffect(() => {
    isMicrophoneMutedVar(false);
    isVideoMutedVar(false);
    localStorage.removeItem("isMicrophoneMuted");
    localStorage.removeItem("isVideoMuted");
  }, []);

  switch (state) {
    case PermissionState.PERMISSION_REQUEST:
      return (
        <>
          <Spacer size="small">
            <Banner type="neutral">{t(`${stepName}.banner.info`)}</Banner>
          </Spacer>
          {permissionLoading ? (
            <Button onClick={requestPermission} variant={Variants.DISABLED}>
              {t(`${stepName}.button.checking`)}
            </Button>
          ) : (
            <Button onClick={requestPermission}>{t(`${stepName}.button.check`)}</Button>
          )}
        </>
      );
    case PermissionState.PERMISSION_GRANTED:
      setTimeout(function () {
        onClickNext();
      }, 1000);

      return (
        <div className={styles.ContentFade}>
          <Spacer size="small">
            <Banner type="success">{t(`${stepName}.banner.granted`)}</Banner>
          </Spacer>
          {permissionLoading && (
            <Button onClick={requestPermission} variant={Variants.DISABLED}>
              {t(`${stepName}.button.checking`)}
            </Button>
          )}
        </div>
      );
    case PermissionState.PERMISSION_DENIED:
      return (
        <div className={styles.ContentFade}>
          <div className={permissionLoading ? "" : styles.Shake}>
            <Spacer size="small">
              <Banner type="error">{t(`${stepName}.banner.denied`)}</Banner>
            </Spacer>
          </div>
          <BrowserSpecificHelp
            {...props}
            onClick={requestPermission}
            permissionLoading={permissionLoading ?? false}
          />
        </div>
      );
    default:
      return null;
  }
}

export function VideoStep(props: StepProps) {
  const devices = useDevices();
  const isMobileDevice = isMobile(navigator).any;

  const videoInputDevices = findVideoInputDevices(devices);
  const videoInputDeviceId = useReactiveVar(videoInputDeviceIdVar);
  const updateVideo = useUpdateVideoInputDevice();

  const bandwidth = useReactiveVar(bandwidthVar);
  const updateBandwidth = useUpdateBandwidth();

  const localMedia = useLocalMedia();

  useSwitchDisconnectedDevice();

  return (
    <>
      <div className={styles.MaxWidth}>
        <UserVideo media={localMedia} />
        <div className={settingStyles.Settings}>
          <div className={styles.SettingsGroup}>
            <DeviceSettingsGroup
              onChange={updateVideo}
              options={videoInputDevices}
              type="video"
              value={videoInputDeviceId ?? mediaController.media.videoInput?.deviceId ?? null}
            />
          </div>
          {!isMobileDevice && <BackgroundsSetting />}
          <div className={styles.SettingsGroup}>
            <BandwidthSettingsGroup onChange={updateBandwidth} value={bandwidth} />
          </div>
        </div>
      </div>
      <StepFooter {...props} />
    </>
  );
}

export function SoundStep(props: StepProps) {
  const { stepName } = props;
  const { t } = useTranslation("setup");
  const [isPlayedOnce, setIsPlayedOnce] = useState(false);

  return (
    <>
      <p>{t(`${stepName}.intro`)}</p>
      <div className={styles.MaxWidth}>
        <TestSound onPlayed={() => setIsPlayedOnce(true)} />
      </div>
      <StepFooter {...props} enabled={isPlayedOnce} />
    </>
  );
}

export function MicStep(props: StepProps) {
  const { stepName } = props;
  const { t } = useTranslation("setup");
  const devices = useDevices();
  const audioInputDevices = findAudioInputDevices(devices);
  const audioInputDeviceId = useReactiveVar(audioInputDeviceIdVar);
  const update = useUpdateAudioInputDevice();

  return (
    <>
      <p>{t(`${stepName}.intro`)}</p>
      <div className={styles.MaxWidth}>
        <DeviceSettingsGroup
          onChange={update}
          options={audioInputDevices}
          type="audio"
          value={audioInputDeviceId ?? mediaController.media.audioInput?.deviceId ?? null}
        />
      </div>
      <StepFooter {...props} />
    </>
  );
}
