import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDecodedToken } from "@app/hooks/useToken";
import clsx from "clsx";
import { format } from "date-fns";

import { FILE_STATES, UploadedFile as File } from "../../../../types/File";
import { getDateLocale } from "../../../../utils/dateFns";

import { COLOR_MAP } from "./File";

import styles from "./File.module.scss";

interface Props {
  file: File;
  state: FILE_STATES;
}

export function FileStatus(props: Props) {
  const { file, state } = props;
  const { t } = useTranslation("files");
  const token = useDecodedToken();

  const createdAt = useMemo(() => {
    const date = format(new Date(file.createdAt), "PP", {
      locale: getDateLocale(token?.language),
    });
    const time = format(new Date(file.createdAt), "p", {
      locale: getDateLocale(token?.language),
    });

    return `${date} ${time}`;
  }, [file.createdAt, token]);

  if (state === FILE_STATES.UPLOADED || state === FILE_STATES.REMOVED) {
    return <p className="small">{createdAt}</p>;
  }

  return (
    <p className={clsx(styles.Status, COLOR_MAP[state])}>{t(`status.${state.toLowerCase()}`)}</p>
  );
}
