import React, { useEffect, useRef } from "react";
import { useReactiveVar } from "@apollo/client";

import { isVolumeMutedVar } from "../../graphql/cache";

import styles from "./Video.module.scss";

interface Props {
  src?: MediaStream;
}

export function FloatingVideo(props: Props) {
  const { src } = props;
  const floatingVideoRef = useRef<HTMLVideoElement>(null);
  const isVolumeMuted = useReactiveVar(isVolumeMutedVar);

  useEffect(() => {
    (async function getFloatingVideo() {
      if (floatingVideoRef.current && src) {
        floatingVideoRef.current.srcObject = src;
      }
    })();
  }, [floatingVideoRef, src]);

  return (
    <div className={styles.Video}>
      <video muted={isVolumeMuted} ref={floatingVideoRef} autoPlay playsInline />
    </div>
  );
}
