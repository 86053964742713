import { gql } from "@apollo/client";

export const QUESTIONS = gql`
  query Questions {
    questions {
      id
      compareToQuestionId
      compareValue
      operator
      order
      options {
        label
        value
      }
      label
      type
    }
  }
`;

export const ANSWER_QUESTION = gql`
  mutation AnswerQuestion($input: AnswerInput!) {
    answerQuestion(input: $input) {
      id
    }
  }
`;

export const COMPLETE_SURVEY = gql`
  mutation CompleteSurvey {
    completeSurvey
  }
`;
