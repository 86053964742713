import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./RecordingIndicator.module.scss";

export function RecordingIndicator() {
  const { t } = useTranslation("recordings");

  return (
    <div className={styles.RecordingIndicator}>
      <div className={styles.DotContainer}>
        <div className={styles.Pulse} />
        <div className={styles.Dot} />
      </div>
      {t("recording")}
    </div>
  );
}
