import isMobile from "ismobilejs";

export enum Systems {
  WEB = "Web",
  ANDROID = "Android",
  IOS = "IOS",
  OTHER = "Other",
}

export function useOperatingSystem() {
  const ua = navigator.userAgent;

  if (!isMobile(ua).any) {
    return Systems.WEB;
  } else if (isMobile(ua).apple.phone) {
    return Systems.IOS;
  } else if (isMobile(ua).android.phone) {
    return Systems.ANDROID;
  } else {
    return Systems.OTHER;
  }
}
