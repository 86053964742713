import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { Icon } from "../Icon/Icon";

import styles from "./Button.module.scss";

export enum Variants {
  DANGER,
  DEFAULT,
  DISABLED,
  FLAT,
  INVERTED,
}
const VARIANT_STYLES_MAP: Record<Variants, string> = {
  [Variants.DANGER]: styles.danger,
  [Variants.DEFAULT]: "",
  [Variants.DISABLED]: styles.disabled,
  [Variants.FLAT]: styles.flat,
  [Variants.INVERTED]: styles.inverted,
};
interface Props {
  children: ReactNode;
  isFullWidth?: boolean;
  isLoading?: boolean;
  loadingMessage?: string | null;
  onClick?: (evt: React.MouseEvent<HTMLElement>) => void;
  type?: "button" | "submit" | "reset";
  variant?: Variants;
}

export function Button(props: Props) {
  const { children, isFullWidth, isLoading, loadingMessage, onClick, type, variant } = props;
  const { t } = useTranslation("common");

  return (
    <button
      className={clsx(
        styles.Button,
        isFullWidth && styles.fullWidth,
        isLoading && styles.disabled,
        VARIANT_STYLES_MAP[variant ?? Variants.DEFAULT],
      )}
      data-ignore-outside-click="true"
      disabled={isLoading || variant === Variants.DISABLED}
      onClick={onClick}
      type={type}
    >
      {isLoading ? (
        <>
          <Icon className={styles.isLoading} icon="spinner" spin />
          {loadingMessage ?? t("loading")}
        </>
      ) : (
        children
      )}
    </button>
  );
}

Button.variants = Variants;
