import React from "react";
import { useTranslation } from "react-i18next";
import { IconName } from "@fortawesome/fontawesome-svg-core";

import { Feed } from "../../components/Feed/Feed";
import { IItem } from "../../components/Feed/Item";
import { TimelineEvents_timelineEvents as TimelineEvent } from "../../types/api";

interface Props {
  isRecording: boolean;
  timelineEvents: TimelineEvent[];
  onSave: (item: IItem) => void;
}

export function RecordingsFeed(props: Props) {
  const { isRecording, onSave, timelineEvents } = props;
  const { t } = useTranslation("recordings");

  const emptyState = isRecording
    ? {
        icon: "thumbtack" as IconName,
        text: t("empty_state_pin_text"),
        title: t("empty_state_pin_title"),
      }
    : {
        icon: "video" as IconName,
        text: t("empty_state_text"),
        title: t("empty_state_title"),
      };

  return (
    <Feed emptyState={emptyState} iconLeft={"thumbtack"} items={timelineEvents} onSave={onSave} />
  );
}
