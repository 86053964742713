import React from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "../../../../components/Modal/Modal";
import { ModalBody } from "../../../../components/Modal/ModalBody";
import { ModalHeader } from "../../../../components/Modal/ModalHeader";
import { Appointment_appointment as Appointment } from "../../../../types/api";

import styles from "./DialIn.module.scss";

export function DialInModal(
  props: Pick<Appointment, "dialInNumber"> & {
    dialInCode?: string;
    dialInPin?: string;
    isOpen: boolean;
    onClose: () => void;
  },
) {
  const { dialInCode, dialInNumber, dialInPin, isOpen, onClose } = props;
  const { t } = useTranslation("waiting_room");

  if (!dialInNumber || !dialInCode) {
    return null;
  }

  return (
    <div className={styles.DialIn}>
      <Modal
        contentLabel={t("phone_dial_in.title") ?? undefined}
        isOpen={isOpen}
        onRequestClose={onClose}
        shouldCloseOnOverlayClick={false}
      >
        <ModalHeader>{t("phone_dial_in.title")}</ModalHeader>
        <ModalBody>
          <div className={styles.Body}>
            <p className={styles.IntroText}>{t("phone_dial_in.phone_number_intro")}</p>
            <p>
              {t("phone_dial_in.phone_number_instruction")}:
              <a className={styles.PhoneNumber} href={`tel:${dialInNumber.replace(/\s/g, "")}`}>
                {dialInNumber}
              </a>
            </p>
            <p>
              {t("phone_dial_in.code_instruction")}:
              <strong className={styles.Code}>{dialInCode}#</strong>
            </p>
            {dialInPin !== undefined && (
              <p>
                {t("phone_dial_in.pin_instruction")}:
                <strong className={styles.Code}>{dialInPin}#</strong>
              </p>
            )}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
