import React from "react";
import { useTranslation } from "react-i18next";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import clsx from "clsx";

import { IconWithNotification } from "../../components/Icon/IconWithNotification";
import { NavItemKey } from "../../types/SideNavigation";

import styles from "./SideNavigation.module.scss";

interface Props {
  icon: IconName;
  isActive: boolean | false;
  notificationCount?: number | null;
  onChange: () => void;
  type: NavItemKey;
}

export function SideNavigationItem(props: Props) {
  const { icon, isActive, notificationCount, onChange, type } = props;
  const { t } = useTranslation("tabs");

  return (
    <button className={clsx(styles.NavItem, { [styles.active]: isActive })} onClick={onChange}>
      <IconWithNotification icon={icon} isNotified={Boolean(notificationCount)} size="lg" />
      <div className={styles.NavText}>{t(type)}</div>
    </button>
  );
}
