import React from "react";
import { IconName } from "@fortawesome/fontawesome-svg-core";

import { Icon } from "./Icon";

import styles from "./Icon.module.scss";

export interface Props {
  icon: IconName;
  isNotified: boolean;
  size?:
    | "xs"
    | "lg"
    | "sm"
    | "1x"
    | "2x"
    | "3x"
    | "4x"
    | "5x"
    | "6x"
    | "7x"
    | "8x"
    | "9x"
    | "10x"
    | undefined;
}

export function IconWithNotification(props: Props) {
  const { icon, isNotified, size } = props;

  return (
    <div className={styles.IconWithNotification}>
      <div className={styles.IconWrapper}>
        {isNotified && <div className={styles.Badge} />}
        <Icon icon={icon} size={size} />
      </div>
    </div>
  );
}
