import React from "react";
import { useTranslation } from "react-i18next";

import { Feed } from "../../../../components/Feed/Feed";
import { Messages_messages } from "../../../../types/api";

interface Props {
  messages: Messages_messages[];
}

export function ChatFeed(props: Props) {
  const { t } = useTranslation("chat");
  const { messages } = props;

  return (
    <Feed
      emptyState={{
        title: t("empty_state_title"),
        text: t("empty_state_text"),
        icon: "comments",
      }}
      items={messages}
    />
  );
}
