export enum ConnectionQuality {
  DISCONNECTED = 0,
  TERRIBLE = 1,
  WEAK = 2,
  OK = 3,
  GREAT = 4,
  EXCELLENT = 5,
}

export enum ConnectionQualityColors {
  BAD = "error",
  WEAK = "warning",
  GOOD = "success",
}
