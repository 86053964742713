import { useCallback, useEffect, useState } from "react";
import clsx from "clsx";

import { Controls as ControlsType, ControlTypes } from "../../types/Control";

import { Control } from "./Control";

import styles from "./Controls.module.scss";

interface Props {
  controls: ControlsType;
  shouldHide?: boolean;
  size: "large" | "small";
}

export function Controls(props: Props) {
  const [isHidden, setIsHidden] = useState(false);
  const { controls, shouldHide, size } = props;

  function toggle(type: ControlTypes) {
    const control = controls[type]!;

    control.onToggle(!control.value);
  }

  const showControls = useCallback(() => {
    setIsHidden(false);
    document.removeEventListener("mousemove", showControls);
    document.removeEventListener("touchmove", showControls);
  }, []);

  const hideControls = useCallback(() => {
    setIsHidden(true);
    document.addEventListener("mousemove", showControls);
    document.addEventListener("touchmove", showControls);
  }, [showControls]);

  useEffect(() => {
    if (shouldHide) {
      const timer = setTimeout(() => {
        if (!isHidden) {
          return hideControls();
        }
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [hideControls, isHidden, shouldHide]);

  return (
    <div
      className={clsx(styles.Controls, { [styles.hidden]: isHidden })}
      data-ignore-outside-click="true"
    >
      {Object.entries(controls).map(([type, control]) => (
        <Control
          {...control}
          key={type}
          onChange={() => toggle(type as ControlTypes)}
          size={size}
          type={type as ControlTypes}
        />
      ))}
    </div>
  );
}
