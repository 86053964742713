import React, { ReactElement } from "react";

import styles from "./Modal.module.scss";

interface Props {
  children: ReactElement | ReactElement[];
}

export function ModalFooter(props: Props) {
  const { children } = props;

  return <div className={styles.ModalFooter}>{children}</div>;
}
