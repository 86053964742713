import { gql } from "@apollo/client";

export const CREATE_FILES = gql`
  mutation createFiles($input: [FileInput!]!) {
    createFiles(input: $input) {
      id
      createdAt
      mimeType
      name
      state
      thumbnail
      url
      participant {
        id
        fullName
      }
    }
  }
`;

export const DELETE_FILES = gql`
  mutation deleteFiles($ids: [String!]!) {
    deleteFiles(ids: $ids) {
      success
    }
  }
`;

export const UPDATE_FILE = gql`
  mutation updateFile($input: UpdateFileInput!) {
    updateFile(input: $input) {
      id
      createdAt
      mimeType
      name
      state
      thumbnail
      url
      participant {
        id
        fullName
      }
    }
  }
`;
