import React from "react";

import { Controls } from "../../components/Controls/Controls";

import { useControls } from "./useControls";

export function MainControls() {
  const controls = useControls(true);

  return <Controls controls={controls} size="large" shouldHide />;
}
