import { API_HOST, enterprise } from "@app/utils/enterprise";
import { BlobServiceClient, BlobUploadCommonResponse, ContainerClient } from "@azure/storage-blob";
import path from "path-browserify";

import { FileObject } from "../types/File";

export interface UploadedFileResponse {
  id: string;
  mimeType: string;
  name: string;
}

export default class BlobStorageClient {
  private readonly SASString: string;
  private readonly appointmentId: string;

  constructor(SASString: string, appointmentId: string) {
    this.SASString = SASString;
    this.appointmentId = appointmentId;
    this.uploadFiles = this.uploadFiles.bind(this);
  }

  /**
   * Uploads passed files to azure
   *
   * @param files
   * @param appointmentId
   * @returns Promise with UploadedFileResponse
   */
  uploadFiles(
    files: FileObject[],
    appointmentId: string,
  ): Promise<BlobUploadCommonResponse & UploadedFileResponse>[] {
    const containerClient = this.getContainerClient();

    return files.map(async ({ file, id, name }) => {
      const blockBlobClient = containerClient.getBlockBlobClient(`${this.getBasePath()}${name}`);

      const response = await blockBlobClient.uploadBrowserData(file, {
        metadata: {
          appointment_id: appointmentId,
          file_id: id,
          file_name: name,
          enterprise,
          video_api: `${API_HOST}/video`,
        },
      });

      return { ...response, id, name, mimeType: file.type };
    });
  }

  /**
   * Get basePath based on appointment id
   *
   * @returns string
   */
  private getBasePath(): string {
    return `${enterprise}/appointment/${this.appointmentId}/files/`;
  }

  /**
   * Instantiate BlobServiceClient with SASString
   *
   * @returns BlobServiceClient
   */
  private getClient(): BlobServiceClient {
    return new BlobServiceClient(this.SASString);
  }

  /**
   * Get ContainerClient from BlobServiceClient
   *
   * @returns ContainerClient
   */
  private getContainerClient(): ContainerClient {
    return this.getClient().getContainerClient("uploads");
  }

  /**
   * Auto-increment file name to ensure uniqueness
   *
   * @param name
   * @param offset
   * @returns string
   */
  async getEnumeratedBlockBlobName(name: string, offset?: number): Promise<string> {
    const containerClient = this.getContainerClient();
    const basePath = this.getBasePath();
    let blockBlobClient = containerClient.getBlockBlobClient(`${basePath}${name}`);
    let increment = offset ?? 1;

    const ext = path.extname(name);
    const baseName = path.basename(name, ext);

    while (true) {
      if (!(await blockBlobClient.exists())) {
        break;
      }
      name = `${baseName} (${increment})${ext}`;
      blockBlobClient = containerClient.getBlockBlobClient(`${basePath}${name}`);
      increment++;
    }

    return name;
  }
}
