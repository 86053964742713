import React from "react";
import { useDecodedToken } from "@app/hooks/useToken";

import { Participant } from "../../../../components/Participant/Participant";
import { Appointment_appointment_participants, Role } from "../../../../types/api";
import { ParticipantActions } from "../ParticipantsActions/ParticipantActions";

import styles from "./ParticipantRow.module.scss";

interface Props {
  participant: Appointment_appointment_participants;
  role?: Role;
}

export function ParticipantRow(props: Props) {
  const { participant, role } = props;
  const token = useDecodedToken();

  const isContactInfoVisible =
    role === Role.CONTACT ||
    participant.id === token?.participantId ||
    participant.role === Role.CONTACT;

  return (
    <div className={styles.ParticipantRow}>
      <Participant participant={participant} withContactInfo={isContactInfoVisible} />

      <div className={styles.Actions}>
        <ParticipantActions {...props} />
      </div>
    </div>
  );
}
