import React, { useEffect, useRef } from "react";
import { useReactiveVar } from "@apollo/client";
import { ConnectionQualityIndicator } from "@app/containers/ConnectionQualityIndicator/ConnectionQualityIndicator";
import { MainControls } from "@app/containers/Controls/MainControls";
import { isVolumeMutedVar } from "@app/graphql/cache";
import { usePictureInPicture } from "@app/hooks/usePictureInPicture";
import { useIsRecording } from "@app/hooks/useRecordings";
import clsx from "clsx";

import { RecordingIndicator } from "../RecordingIndicator/RecordingIndicator";

import styles from "./Video.module.scss";

interface Props {
  isTracking?: boolean;
  src?: MediaStream | HTMLMediaElement;
  onClick?: () => void;
}

export function MainVideo(props: Props) {
  const { onClick, src } = props;
  const videoRef = useRef<HTMLVideoElement>(null);
  const isVolumeMuted = useReactiveVar(isVolumeMutedVar);
  const isRecording = useIsRecording();

  usePictureInPicture(videoRef);

  useEffect(() => {
    if (videoRef.current === null || src === undefined) {
      return;
    }

    if (src instanceof MediaStream) {
      videoRef.current.srcObject = src;
    }

    // Support for: safari_ver >= 11 || chrome_ver > 65 || firefox_ver > 59 (see pexrtc)
    if (src instanceof HTMLMediaElement) {
      videoRef.current.srcObject = src.srcObject;
    }
  }, [src, videoRef]);

  return (
    <div className={clsx(styles.Video, styles.large)}>
      <div className={styles.controls}>
        <div className={styles.left}>
          <ConnectionQualityIndicator />
          {isRecording && <RecordingIndicator />}
        </div>
        <MainControls />
      </div>
      <video
        muted={isVolumeMuted}
        onClick={onClick}
        ref={videoRef}
        autoPlay
        draggable
        playsInline
      />
    </div>
  );
}
