import React from "react";
import { useTranslation } from "react-i18next";

import SkedifyNotFound from "../../assets/skedify-404.svg?react";

import styles from "./NotFound.module.css";

export function NotFound() {
  const { t } = useTranslation("errors");

  return (
    <div className={styles.NotFound}>
      <SkedifyNotFound />
      <p>{t("not_found")}</p>
    </div>
  );
}
