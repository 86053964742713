import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Select, { OnChangeValue } from "react-select";
import { BANDWIDTHS } from "@app/hooks/media/constants";
import { getStreamQuality } from "@app/hooks/media/media";
import { IconSource } from "@pexip/components";
import { MediaDeviceInfoLike } from "@pexip/media-control";

import { TestBar } from "../../components/TestBar/TestBar";
import { ErrorMessage } from "../../components/Typography/Typography";

import styles from "./Settings.module.scss";

interface SharedProps {
  error?: string | null;
  hideIcon?: boolean;
  icon?: IconSource;
  label?: string | null;
  stream?: MediaStream | null;
  value: string | null;
}

interface DeviceSettingsProps extends SharedProps {
  type: "video" | "audio";
  onChange: (device: MediaDeviceInfoLike) => void;
  options: MediaDeviceInfoLike[];
}

interface BandwidthSettingsProps extends SharedProps {
  onChange: (value: string) => void;
}

type DeviceOptionType = { label: string; value: MediaDeviceInfoLike };
type OptionType = { label: string; value: string };

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    fontSize: ".875rem",
  }),
  menu: (provided: any) => ({
    ...provided,
    fontSize: ".875rem",
    zIndex: 20,
  }),
};

export function BandwidthSettingsGroup({ label, onChange, value }: BandwidthSettingsProps) {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (option: OnChangeValue<OptionType, boolean>) => {
      if (!option || "length" in option) {
        return;
      }

      return onChange(option.value);
    },
    [onChange],
  );

  const mapBandwidthOption = useCallback(
    (option: string) => ({
      label: t(`settings:bandwidths.${getStreamQuality(option)}`),
      value: option,
    }),
    [t],
  );

  return (
    <div className={styles.group}>
      <div className={styles.rightSide}>
        <label>{t("settings:stream_quality")}</label>
        <p className="hidden md:block">{t("settings:stream_quality_info")}</p>
        <Select
          components={{ IndicatorSeparator: null }}
          isSearchable={false}
          onChange={handleChange}
          options={["auto", ...BANDWIDTHS].map(mapBandwidthOption)}
          styles={customStyles}
          value={mapBandwidthOption(value)}
        />
      </div>
    </div>
  );
}

function mapDeviceOption(option: MediaDeviceInfoLike) {
  return { label: option?.label, value: option };
}

export function DeviceSettingsGroup({
  error,
  label,
  onChange,
  options,
  type,
  value,
}: DeviceSettingsProps) {
  const selectedValue = mapDeviceOption(
    options.find((option) => option.deviceId === value) || options[0],
  );

  const handleChange = useCallback(
    (option: OnChangeValue<DeviceOptionType, boolean>) => {
      if (!option || "length" in option) {
        return;
      }

      return onChange(option.value);
    },
    [onChange],
  );

  return (
    <div className={styles.group}>
      <div className={styles.rightSide}>
        {label && <label>{label}</label>}
        <Select
          components={{ IndicatorSeparator: null }}
          isSearchable={false}
          onChange={handleChange}
          options={options.map(mapDeviceOption)}
          styles={customStyles}
          value={selectedValue}
        />
        {error ? (
          <ErrorMessage>{error}</ErrorMessage>
        ) : (
          type === "audio" && (
            <div className={styles.Testbar}>
              <TestBar />
            </div>
          )
        )}
      </div>
    </div>
  );
}
