export function isBrowserCompatible() {
  const isChromeIOS = /(?=iPhone|iPod|iPad).*(?=.*GSA|CriOS)/i.test(navigator.userAgent);

  const hasWebRTC =
    // @ts-ignore
    navigator.getUserMedia ||
    // @ts-ignore
    navigator.webkitGetUserMedia ||
    // @ts-ignore
    navigator.mozGetUserMedia ||
    // @ts-ignore
    navigator.msGetUserMedia ||
    // @ts-ignore
    navigator?.mediaDevices?.getUserMedia;

  // @ts-ignore
  return hasWebRTC && !isChromeIOS && window.EventSource !== undefined;
}
