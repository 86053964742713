import React, { ReactElement } from "react";

import { Recordings_recordings as IRecording, RecordingType } from "../../types/api";
import { formatDuration } from "../../utils/formatDuration";
import { formatSize } from "../../utils/formatSize";
import { Icon } from "../Icon/Icon";

import styles from "./Recording.module.scss";

interface Props {
  recording: IRecording;
}

const RECORDING_ICON_MAP: Record<RecordingType, ReactElement> = {
  COMBINED: <Icon icon="video" />,
  PRESENTATION: <Icon icon="desktop" />,
  USER: <Icon icon="video" />,
};

export function Recording(props: Props) {
  const { recording } = props;

  const icon = RECORDING_ICON_MAP[recording.type];
  const duration = formatDuration(recording.duration);
  const size = formatSize(recording.size);

  return (
    <a
      className={styles.Recording}
      href={recording.url ?? undefined}
      rel="noopener noreferrer"
      target="_blank"
    >
      <div className={styles.Icon}>{icon}</div>
      <div>
        <h4>{recording.name}</h4>
        <small>
          {duration != null && <span>{duration}</span>}
          {size != null && <span>{size}</span>}
        </small>
      </div>
    </a>
  );
}
