import { gql } from "@apollo/client";

export const MESSAGES = gql`
  query Messages {
    messages {
      id
      body
      createdAt
      sender {
        id
        avatar
        email
        lastName
        firstName
        fullName
        role
        title
      }
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation SendMessage($input: SendMessageInput!) {
    sendMessage(input: $input) {
      id
      body
      createdAt
      sender {
        id
        avatar
        email
        lastName
        firstName
        fullName
        role
        title
      }
    }
  }
`;

export const NEW_MESSAGE = gql`
  subscription NewMessage($appointmentId: String!, $userId: String!) {
    newMessage(appointmentId: $appointmentId, userId: $userId) {
      id
      body
      createdAt
      sender {
        id
        avatar
        email
        lastName
        firstName
        fullName
        role
        title
      }
    }
  }
`;
