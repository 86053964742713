import { gql } from "@apollo/client";

export const MODULE_SETTINGS = gql`
  query getModuleSettings($moduleName: ModuleName!) {
    moduleSettings(moduleName: $moduleName) {
      key
      type
      userControllable
      value
    }
  }
`;
