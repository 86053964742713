import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { EmptyState as Placeholder } from "../../components/EmptyState/EmptyState";
import { NavContext } from "../../containers/SideNavigation/useNavContext";
import { useDropzone } from "../../hooks/useDropzone";
import { useFiles } from "../../hooks/useFiles";
import { useModules } from "../../hooks/useModules";
import { useDecodedToken } from "../../hooks/useToken";
import { Appointment_appointment as Appointment, ModuleName } from "../../types/api";
import { NavItemKey } from "../../types/SideNavigation";
import { Chat } from "../Chat/Chat";
import { Files } from "../Files/Files";
import { FilesErrorBoundary } from "../Files/FilesErrorBoundary";
import { Participants } from "../Participants/Participants";
import { Recordings } from "../Recordings/Recordings";
import { Settings } from "../Settings/Settings";
import { ActionTypes } from "../SideNavigation/navReducer";

import styles from "./SidePanelContent.module.scss";

interface Props {
  appointment: Appointment;
  callbackFn?: (payload: any) => void;
}

export function SidePanelContent({ appointment }: Props) {
  const isFilesAllowed = useModules(ModuleName.FILES);
  const isRecordingsAllowed = useModules(ModuleName.RECORDINGS);
  const { isHost } = useDecodedToken() ?? {};

  const { uploadFiles } = useFiles();
  const { t } = useTranslation("files");
  const {
    getInputProps,
    getRootProps,
    isDragActive,
    open: openDropzone,
  } = useDropzone({
    disabled: !isFilesAllowed,
    onDrop: uploadFiles,
  });

  const {
    dispatch,
    state: { active_tab, navItems },
  } = useContext(NavContext);

  const onNewNotification = useCallback(
    (tab: NavItemKey) => {
      if (tab !== active_tab) {
        return dispatch({
          type: ActionTypes.INCREMENT_NOTIFICATION_COUNTER,
          tab,
        });
      }
    },
    [active_tab, dispatch],
  );

  const tabs = useMemo(
    () => [
      {
        label: NavItemKey.CHAT,
        notification: navItems[NavItemKey.CHAT]?.notificationCount,
        content: (
          <Chat
            appointment={appointment}
            onNewNotification={() => onNewNotification(NavItemKey.CHAT)}
          />
        ),
        unmountOnClose: false,
      },
      ...(isFilesAllowed
        ? [
            {
              label: NavItemKey.FILES,
              notification: navItems[NavItemKey.FILES]?.notificationCount,
              content: (
                <FilesErrorBoundary>
                  <Files
                    onNewNotification={() => onNewNotification(NavItemKey.FILES)}
                    openDropzone={openDropzone}
                  />
                </FilesErrorBoundary>
              ),
              unmountOnClose: false,
            },
          ]
        : []),
      ...(isRecordingsAllowed && isHost
        ? [
            {
              label: NavItemKey.RECORDINGS,
              content: <Recordings />,
              unmountOnClose: false,
            },
          ]
        : []),
      {
        label: NavItemKey.PARTICIPANTS,
        content: <Participants appointment={appointment} />,
        unmountOnClose: true,
      },
      {
        label: NavItemKey.SETTINGS,
        content: <Settings />,
        unmountOnClose: true,
      },
    ],
    [
      appointment,
      isFilesAllowed,
      isHost,
      isRecordingsAllowed,
      navItems,
      onNewNotification,
      openDropzone,
    ],
  );

  return (
    <div {...getRootProps()} className={styles.SidePanel}>
      <input {...getInputProps()} />
      {isDragActive && (
        <div className={styles.Dropzone}>
          <Placeholder
            icon="file"
            text={t("dropzone.empty_state_text")}
            title={t("dropzone.empty_state_title")}
          />
        </div>
      )}
      <div className={clsx(styles.children, { [styles.hidden]: isDragActive })}>
        <div className={styles.Wrapper}>
          {tabs.map(({ content, label, unmountOnClose }) => {
            const isActive = active_tab === label;

            return (
              <div
                className={clsx(styles.TabContainer, {
                  [styles.active]: isActive,
                })}
                key={label}
              >
                {unmountOnClose && !isActive ? null : content}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
