import React from "react";
import { useTranslation } from "react-i18next";

import { Avatar } from "../../../../components/Avatar/Avatar";
import {
  Appointment_appointment_participants as Participant,
  Appointment_appointment_subject as Subject,
} from "../../../../types/api";

import styles from "./Contact.module.scss";

interface Props {
  contact: Participant;
  subject: Subject;
}

export function Contact(props: Props) {
  const { contact, subject } = props;
  const { t } = useTranslation("waiting_room");

  return (
    <div className={styles.Contact}>
      <div className={styles.left}>
        <Avatar participant={contact} size="large" />
      </div>
      <div>
        <h3>
          <strong>{contact.fullName}</strong>
        </h3>
        <p className="small">
          {t("about")} <strong>{subject.title}</strong>
          <br />
          {subject.description}
        </p>
      </div>
    </div>
  );
}
