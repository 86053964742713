import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import clsx from "clsx";

import { Icon } from "../../../Icon/Icon";

import styles from "./ToastBody.module.scss";

interface Props {
  icon: IconProp;
  message: string;
  title: string;
  variant?: "error" | "info" | "success";
}

export function ToastBody(props: Props) {
  const { icon, message, title, variant = "info" } = props;

  return (
    <div className={styles.ToastBody}>
      <div className={clsx(styles.icon, styles[variant])}>
        <Icon icon={icon} fixedWidth />
      </div>
      <div className={styles.body}>
        <div className={styles.title}>{title}</div>
        <div className={styles.message}>{message}</div>
      </div>
    </div>
  );
}
