import React from "react";

import styles from "./Logo.module.scss";

interface Props {
  src?: string;
}

export function Logo(props: Props) {
  const { src } = props;

  if (!src) {
    return null;
  }

  return <img alt="Logo" className={styles.Logo} src={src} />;
}
