import { gql } from "@apollo/client";

export const START_RECORDINGS = gql`
  mutation StartRecordings {
    startRecordings {
      id
      state
    }
  }
`;

export const STOP_RECORDINGS = gql`
  mutation StopRecordings {
    stopRecordings {
      id
      state
    }
  }
`;
