import React, { createContext, useReducer } from "react";
import { useDecodedToken } from "@app/hooks/useToken";

import { ModuleName, Role } from "../../types/api";

import { getInitialState, reducer, StateType } from "./navReducer";

const NavContext = createContext<{
  state: StateType;
  dispatch: React.Dispatch<any>;
}>({
  state: getInitialState([]),
  dispatch: () => null,
});

interface Props {
  children: React.ReactNode;
}

const NavProvider = ({ children }: Props) => {
  const { allowedModules = [], role } = useDecodedToken() ?? {};
  const modules = allowedModules.filter((module) => {
    if (module === ModuleName.RECORDINGS) {
      return role === Role.CONTACT;
    }

    return true;
  });

  const [state, dispatch] = useReducer(reducer, getInitialState(modules));

  return <NavContext.Provider value={{ state, dispatch }}>{children}</NavContext.Provider>;
};

export { NavContext, NavProvider };
