import { gql } from "@apollo/client";

export const RECORDINGS = gql`
  query Recordings {
    recordings {
      id
      duration
      name
      size
      state
      type
      url
      createdAt
      updatedAt
    }
  }
`;
