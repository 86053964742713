import React from "react";

import { Button, Variants } from "../../../../components/Button/Button";
import { Icon } from "../../../../components/Icon/Icon";
import { Appointment_appointment as Appointment } from "../../../../types/api";

import { DialInModal } from "./DialInModal";

import styles from "./DialIn.module.scss";

export function DialIn(
  props: Pick<Appointment, "dialInNumber"> & {
    dialInCode?: string;
    dialInPin?: string;
  },
) {
  const [isDialInOpen, setIsDialInOpen] = React.useState(false);
  const { dialInCode, dialInNumber } = props;

  if (!dialInNumber || !dialInCode) {
    return null;
  }

  return (
    <div className={styles.DialIn}>
      <Button onClick={() => setIsDialInOpen(true)} variant={Variants.INVERTED}>
        <Icon icon="phone-alt" />
      </Button>
      <DialInModal {...props} isOpen={isDialInOpen} onClose={() => setIsDialInOpen(false)} />
    </div>
  );
}
