import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Textarea from "react-textarea-autosize";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import clsx from "clsx";
import { format, parseISO } from "date-fns";

import { Systems, useOperatingSystem } from "../../hooks/useOperatingSystem";
import { Messages_messages_sender } from "../../types/api";
import { Icon } from "../Icon/Icon";
import { Participant } from "../Participant/Participant";

import styles from "./Feed.module.scss";

export interface IItem {
  id: string;
  body: string;
  createdAt: string;
  sender?: Messages_messages_sender;
  isSaving?: boolean | null;
}

enum SaveState {
  INITIAL,
  SAVING,
  SAVED,
}

interface Props {
  iconLeft?: IconName;
  item: IItem;
  onSave?: (item: IItem) => void;
}

export function Item(props: Props) {
  const { iconLeft, item, onSave } = props;
  const { t } = useTranslation();
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState(item.body);
  const [saveState, setSaveState] = useState<SaveState>(SaveState.INITIAL);
  const os = useOperatingSystem();

  function handleEdit() {
    if (onSave !== undefined) {
      setIsEdit(true);
    }
  }

  function handleSave() {
    setIsEdit(false);

    if (onSave !== undefined && value !== item.body) {
      onSave({ ...item, body: value });
    }
  }

  function handleChange(evt: React.ChangeEvent<HTMLTextAreaElement>) {
    setValue(evt.target.value);
  }

  const handleKeyPress = (evt: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (evt.keyCode === 13 && evt.shiftKey) {
      evt.preventDefault();

      return setValue(`${value}\n`);
    }

    if (evt.keyCode === 13) {
      evt.preventDefault();
      handleSave();
    }
  };

  useEffect(() => {
    if (inputRef.current != null && isEdit) {
      inputRef.current.setSelectionRange(value.length, value.length);
    }

    if (inputRef.current != null && !isEdit) {
      inputRef.current.blur();
    }
  }, [inputRef, isEdit]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (item.isSaving) {
      setSaveState(SaveState.SAVING);
    }

    if (!item.isSaving && saveState === SaveState.SAVING) {
      const timeout = setTimeout(() => {
        setSaveState(SaveState.SAVED);

        setTimeout(() => {
          setSaveState(SaveState.INITIAL);
        }, 1500);
      }, 300);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [item.isSaving]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={clsx(styles.Item, {
        [styles.compact]: item.sender === undefined,
      })}
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>
          {iconLeft !== undefined && <Icon icon={iconLeft} />}
          {item.sender !== undefined && <Participant participant={item.sender} size="small" />}
        </div>
        <div
          className={clsx(styles.body, {
            [styles.editable]: onSave !== undefined,
          })}
          onClick={handleEdit}
        >
          <Textarea
            autoFocus={isEdit}
            disabled={onSave === undefined}
            minRows={1}
            onBlur={handleSave}
            onChange={handleChange}
            onKeyDown={handleKeyPress}
            placeholder={t("note_placeholder") ?? undefined}
            ref={inputRef}
            style={os !== Systems.WEB ? { fontSize: 16 } : {}}
            value={value}
          />
          {saveState === SaveState.SAVING && <Icon icon="spinner" spin />}
          {saveState === SaveState.SAVED && <Icon icon="check-circle" />}
        </div>
      </div>
      <div className={styles.time}>{format(parseISO(item.createdAt), "H:mm")}</div>
    </div>
  );
}
