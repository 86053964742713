export enum Browsers {
  CHROME = "Chrome",
  EDGE = "Edge",
  FIREFOX = "Firefox",
  OPERA = "Opera",
  SAFARI = "Safari",
  SAMSUNG = "Samsung",
  UNKNOWN = "Unknown",
}

export function useBrowser() {
  const ua = navigator.userAgent;
  if ((ua.indexOf("Opera") || ua.indexOf("OPR")) !== -1) {
    return Browsers.OPERA;
  } else if (ua.indexOf("Edge") !== -1) {
    return Browsers.EDGE;
  } else if (ua.indexOf("Chrome") !== -1) {
    return Browsers.CHROME;
  } else if (ua.indexOf("Safari") !== -1) {
    return Browsers.SAFARI;
  } else if (ua.indexOf("Firefox") !== -1) {
    return Browsers.FIREFOX;
  } else if (ua.indexOf("SamsungBrowser") !== -1) {
    return Browsers.SAMSUNG;
  } else {
    return Browsers.UNKNOWN;
  }
}
