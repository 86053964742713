import { useCallback, useEffect } from "react";
import { useReactiveVar } from "@apollo/client";
import { audioInputDeviceIdVar, videoInputDeviceIdVar } from "@app/graphql/cache";
import { UserMediaStatus } from "@pexip/media";
import isMobileDevice from "ismobilejs";

import { useAppointment } from "../useAppointment";

import { mediaController } from "./media";

export function useGUM(skipInitialGUM?: boolean) {
  const videoInputDeviceId = useReactiveVar(videoInputDeviceIdVar);
  const audioInputDeviceId = useReactiveVar(audioInputDeviceIdVar);

  const { subject } = useAppointment();

  const getUserMedia = useCallback(() => {
    const isMobile = isMobileDevice(navigator.userAgent).any;

    mediaController.getUserMedia({
      audio: {
        ...(audioInputDeviceId ? { deviceId: { ideal: audioInputDeviceId } } : {}),
      },
      video: {
        bgImageUrl: subject.branding.backgroundReplacementImage,
        facingMode: isMobile ? "user" : "environment",
        // TODO: calculate based on quality
        // See https://gitlab.com/pexip/zoo/-/blob/main/src/aquila/packages/media-components/src/utils/qualityToMediaConstraints.ts
        ...(isMobile ? {} : { height: { ideal: 720, max: 1080 } }),
        ...(isMobile ? {} : { width: { ideal: 1280, max: 1920 } }),
        ...(videoInputDeviceId && !isMobile ? { deviceId: { ideal: videoInputDeviceId } } : {}),
      },
    });
  }, [videoInputDeviceId, audioInputDeviceId, subject]);

  useEffect(() => {
    if (!skipInitialGUM && mediaController.media.status === UserMediaStatus.Initial) {
      getUserMedia();
    }
  }, [getUserMedia, skipInitialGUM]);

  return getUserMedia;
}
