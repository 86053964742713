import { Navigate } from "react-router-dom";

import { Loader } from "./components/Loader/Loader";
import { NavProvider } from "./containers/SideNavigation/useNavContext";
import { useAppointment } from "./hooks/useAppointment";
import { AppStates, Match } from "./hooks/useAppStateMachine";
import { useHotjar } from "./hooks/useHotjar";
// import { useHeartbeat } from "./hooks/useHeartbeat";
import { useZendesk } from "./hooks/useZendesk";
import { Declined } from "./pages/Declined/Declined";
import { EndScreen } from "./pages/EndScreen/EndScreen";
import { MeetingRoom } from "./pages/MeetingRoom/MeetingRoom";
import { Setup } from "./pages/Setup/Setup";
import { WaitingRoom } from "./pages/WaitingRoom/WaitingRoom";
import {
  AcceptedAppointment,
  CompletedAppointment,
  UnacceptedAppointment,
} from "./types/Appointment";
import { Branding } from "./branding";

import "./App.scss";

export function App() {
  const appointment = useAppointment();

  useZendesk();
  useHotjar();

  // This is temporarily disabled until B/E is fixed
  // useHeartbeat();

  return (
    <>
      <Branding />
      <Match state={AppStates.LOADING}>
        <Loader />
      </Match>
      <Match state={AppStates.ERROR}>
        <Navigate to="/" replace />
      </Match>
      {appointment !== undefined && (
        <>
          <Match state={AppStates.PENDING}>
            <Declined appointment={appointment as UnacceptedAppointment} />
          </Match>
          <Match state={AppStates.CANCELLED}>
            <Declined appointment={appointment as UnacceptedAppointment} />
          </Match>
          <NavProvider>
            <Match state={AppStates.WAITING_ROOM}>
              <WaitingRoom appointment={appointment as AcceptedAppointment} />
            </Match>
            <Match state={AppStates.MEETING_ROOM}>
              <MeetingRoom appointment={appointment as AcceptedAppointment} />
            </Match>
          </NavProvider>
          <Match state={AppStates.END_SCREEN}>
            <EndScreen appointment={appointment as CompletedAppointment} />
          </Match>
          <Match state={AppStates.SETUP}>
            <Setup appointment={appointment} />
          </Match>
        </>
      )}
    </>
  );
}
