import { gql } from "@apollo/client";

export const TIMELINE_EVENTS = gql`
  query TimelineEvents($recordingId: String) {
    timelineEvents(recordingId: $recordingId) {
      id
      body
      createdAt
      type
      isSaving @client
    }
  }
`;

export const CREATE_TIMELINE_EVENT = gql`
  mutation CreateTimelineEvent($input: CreateTimelineEventInput!) {
    createTimelineEvent(input: $input) {
      id
      body
      type
      createdAt
      isSaving @client
    }
  }
`;

export const UPDATE_TIMELINE_EVENT = gql`
  mutation UpdateTimelineEvent($input: UpdateTimelineEventInput!) {
    updateTimelineEvent(input: $input) {
      id
      body
      type
      createdAt
      isSaving @client
    }
  }
`;

export const IS_RECORDING = gql`
  query IsRecording {
    isRecording
  }
`;

export const IS_RECORDING_UPDATED = gql`
  subscription IsRecordingSubscription($enterprise: String!, $appointmentId: String!) {
    isRecordingSubscription(enterprise: $enterprise, appointmentId: $appointmentId)
  }
`;
