import React, { ReactNode } from "react";

import styles from "./Modal.module.scss";

interface Props {
  children: ReactNode;
}

export function ModalHeader(props: Props) {
  const { children } = props;

  return <h2 className={styles.ModalTitle}>{children}</h2>;
}
