import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { useModules } from "@app/hooks/useModules";
import { formatISO } from "date-fns";

import { Button, Variants } from "../../components/Button/Button";
import { IItem } from "../../components/Feed/Item";
import { Icon } from "../../components/Icon/Icon";
import { useIsRecording, useRecordings } from "../../hooks/useRecordings";
import { ModuleName, RecordingState, RecordingType, TimelineEvents } from "../../types/api";
import { upsertObject } from "../../utils/arrayHelpers";

import { CREATE_TIMELINE_EVENT, TIMELINE_EVENTS, UPDATE_TIMELINE_EVENT } from "./graphql";
import { RecordingDuration } from "./RecordingDuration";
import { RecordingsFeed } from "./RecordingsFeed";

import styles from "./Recordings.module.scss";

export function Recordings() {
  const { t } = useTranslation("recordings");
  const { isLoading, recordings, startRecordings, stopRecordings } = useRecordings();
  const isRecording = useIsRecording();
  const { moduleSettings: recordingSettings } = useModules(ModuleName.RECORDINGS);

  const currentRecording = recordings.find(
    (recording) =>
      recording.type !== RecordingType.PRESENTATION && recording.state === RecordingState.RECORDING,
  );
  const [createTimelineEvent] = useMutation(CREATE_TIMELINE_EVENT, {
    onError: console.error,
  });
  const [updateTimelineEvent] = useMutation(UPDATE_TIMELINE_EVENT, {
    onError: console.error,
  });
  const { data } = useQuery<TimelineEvents>(TIMELINE_EVENTS);
  const timelineEvents = data?.timelineEvents ?? [];

  function handleCreateAnnotation() {
    const createdAt = new Date();

    return createTimelineEvent({
      variables: {
        input: {
          body: "",
          recordingId: currentRecording?.id,
          type: "ANNOTATION",
        },
      },
      optimisticResponse: {
        __typename: "Mutation",
        createTimelineEvent: {
          __typename: "TimelineEvent",
          id: createdAt.getTime().toString(),
          body: "",
          type: "ANNOTATION",
          createdAt: formatISO(createdAt),
        },
      },
      update: (proxy, { data: { createTimelineEvent } }) => {
        const query = { query: TIMELINE_EVENTS };
        const data: any = proxy.readQuery(query);

        proxy.writeQuery({
          ...query,
          data: {
            timelineEvents: upsertObject(data.timelineEvents, createTimelineEvent, true),
          },
        });
      },
    });
  }

  function handleUpdateAnnotation(item: IItem) {
    return updateTimelineEvent({
      variables: {
        input: {
          id: item.id,
          body: item.body,
        },
      },
      optimisticResponse: {
        updateTimelineEvent: {
          ...item,
          isSaving: true,
        },
      },
      update: (proxy, { data: { updateTimelineEvent } }) => {
        const query = { query: TIMELINE_EVENTS };
        const data: any = proxy.readQuery(query);

        proxy.writeQuery({
          ...query,
          data: {
            timelineEvents: upsertObject(data.timelineEvents, updateTimelineEvent),
          },
        });
      },
    });
  }

  function handleStartRecordings() {
    return startRecordings();
  }
  function handleStopRecordings() {
    return stopRecordings();
  }

  return (
    <div className={styles.Recordings}>
      <RecordingsFeed
        isRecording={isRecording}
        onSave={handleUpdateAnnotation}
        timelineEvents={timelineEvents}
      />
      <div className={styles.Footer}>
        {currentRecording !== undefined ? (
          <>
            <RecordingDuration start={currentRecording.createdAt} />
            <Button
              onClick={handleStopRecordings}
              variant={
                recordingSettings.RECORDING_BY_DEFAULT_ENABLED
                  ? Variants.DISABLED
                  : Variants.INVERTED
              }
              isFullWidth
            >
              {t("stop")}
            </Button>
            <Button onClick={handleCreateAnnotation}>
              <Icon icon="thumbtack" />
            </Button>
          </>
        ) : (
          <Button
            isLoading={isLoading}
            onClick={handleStartRecordings}
            variant={
              recordingSettings.RECORDING_BY_DEFAULT_ENABLED ? Variants.DISABLED : Variants.DEFAULT
            }
            isFullWidth
          >
            {t("start")}
          </Button>
        )}
      </div>
    </div>
  );
}
