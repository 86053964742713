import React, { ReactElement, useState } from "react";
import clsx from "clsx";

import { Button, Variants } from "../Button/Button";
import { Icon } from "../Icon/Icon";

import styles from "./CollapsibleWrapper.module.scss";

interface Props {
  children?: ReactElement | Array<ReactElement> | boolean;
}

export function CollapsibleWrapper(props: Props) {
  const { children } = props;
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <div
      className={clsx(styles.CollapsibleWrapper, {
        [styles.collapsed]: isCollapsed,
      })}
    >
      <Button onClick={() => setIsCollapsed(!isCollapsed)} variant={Variants.FLAT}>
        <Icon color="white" icon={isCollapsed ? "window-maximize" : "minus-square"} size="lg" />
      </Button>
      {children}
    </div>
  );
}
