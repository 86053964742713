import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";

import { Variants } from "../../components/Button/Button";
import { PhotoContainer } from "../../components/PhotoContainer/PhotoContainer";
import { Recording } from "../../components/Recording/Recording";
import { Separator } from "../../components/Separator/Separator";
import { DownloadButton } from "../../containers/Files/components/DownloadButton/DownloadButton";
import { FILES } from "../../containers/Files/filesGql";
import { Survey } from "../../containers/Survey/Survey";
import { useModules } from "../../hooks/useModules";
import { useRecordings } from "../../hooks/useRecordings";
import { useDecodedToken } from "../../hooks/useToken";
import {
  ModuleName,
  Recordings_recordings as IRecording,
  RecordingState,
  Role,
} from "../../types/api";
import { CompletedAppointment } from "../../types/Appointment";
import { UploadedFile as File } from "../../types/File";

import { HostActions } from "./HostActions";

import styles from "./EndScreen.module.scss";

interface Props {
  appointment: CompletedAppointment;
}

function getRecordingName(recording: IRecording) {
  if (recording.name.endsWith("annotated")) {
    return "recordings:name.annotated";
  }

  return `recordings:name.${recording.type.toLowerCase()}`;
}

export function EndScreen({ appointment }: Props) {
  const { role, surveyCompleted } = useDecodedToken() ?? {};
  const { t } = useTranslation("end_screen");
  const { recordings } = useRecordings();

  const { data: filesQuery } = useQuery<{ files: File[] }>(FILES, {
    skip: !useModules(ModuleName.FILES).isModuleEnabled,
  });

  const files = filesQuery?.files ?? [];
  const hasFiles = files.length > 0;

  const readyRecordings = recordings.filter(
    (recording) => recording.state === RecordingState.READY,
  );

  return (
    <PhotoContainer branding={appointment.subject.branding}>
      <h2>{t("title")}</h2>

      {!surveyCompleted && <Survey />}

      {(role === Role.CONTACT || hasFiles) && (
        <div>
          <Separator />
          {role === Role.CONTACT ? (
            <p>{t("next_actions")}</p>
          ) : (
            hasFiles && <p>{t("download_files_title")}</p>
          )}
          <div className={styles.ActionButtons}>
            {role === Role.CONTACT && <HostActions appointmentId={appointment.externalId} />}
            {hasFiles && (
              <DownloadButton
                variant={role === Role.CONTACT ? Variants.INVERTED : Variants.DEFAULT}
              />
            )}
          </div>
        </div>
      )}

      {readyRecordings.length > 0 && (
        <div className={styles.Recordings}>
          <Separator />
          <h3>{t("recordings")}</h3>
          {readyRecordings.map((recording, idx) => (
            <Recording
              key={recording.id}
              recording={{
                ...recording,
                name: t(getRecordingName(recording)),
              }}
            />
          ))}
        </div>
      )}
    </PhotoContainer>
  );
}
