import { useTranslation } from "react-i18next";
import { useReactiveVar } from "@apollo/client";
import { mediaController, useDevices } from "@app/hooks/media/media";
import {
  useUpdateAudioInputDevice,
  useUpdateBandwidth,
  useUpdateVideoInputDevice,
} from "@app/hooks/media/useDevices";
import { IconTypes } from "@pexip/components";
import { findAudioInputDevices, findVideoInputDevices } from "@pexip/media-control";
import isMobile from "ismobilejs";

import {
  audioInputDeviceIdVar,
  bandwidthVar,
  isMicrophoneMutedVar,
  isVideoMutedVar,
  videoInputDeviceIdVar,
} from "../../graphql/cache";

import { BackgroundsSetting } from "./Backgrounds";
import { BandwidthSettingsGroup, DeviceSettingsGroup } from "./SettingsGroup";

import styles from "./Settings.module.scss";

interface Props {
  hideIcons?: boolean;
}

export function Settings({ hideIcons }: Props) {
  const { t } = useTranslation("settings");
  const isMobileDevice = isMobile(navigator).any;

  const isMicrophoneMuted = useReactiveVar(isMicrophoneMutedVar);
  const isVideoMuted = useReactiveVar(isVideoMutedVar);
  const audioInputDeviceId = useReactiveVar(audioInputDeviceIdVar);
  const videoInputDeviceId = useReactiveVar(videoInputDeviceIdVar);
  const bandwidth = useReactiveVar(bandwidthVar);

  const updateVideoInput = useUpdateVideoInputDevice();
  const updateAudioInput = useUpdateAudioInputDevice();
  const updateBandwidth = useUpdateBandwidth();

  const devices = useDevices();
  const audioInputDevices = findAudioInputDevices(devices);
  const videoInputDevices = findVideoInputDevices(devices);

  return (
    <div className={styles.Settings}>
      <DeviceSettingsGroup
        error={isMicrophoneMuted ? t("troubleShooting.microMuted") : null}
        hideIcon={hideIcons}
        icon={isMicrophoneMuted ? IconTypes.IconMicrophoneOff : IconTypes.IconMicrophoneOn}
        label={t("audio_input")}
        onChange={updateAudioInput}
        options={audioInputDevices}
        type="audio"
        value={audioInputDeviceId ?? mediaController.media.audioInput?.deviceId ?? null}
      />
      <DeviceSettingsGroup
        error={isVideoMuted ? t("troubleShooting.videoMuted") : null}
        hideIcon={hideIcons}
        icon={isVideoMuted ? IconTypes.IconVideoOff : IconTypes.IconVideoOn}
        label={t("video_input")}
        onChange={updateVideoInput}
        options={videoInputDevices}
        type="video"
        value={videoInputDeviceId ?? mediaController.media.videoInput?.deviceId ?? null}
      />
      <BandwidthSettingsGroup hideIcon={hideIcons} onChange={updateBandwidth} value={bandwidth} />
      {!isMobileDevice && <BackgroundsSetting showLabel />}
    </div>
  );
}
