import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { useDecodedToken } from "@app/hooks/useToken";

import { Button } from "../../components/Button/Button";
import { EmptyState } from "../../components/EmptyState/EmptyState";
import { useFiles } from "../../hooks/useFiles";
import { FileEvent, UploadedFile as File } from "../../types/File";
import { removeById, upsertObject } from "../../utils/arrayHelpers";

import { DownloadButton } from "./components/DownloadButton/DownloadButton";
import { FileRow } from "./components/File/File";
import { FILE_EVENT, FILES } from "./filesGql";

import styles from "./Files.module.scss";

interface Props {
  onNewNotification: () => void;
  openDropzone: () => void;
}

export function Files(props: Props) {
  const { onNewNotification, openDropzone } = props;
  const token = useDecodedToken();
  const { t } = useTranslation("files");
  const { deleteFiles } = useFiles();

  const { data, subscribeToMore } = useQuery<{
    files: File[];
  }>(FILES);

  const files = data?.files ?? [];

  useEffect(() => {
    if (token != null && subscribeToMore !== undefined) {
      const unsubscribe = subscribeToMore({
        document: FILE_EVENT,
        variables: {
          appointmentId: token?.appointmentId,
        },
        updateQuery: (prev: any, chunk: any) => {
          const file = chunk.subscriptionData.data.onFileEvent;

          if (!file || file.participant.id === token?.participantId) return prev;

          if (file.event === FileEvent.FILE_DELETED) {
            return {
              files: removeById(prev.files, file),
            };
          }

          if (file.event === FileEvent.FILE_UPLOADED) {
            onNewNotification();
          }

          return {
            files: upsertObject(prev.files, file),
          };
        },
      });

      return () => unsubscribe();
    }
  }, [onNewNotification, subscribeToMore, token]);

  return (
    <div className={styles.Files}>
      <div className={styles.FilesContent}>
        {files.length > 0 ? (
          files.map((file) => <FileRow file={file} key={file.id} onDelete={deleteFiles} />)
        ) : (
          <EmptyState icon="file" text={t("empty_state_text")} title={t("empty_state_title")} />
        )}
      </div>
      <div className={styles.FilesFooter}>
        <Button onClick={openDropzone} isFullWidth>
          {t("upload_button")}
        </Button>
        {files.length > 0 && <DownloadButton isFullWidth />}
      </div>
    </div>
  );
}
