import { useEffect, useRef } from "react";

export function useWindowUnloadEffect(handler: () => void) {
  const cb = useRef(handler);
  cb.current = handler;

  useEffect(() => {
    const handler = cb.current;
    if (handler === null) {
      return;
    }

    window.addEventListener("beforeunload", handler);

    return () => {
      window.removeEventListener("beforeunload", handler);
    };
  }, [cb]);
}
