import { useEffect } from "react";
import { useEnterpriseSettings } from "@app/graphql/hooks/useEnterpriseSettings.hooks";

import { AppStates, useAppStateMachine } from "./useAppStateMachine";
import { useDecodedToken } from "./useToken";

export function useZendesk() {
  const token = useDecodedToken();
  const { color } = useEnterpriseSettings() ?? {};
  const { currentState } = useAppStateMachine();

  useEffect(() => {
    if (window.zESettings && window.zE) {
      window.zESettings = {
        color: {
          theme: color,
          launcher: color,
          launcherText: "#FFF",
        },
      };

      return () => {
        delete window.zESettings;
        delete window.zE;
      };
    }
  }, [color]);

  useEffect(() => {
    if (currentState.value !== AppStates.LOADING && window.zE) {
      window.zE.apply(null, ["webWidget", "setLocale", token?.language]);
      window.zE.apply(null, [
        "webWidget",
        "helpCenter:setSuggestions",
        { labels: ["video", token?.role, currentState.value].filter(Boolean) },
      ]);
    }
  }, [currentState.value, token]);
}
