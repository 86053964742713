import { memo, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import { useReactiveVar } from "@apollo/client";
import { useMediaError } from "@app/hooks/media/useMediaError";
import { Browsers, useBrowser } from "@app/hooks/useBrowser";
import { Icon, IconTypes } from "@pexip/components";
import { ExtendedMediaTrackSettings, Media } from "@pexip/media";
import { useWindowOrientation } from "@pexip/media-components";
import clsx from "clsx";
import isMobile from "ismobilejs";

import { UserControls } from "../../containers/Controls/UserControls";
import { Settings } from "../../containers/Settings/Settings";
import { isSettingsShownVar, isVideoMutedVar } from "../../graphql/cache";
import { CloseButton } from "../Button/CloseButton";
import { Placement, PointerPlacement, Tooltip, Type } from "../Tooltip/Tooltip";

import { VideoOverlay } from "./VideoOverlay";

import styles from "./Video.module.scss";

interface Props {
  media: Media;
  hasControls?: boolean;
  tooltipOptions?: {
    pointer: PointerPlacement;
    placement: Placement;
  };
}

export function UserVideo({ hasControls, media, tooltipOptions }: Props) {
  const { t } = useTranslation();

  const error = useMediaError();
  const [isErrorShownState, setIsErrorShown] = useState(false);
  const browserSpecificUrl = useBrowserSpecificHelp();

  const isSettingsShown = useReactiveVar(isSettingsShownVar);
  const isVideoMuted = useReactiveVar(isVideoMutedVar);

  function handleSettingsClose() {
    isSettingsShownVar(false);
    setIsErrorShown(false);
  }

  useEffect(() => {
    let timeoutId: number | null = null;

    if (!isSettingsShown) {
      // delay for animation settingsShown closed
      timeoutId = window.setTimeout(() => setIsErrorShown(true), 300);
    }

    return () => timeoutId !== null && clearTimeout(timeoutId);
  }, [isSettingsShown]);

  let isErrorShown = !isSettingsShown && isErrorShownState && error;

  return (
    <div className={styles.Container}>
      {isErrorShown && (
        <div
          className={clsx(
            styles.Tooltip,
            tooltipOptions?.pointer === PointerPlacement.END ? styles.right : styles.left,
            tooltipOptions?.placement === Placement.BOTTOM ? styles.bottom : styles.top,
          )}
        >
          <Tooltip
            icon="cog"
            pointer={tooltipOptions?.pointer ?? PointerPlacement.START}
            position={tooltipOptions?.placement ?? Placement.TOP}
            title={t(`device-status:${error}.title`)}
            type={Type.ERROR}
            withClose
          >
            <span
              dangerouslySetInnerHTML={{
                __html: t(`device-status:${error}.message`, { url: browserSpecificUrl }),
              }}
            />
          </Tooltip>
        </div>
      )}

      {hasControls && (
        <CSSTransition classNames={{ ...styles }} in={isSettingsShown} timeout={200} unmountOnExit>
          <div className={styles.SettingsPanel}>
            <CloseButton onClose={handleSettingsClose} />
            <Settings hideIcons />
          </div>
        </CSSTransition>
      )}
      <div className={clsx(styles.Video, styles.mirrored)} data-ignore-outside-click="true">
        <Video media={media} />
        {isVideoMuted && <Icon colorScheme="light" source={IconTypes.IconVideoOff} />}
        <VideoOverlay error={error} />
        {hasControls && (
          <div className={styles.controls}>
            <UserControls />
          </div>
        )}
      </div>
    </div>
  );
}

const Video = memo(
  ({ media }: { media: Media }) => {
    const video = useRef<HTMLVideoElement>(null);

    const dimensions = useVideoDimensions(media.getSettings().video[0]);

    useEffect(() => {
      if (video.current != null && media?.stream != null) {
        video.current.srcObject = media?.stream;
      }
    }, [media.stream]);

    return <video ref={video} style={dimensions} autoPlay muted playsInline />;
  },
  (prev, current) => prev.media.stream === current.media.stream,
);

const DEFAULT_HEIGHT = 168.75;
const DEFAULT_WIDTH = 300;

function useVideoDimensions(videoSettings?: ExtendedMediaTrackSettings | null): {
  height: number;
  width: number;
} {
  const { isPortrait } = useWindowOrientation();
  const isMobileDevice = isMobile(navigator.userAgent).any;

  if (videoSettings == null) {
    return isPortrait && isMobileDevice
      ? { height: DEFAULT_WIDTH, width: DEFAULT_HEIGHT }
      : { height: DEFAULT_HEIGHT, width: DEFAULT_WIDTH };
  }

  const aspectRatio = videoSettings.width / videoSettings.height;

  return aspectRatio > 1
    ? { height: DEFAULT_WIDTH / aspectRatio, width: DEFAULT_WIDTH }
    : { height: DEFAULT_WIDTH, width: DEFAULT_WIDTH * aspectRatio };
}

const URLS: Partial<Record<Browsers, string>> = {
  [Browsers.CHROME]:
    "https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DDesktop&hl=en",
  [Browsers.FIREFOX]:
    "https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions",
  [Browsers.EDGE]: "https://www.windowscentral.com/how-manage-site-permissions-new-microsoft-edge",
  [Browsers.SAFARI]: "https://support.apple.com/guide/safari/websites-ibrwe2159f50/mac",
};

function useBrowserSpecificHelp() {
  const browser = useBrowser();

  return URLS[browser];
}
