import React, { PropsWithChildren } from "react";
import clsx from "clsx";

import styles from "./Spacer.module.scss";

interface Props {
  size?: "tiny" | "small" | "default" | "large";
}

export function Spacer(props: PropsWithChildren<Props>) {
  const { children, size = "default" } = props;

  return <div className={clsx(styles.Spacer, styles[size])}>{children}</div>;
}
