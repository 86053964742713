import { gql } from "@apollo/client";

export const CONNECT_PARTICIPANT = gql`
  mutation connectParticipant {
    connectParticipant {
      success
    }
  }
`;

export const DISCONNECT_PARTICIPANT = gql`
  mutation disconnectParticipant {
    disconnectParticipant {
      success
    }
  }
`;
