import React from "react";

import { Icon } from "../../../../components/Icon/Icon";

import styles from "./File.module.scss";

interface Props {
  type: string;
}

export function FileIcon({ type }: Props) {
  switch (type) {
    case "image/png":
    case "image/jpeg":
    case "image/gif":
      return (
        <div className={styles.yellow}>
          <Icon icon="file-image" size="2x" />
        </div>
      );
    case "application/pdf":
      return (
        <div className={styles.red}>
          <Icon icon="file-pdf" size="2x" />
        </div>
      );
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
    case "application/vnd.ms-word.document.macroEnabled.12":
    case "application/vnd.ms-word.template.macroEnabled.12":
      return (
        <div className={styles.blue}>
          <Icon icon="file-word" size="2x" />
        </div>
      );
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
    case "application/vnd.ms-excel.sheet.macroEnabled.12":
    case "application/vnd.ms-excel.template.macroEnabled.12":
    case "application/vnd.ms-excel.addin.macroEnabled.12":
    case "application/vnd.ms-excel.sheet.binary.macroEnabled.12":
      return (
        <div className={styles.green}>
          <Icon icon="file-excel" size="2x" />
        </div>
      );
    default:
      return (
        <div className={styles.default}>
          <Icon icon="file" size="2x" />
        </div>
      );
  }
}
