import { useEffect, useMemo, useState } from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { useReactiveVar, useSubscription } from "@apollo/client";
import { Placement, PointerPlacement } from "@app/components/Tooltip/Tooltip";
import { useLocalMedia } from "@app/hooks/media/media";
import { useGUM } from "@app/hooks/media/useGum";
import { useSpeakingWhileMuted } from "@app/hooks/media/useSpeakingWhileMuted";
import { Media } from "@pexip/media";
import { throttle } from "lodash-es";

import { CollapsibleWrapper } from "../../components/CollapsibleWrapper/CollapsibleWrapper";
import { WatermarkLogo } from "../../components/Logo/WatermarkLogo";
import { FloatingVideo } from "../../components/Video/FloatingVideo";
import { MainVideo } from "../../components/Video/MainVideo";
import { UserVideo } from "../../components/Video/UserVideo";
import { PanelContainer } from "../../containers/Panel/PanelContainer";
import { ON_PARTICIPANT_EVENT } from "../../containers/Participants/graphql";
import { presentationStatusVar } from "../../graphql/cache";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { Systems, useOperatingSystem } from "../../hooks/useOperatingSystem";
import { usePexip } from "../../hooks/usePexip";
import { useDecodedToken } from "../../hooks/useToken";
import { OnParticipantEvent } from "../../types/api";
import { AcceptedAppointment } from "../../types/Appointment";
import { DialInModal } from "../WaitingRoom/components/DialIn/DialInModal";

import styles from "./MeetingRoom.module.scss";

interface Props {
  appointment: AcceptedAppointment;
}

export function MeetingRoom({ appointment }: Props) {
  const [isDialInOpen, setIsDialInOpen] = useState(false);

  const { presentationSelfStream, presentationStream, stream } = usePexip(appointment);
  const { isDesktop } = useMediaQuery();
  const os = useOperatingSystem();
  const token = useDecodedToken();
  const presentationStatus = useReactiveVar(presentationStatusVar);
  const [presentationInMainView, setPresentationInMainView] = useState(
    presentationStatus === "presenting:other",
  );
  const actualPresentationStream = presentationStream ?? presentationSelfStream;

  const localMedia = useLocalMedia();

  useSpeakingWhileMuted();
  useGUM();

  const { data } = useSubscription<OnParticipantEvent>(ON_PARTICIPANT_EVENT, {
    variables: {
      appointmentId: token?.appointmentId,
      userId: token?.participantId,
    },
  });

  useEffect(() => {
    if (data?.onParticipantEvent) setIsDialInOpen(true);
  }, [data]);

  useEffect(() => {
    if (presentationStatus === "none") {
      return;
    }

    setPresentationInMainView(presentationStatus === "presenting:other" ? true : false);
  }, [presentationStatus]);

  return (
    <>
      <DialInModal
        dialInCode={token?.aliases.find((a) => a.type === "DIAL_IN_CODE")?.value}
        dialInNumber={appointment.dialInNumber}
        dialInPin={token?.pin}
        isOpen={isDialInOpen}
        onClose={() => setIsDialInOpen(false)}
      />
      <PanelContainer appointment={appointment} isOpenByDefault={isDesktop}>
        <div className={styles.MeetingRoom}>
          <WatermarkLogo src={appointment.subject.branding.watermarkLogo} />
          <MainVideo
            onClick={() => setPresentationInMainView((current) => !current)}
            src={
              actualPresentationStream !== undefined && presentationInMainView
                ? actualPresentationStream
                : stream
            }
          />
          {actualPresentationStream !== undefined && (
            <Draggable bounds="parent">
              <div className={styles.floatingVideo}>
                <FloatingVideo src={presentationInMainView ? stream : actualPresentationStream} />
              </div>
            </Draggable>
          )}
          {os === Systems.WEB ? (
            <DraggableVideo media={localMedia} />
          ) : (
            <div className={styles.self}>
              <CollapsibleWrapper>
                <UserVideo media={localMedia} />
              </CollapsibleWrapper>
            </div>
          )}
        </div>
      </PanelContainer>
    </>
  );
}

function DraggableVideo({ media }: { media: Media }) {
  const [tooltipOptions, setTooltipOptions] = useState({
    pointer: PointerPlacement.END,
    placement: Placement.TOP,
  });

  const handleDrag = useMemo(
    () =>
      throttle((_: DraggableEvent, data: DraggableData) => {
        const { right, top } = data.node.getBoundingClientRect();

        setTooltipOptions({
          pointer:
            right > document.body.clientWidth / 2 ? PointerPlacement.END : PointerPlacement.START,
          placement: top > document.body.clientHeight / 2 ? Placement.TOP : Placement.BOTTOM,
        });
      }, 250),
    [],
  );

  return (
    <Draggable bounds="parent" onDrag={handleDrag}>
      <div className={styles.self}>
        <CollapsibleWrapper>
          <UserVideo media={media} tooltipOptions={tooltipOptions} />
        </CollapsibleWrapper>
      </div>
    </Draggable>
  );
}
