import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserMediaStatus } from "@pexip/media";

import { useStreamStatus } from "./media";

export function useMediaError() {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const status = useStreamStatus();

  useEffect(() => {
    if (ERRORS.includes(status)) {
      return setError(status);
    }

    return setError(null);
  }, [status, t]);

  return error;
}

const AUDIO_ERRORS: UserMediaStatus[] = [
  // Not seen as errors
  // UserMediaStatus.InitialPermissionsNotGranted,
  // UserMediaStatus.InitialPermissionsAudioInputDenied,
  // UserMediaStatus.InitialPermissionsGrantedAudioInputDenied,
  UserMediaStatus.NoDevicesFound,
  UserMediaStatus.NoAudioDevicesFound,
  UserMediaStatus.AudioDeviceNotFound,
  UserMediaStatus.AudioVideoDevicesNotFound,
  UserMediaStatus.PermissionsRejected,
  UserMediaStatus.PermissionsRejectedAudioInput,
  UserMediaStatus.PermissionsOnlyAudioinputNoVideoDevices,
  UserMediaStatus.AudioDeviceInUse,
  UserMediaStatus.DevicesInUse,
  UserMediaStatus.NotSupportedError,
  UserMediaStatus.NotSupportedErrorOnlyAudioInput,
  UserMediaStatus.UnknownError,
  UserMediaStatus.UnknownErrorOnlyAudioinput,
];

const VIDEO_ERRORS: UserMediaStatus[] = [
  // Not seen as errors
  // UserMediaStatus.InitialPermissionsNotGranted,
  // UserMediaStatus.InitialPermissionsVideoInputDenied,
  // UserMediaStatus.InitialPermissionsGrantedVideoInputDenied,
  UserMediaStatus.NoDevicesFound,
  UserMediaStatus.NoVideoDevicesFound,
  UserMediaStatus.VideoDeviceNotFound,
  UserMediaStatus.AudioVideoDevicesNotFound,
  UserMediaStatus.PermissionsRejected,
  UserMediaStatus.PermissionsRejectedVideoInput,
  UserMediaStatus.VideoDeviceInUse,
  UserMediaStatus.DevicesInUse,
  UserMediaStatus.NotSupportedError,
  UserMediaStatus.NotSupportedErrorOnlyVideoInput,
  UserMediaStatus.NotSupportedErrorOnlyAudioInput,
  UserMediaStatus.UnknownError,
  UserMediaStatus.UnknownErrorOnlyVideoinput,
];

export const ERRORS: UserMediaStatus[] = [...AUDIO_ERRORS, ...VIDEO_ERRORS];
