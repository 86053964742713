import { gql } from "@apollo/client";

export const TRIGGER_DIAL_IN = gql`
  mutation TriggerDialIn($participantId: String!) {
    triggerDialIn(participantId: $participantId) {
      success
    }
  }
`;

export const ON_PARTICIPANT_EVENT = gql`
  subscription OnParticipantEvent($appointmentId: String!, $userId: String!) {
    onParticipantEvent(appointmentId: $appointmentId, userId: $userId) {
      body
      timestamp
      triggeredBy
      type
    }
  }
`;
