export enum ControlVariants {
  DANGER,
  DEFAULT,
  ON,
  OFF,
}

export type ControlTypes =
  | "audio"
  | "disconnect"
  | "microphone"
  | "navigation"
  | "pictureInPicture"
  | "presentation"
  | "settings"
  | "video"
  | "userFacing";

export type Control = {
  isLoading?: boolean;
  onToggle: (value: boolean) => void;
  value: boolean;
  variant?: ControlVariants;
};

export type Controls = {
  [x in ControlTypes]?: Control;
};

export type ControlMutation = {
  updateControl(value: boolean): Control;
};
