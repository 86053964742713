import React from "react";
import Helmet from "react-helmet";

import { useEnterpriseSettings } from "./graphql/hooks/useEnterpriseSettings.hooks";
import { enterprise } from "./utils/enterprise";

const PUBLIC_FOLDER = `https://skedifyvideostorage.blob.core.windows.net/assets/${enterprise}/public`;

export function Branding() {
  const { name } = useEnterpriseSettings() ?? {};

  return (
    <Helmet>
      <link href={`${PUBLIC_FOLDER}/apple-touch-icon.png?v=1`} rel="apple-touch-icon" />
      <link
        href={`${PUBLIC_FOLDER}/favicon-32x32.png?v=1`}
        rel="icon"
        sizes="32x32"
        type="image/png"
      />
      <link
        href={`${PUBLIC_FOLDER}/android-chrome-192x192.png?v=1`}
        rel="icon"
        sizes="192x192"
        type="image/png"
      />
      <link
        href={`${PUBLIC_FOLDER}/favicon-96x96.png?v=1`}
        rel="icon"
        sizes="96x96"
        type="image/png"
      />
      <link
        href={`${PUBLIC_FOLDER}/favicon-16x16.png?v=1`}
        rel="icon"
        sizes="16x16"
        type="image/png"
      />
      <link href={`${PUBLIC_FOLDER}/manifest.json`} rel="manifest" />
      <link href={`${PUBLIC_FOLDER}/overrides.css`} rel="stylesheet" />

      <title>{name ?? "Loading.."}</title>
    </Helmet>
  );
}
