import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";

import { Button, Variants } from "../../../../components/Button/Button";
import { Icon } from "../../../../components/Icon/Icon";

import styles from "./ChatInput.module.scss";

interface Props {
  onSubmit: (value: string) => void;
}

export function ChatInput(props: Props) {
  const { onSubmit } = props;
  const [value, setValue] = useState("");
  const { t } = useTranslation("chat");

  const handleSubmit = useCallback(
    (evt: any) => {
      evt.preventDefault();

      if (value.trim()) {
        onSubmit(value.trim());
        setValue("");
      }
    },
    [onSubmit, value],
  );

  const onChange = useCallback((evt: any) => {
    setValue(evt.target.value);
  }, []);

  const onKeyPress = (evt: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (evt.keyCode === 13 && evt.shiftKey) {
      evt.preventDefault();

      return setValue(`${value}\n`);
    }

    if (evt.keyCode === 13) {
      evt.preventDefault();
      handleSubmit(evt);
    }
  };

  return (
    <div className={styles.ChatInput}>
      <form onSubmit={handleSubmit}>
        <TextareaAutosize
          minRows={4}
          onChange={onChange}
          onKeyDown={onKeyPress}
          placeholder={t("input_placeholder") ?? undefined}
          value={value}
        />
        <Button type="submit" variant={!value ? Variants.DISABLED : Variants.DEFAULT}>
          <Icon icon="paper-plane" />
        </Button>
      </form>
    </div>
  );
}
