import React from "react";
import { useDecodedToken } from "@app/hooks/useToken";

import { Appointment_appointment } from "../../types/api";

import { ParticipantRow } from "./components/ParticipantsRow/ParticipantRow";

import styles from "./Participants.module.scss";

interface Props {
  appointment: Appointment_appointment;
}

export function Participants(props: Props) {
  const { appointment } = props;
  const token = useDecodedToken();

  return (
    <div className={styles.Participants}>
      <div className={styles.ParticipantsContent}>
        {appointment.participants.map((participant) => {
          return (
            <ParticipantRow key={participant.id} participant={participant} role={token?.role} />
          );
        })}
      </div>
    </div>
  );
}
